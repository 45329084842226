import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Button,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MegaSharesDialog = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">WHAT IS AN M-SHARE?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    1 M-Share, or "Mega Share," represents 1 million Axion shares. When you stake
                    your Axion, your tokens are burned and exchanged for shares. To calculate your
                    interest on any day, multiply the daily Axion payout in the chart below by the
                    number of M-Shares you own.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Relax n Divs
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MegaSharesDialog;
