import shrimp from 'assets/images/shrimp.png';
import crab from 'assets/images/crab.png';
import fish from 'assets/images/fish.png';
import dolphin from 'assets/images/dolphin.png';
import shark from 'assets/images/shark.png';
import whale from 'assets/images/whale.png';
import kraken from 'assets/images/kraken.png';

export const getEcoShares = (shares) => {
    if (shares >= 0 && shares < 0.0001) {
        return { image: shrimp, title: 'Shrimp' };
    } else if (shares >= 0.0001 && shares < 0.001) {
        return { image: crab, title: 'Crab' };
    } else if (shares >= 0.001 && shares < 0.01) {
        return { image: fish, title: 'Fish' };
    } else if (shares >= 0.01 && shares < 0.1) {
        return { image: dolphin, title: 'Dolphin' };
    } else if (shares >= 0.1 && shares < 1) {
        return { image: shark, title: 'Shark' };
    } else if (shares >= 1 && shares < 10) {
        return { image: whale, title: 'Whale' };
    } else if (shares >= 10) {
        return { image: kraken, title: 'Kraken' };
    }

    return { image: kraken, title: 'Kraken' };
};
