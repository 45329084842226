import React from 'react';
// Modules
import clsx from 'classnames';
// Components
import { LinearProgress, Typography } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { numberWithCommas } from 'utils/string';
// Modules
import useStyles from './styles';

const StatProgress = ({
    title = 'EARNED',
    value = '198,117',
    valueUsd = '62,000,000',
    percent = { positive: true, value: 0.01 },
    ...props
}) => {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <Typography variant="h6">{title}</Typography>
            <Typography className={classes.amount}>{value}</Typography>
            <Typography className={classes.value}>${valueUsd}</Typography>
            {percent && (
                <>
                    <div className={classes.row}>
                        {percent?.positive ? (
                            <ArrowDropUp className={classes.positive} />
                        ) : (
                            <ArrowDropDown className={classes.negative} />
                        )}
                        <Typography
                            classes={{
                                root: clsx({
                                    [classes.positive]: percent.positive,
                                    [classes.negative]: !percent.positive,
                                }),
                            }}
                        >
                            {percent?.positive ? '+' : ''}
                            {percent?.value}%
                        </Typography>
                    </div>
                    <LinearProgress
                        variant="determinate"
                        value={parseFloat(Math.abs(percent.value))}
                        classes={{
                            root: classes.progress,
                            bar1Determinate: clsx({
                                [classes.positiveBg]: Math.sign(percent.value) !== -1,
                                [classes.negativeBg]: Math.sign(percent.value) === -1,
                            }),
                        }}
                    />
                    {percent.usdValue ? (
                        <div className={clsx(classes.percentUsdValue)}>
                            <Typography className={clsx(classes.usdValue)} variant="h5">
                                ${numberWithCommas(percent?.usdValue, 2)}
                            </Typography>
                            <Typography className={clsx(classes.last24)} variant="h5">
                                Last Axion Payout
                            </Typography>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};

export default StatProgress;
