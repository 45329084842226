import React from 'react';
// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
import { numberWithCommas } from 'utils/string';
import { getEcoShares } from 'utils/get-eco';
// Components
import { CircularProgress } from '@material-ui/core';
import Donut from 'components/Donut';
import Stat from 'components/Stat';
import StatProgress from 'components/StatProgress';
import Card from 'components/Card';
import Tag from 'components/Tag';
import Staking from '../Staking';
import NavProfiles from 'components/NavProfiles/NavProfiles';
// styles
import { colors } from 'global-styles';
import useStyles from './styles';

const Account = ({ payouts = [], ...props }) => {
    const classes = useStyles();
    const { staker, profiles, axion, globals } = useSelector(
        ({ staker, profiles, axion, globals }) => ({
            staker,
            profiles,
            axion,
            globals,
        })
    );

    const data = {};
    if (staker) {
        data.avgStakeLength = staker.stakeStats.avgStakeLength / 60 / 60 / 24 / 365;
        data.avgActiveStakeLength = staker.stakeStats.avgActiveStakeLength / 60 / 60 / 24 / 365;
        data.totalStaked = staker.stats.totalStaked.toFixed(2);
        data.totalStakedUsd = data.totalStaked * axion.stats?.currentPriceUsd;
        data.totalShares = staker.stats.totalShares.toFixed(2);
        data.liquid = staker.stats.balance.toFixed(2);
        data.liquidUsd = data.liquid * axion.stats?.currentPriceUsd;
        data.totalSupply = staker.stats.balance + staker.stats.totalStaked;
        data.totalSupplyUsd = data.totalSupply * axion.stats?.currentPriceUsd;
        data.interest = staker.stats.totalInterest;
        data.penaltyPercent = (staker.stakeStats.penalty / staker.stats.totalInterest) * 100 || 0;
        data.shares = staker.stats.totalShares.toFixed(2);
        data.percentOwned = (data.totalShares / axion.stats?.sharesTotalSupply) * 100;
        data.stakes = staker.stakes.length;
        data.stakedPercent = data.totalStaked / data.totalSupply;
        data.eco = getEcoShares(data.percentOwned);

        data.lastPayout = payouts[payouts.length - 1];
        data.accruedPctIn24h = (data.lastPayout / staker.stakeStats.accrued) * 100 || 0;
        const lastEarnedLength = staker.sessions?.['Completed Stakes']?.stakes?.length ?? 0;
        const lastEarned =
            staker.sessions?.['Completed Stakes']?.stakes?.[lastEarnedLength - 1]?.interest ?? 0;

        data.lastEarnedPct =
            Math.sign(staker.stakeStats.earned) * (lastEarned / staker.stakeStats.earned) * 100 ||
            0;
    }

    function renderAddStaker() {
        return globals.loadingStaker ? (
            <div className={classes.progress}>
                <CircularProgress />
            </div>
        ) : (
            <div className={classes.bare}>
                <NavProfiles bare />
            </div>
        );
    }

    function renderStaker() {
        return (
            <>
                <div className={classes.cardBody}>
                    <div className={classes.graph}>
                        <Donut
                            circumference={100}
                            strokeWidth={4}
                            percentage={data.stakedPercent * 100}
                            innerColor={colors.yellow}
                            outerColor={colors.orange}
                            centertext={data.totalSupply.shorten(0, true)}
                        />
                    </div>
                    <div className={clsx(classes.stats)}>
                        <Stat
                            classes={{ root: classes.stat }}
                            legend={{ color: colors.orange }}
                            value={numberWithCommas(data.totalStaked, 0)}
                            usdValue={numberWithCommas(data.totalStakedUsd)}
                        />
                        <Stat
                            classes={{ root: classes.stat }}
                            title={'Liquid'}
                            legend={{ color: colors.yellow }}
                            value={numberWithCommas(data.liquid, 0)}
                            usdValue={numberWithCommas(data.liquidUsd)}
                        />
                        <Stat
                            classes={{ root: classes.stat }}
                            title={'Total Owned'}
                            value={numberWithCommas(data.totalSupply, 0)}
                            usdValue={numberWithCommas(data.totalSupplyUsd)}
                        />
                    </div>
                    <div className={classes.walletItems}>
                        <div className={clsx(classes.walletItem)}>
                            <StatProgress
                                title="interest ACCRUED"
                                value={staker.stakeStats.accrued.shorten(2)}
                                valueUsd={numberWithCommas(
                                    staker.stakeStats.accrued * axion.stats?.currentPriceUsd
                                )}
                                percent={{
                                    value: data.accruedPctIn24h.toFixed(2),
                                    positive: true,
                                    usdValue: data.lastPayout * axion.stats?.currentPriceUsd,
                                }}
                            />
                        </div>
                        <div className={clsx(classes.walletItem, classes.middleWalletItem)}>
                            <StatProgress
                                title="Interest Realized"
                                value={staker.stakeStats.earned.shorten(2)}
                                percent={{
                                    value: data.lastEarnedPct.toFixed(2),
                                    positive: [1, 0].includes(Math.sign(data.lastEarnedPct)),
                                }}
                                valueUsd={numberWithCommas(
                                    staker.stakeStats.earned * axion.stats?.currentPriceUsd
                                )}
                            />
                        </div>
                        <div className={clsx(classes.walletItem)}>
                            <StatProgress
                                title="PENALTIES"
                                classes={{ amount: classes.penalty }}
                                value={staker.stakeStats.penalty.shorten()}
                                valueUsd={numberWithCommas(
                                    staker.stakeStats?.penalty * axion.stats?.currentPriceUsd
                                )}
                                percent={{
                                    value: data.penaltyPercent.toFixed(4),
                                    positive: false,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.cardFooter}>
                    <Staking data={data} eco={data.eco} />
                </div>
            </>
        );
    }

    return (
        <Card classes={{ root: classes.root }}>
            <div className={classes.cardTitle}>
                My Portfolio
                {profiles.active && (
                    <Tag
                        text="View on PolygonScan"
                        noImage
                        href={`https://polygonscan.com/address/${profiles.active}`}
                        target="_blank"
                        classes={{ root: classes.tag }}
                    />
                )}
            </div>
            {staker ? renderStaker() : renderAddStaker()}
        </Card>
    );
};

export default Account;
