import React from 'react';
import { useSelector } from 'react-redux';
import { numberWithCommas } from 'utils/string';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Button,
} from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TreesDialog = ({ open, onClose }) => {
    const { axion, auction } = useSelector(({ axion, auction }) => ({
        axion,
        auction,
    }));

    const totalPlegedEth = 158.936;
    const totalPledgedUsd = 534305.18;
    const trees = totalPledgedUsd / 0.1 / 2;

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{'Axions Tree Pledge'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Led by the Axion Founder's passion for reforestation, 1% of the ETH bid in the
                    daily auction is pledged to the Eden Project. For more information, please visit{' '}
                    <a className={'eden'} href="https://edenprojects.org/">
                        https://edenprojects.org/
                    </a>
                </DialogContentText>
                <DialogContentText id="alert-dialog-slide-description">
                    Since November 14th of 2020, a total of {totalPlegedEth.toFixed(3)} ETH ($
                    {numberWithCommas(totalPledgedUsd)}) has been pledged to reforestation. Roughly{' '}
                    {numberWithCommas(trees, 0)} trees have been planted.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    That is awesome!!
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TreesDialog;
