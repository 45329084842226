import React from 'react';
// Components
import { Typography, Tab, Tabs, AppBar } from '@material-ui/core';
import DailyStats from './DailyStats';
import SocialMedia from './SocialMedia';
import Podcasts from './Podcasts';
import Highlights from './Highlights';
import Shows from './Shows';
import Card from 'components/Card';
import TabPanel from 'components/TabPanel';
// styles
import useStyles from './styles';

export default function Activity() {
    const [value, setValue] = React.useState('one');

    const classes = useStyles();

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Highlights />
            {/* <Card classes={{ root: classes.card }}>
                <Typography variant="h5">Recent Show</Typography>
                <iframe
                    title="week-show"
                    className={classes.frame}
                    src="https://www.youtube.com/embed/ymMXkt8jSzE"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    frameBorder="0"
                ></iframe>
            </Card>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor={'primary'}
                    classes={{ root: classes.tabs }}
                    // centered
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab value="one" label="Activity" />
                    <Tab value="two" label="Today's Stats" />
                    <Tab value="three" label="News" />
                    <Tab value="four" label="Weekly Shows" />
                    <Tab value="five" label="Podcasts" />
                    {/* <Tab value="three" label="Videos & Articles" />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index="one">
            </TabPanel>
            <TabPanel value={value} index="two">
                <DailyStats classes={{ root: classes.spaceTop }} />
            </TabPanel>
            <TabPanel value={value} index="three">
                <SocialMedia />
            </TabPanel>
            <TabPanel value={value} index="four">
                <Shows />
            </TabPanel>
            <TabPanel value={value} index="five">
                <Podcasts />
            </TabPanel> */}
        </div>
    );
}
