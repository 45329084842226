import React, { useState, useEffect } from 'react';

// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
// Utils
import { numberWithCommas } from 'utils/string';
import moment from 'moment';
import web3 from 'web3';
// Material UI
import { Grid, Typography } from '@material-ui/core';

// Components
import Card from 'components/Card';
import ClubCard from 'components/ClubCard';
import VCCard from 'components/VCCard';
import Donut from 'components/Donut';
import SharesBarChart from 'components/SharesBarChart';
import Stat from 'components/Stat';

// import Venture from 'assets/images/venture.jpg'
// Styles
import { colors, palette } from 'global-styles';
import useStyles from './styles';

const Overview = (props) => {
    const classes = useStyles(props);
    const { axion, bpd } = useSelector(({ axion, bpd }) => ({ axion, bpd }));

    const [components, setComponents] = useState({
        m: '1',
        d: '1',
        h: '01',
        m: '01',
        s: '01',
    });
    useEffect(() => {
        const BPDOneDate = 1605250956 + 30240000 * 2;

        const interval = setInterval(() => {
            const now = new Date().getTime() / 1000;
            const seconds = Math.floor(BPDOneDate - now);
            const days = Math.floor(seconds / 24 / 60 / 60);
            const hoursLeft = Math.floor(seconds - days * 86400);
            const hours = Math.floor(hoursLeft / 3600);
            const minutesLeft = Math.floor(hoursLeft - hours * 3600);
            const minutes = Math.floor(minutesLeft / 60);
            const rs = seconds % 60;

            setComponents({
                d: days,
                h: hours >= 10 ? hours : `0${hours}`,
                m: minutes >= 10 ? minutes : `0${minutes}`,
                s: rs >= 10 ? rs : `0${rs}`,
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const percentStaked =
        (axion.stats?.totalStaked / (axion.stats?.total - axion.stats?.devFund)) * 100;

    return (
        <Card classes={{ root: classes.card }}>
            <Grid container>
                <Grid item xs={12} lg={5} classes={{ root: classes.leftSide }}>
                    <div
                        className={classes.cardTitle}
                        style={{
                            paddingTop: 15,
                            paddingBottom: 13,
                        }}
                    >
                        <div className={clsx(classes.row, classes.between)}>
                            <Typography variant="h5">Overview</Typography>
                            <Typography variant="h5">
                                LAUNCHED {axion.stats?.day} DAYS AGO
                            </Typography>
                        </div>
                    </div>
                    <div className={clsx(classes.cardBody, classes.maxWidthCardBody)}>
                        <Grid container style={{ height: '100%' }}>
                            <Grid item xs={12} sm={7} classes={{ root: classes.leftSideInner }}>
                                <Donut
                                    percentage={percentStaked}
                                    circumference={100}
                                    innerColor={colors.teal}
                                    outerColor={palette.primary.purple}
                                    centertext={axion.stats?.totalWithoutDev?.shorten?.()}
                                    classes={{ root: classes.donut }}
                                />
                                <div className={classes.shares}>
                                    <Typography variant="h6">AXN Total Shares</Typography>
                                    <Typography variant="h3" className={classes.sharesTotalSupply}>
                                        {numberWithCommas(axion.stats?.sharesTotalSupply, 0)}
                                    </Typography>
                                    <Typography variant="h4" className={classes.shareRate}>
                                        Share Rate = {axion.stats?.shareRate?.toFixed(4)}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5} classes={{ root: classes.stats }}>
                                <Grid container>
                                    <Grid item xs={6} sm={12}>
                                        <Stat
                                            classes={{ root: classes.stat }}
                                            legend={{
                                                color: palette.primary.main,
                                            }}
                                            value={numberWithCommas(axion.stats?.totalStaked, 0)}
                                            usdValue={numberWithCommas(
                                                axion.stats?.totalStaked *
                                                    axion.stats?.currentPriceUsd
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={12}>
                                        <Stat
                                            classes={{ root: classes.stat }}
                                            title={'Liquid'}
                                            legend={{ color: colors.teal }}
                                            value={numberWithCommas(
                                                axion.stats?.totalSupplyWithoutDev,
                                                0
                                            )}
                                            usdValue={numberWithCommas(
                                                axion.stats?.totalSupplyWithoutDev *
                                                    axion.stats?.currentPriceUsd
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stat
                                            classes={{
                                                root: clsx(classes.stat, classes.bottomStat),
                                            }}
                                            title={'Total Supply'}
                                            value={numberWithCommas(
                                                axion.stats?.totalWithoutDev,
                                                0
                                            )}
                                            usdValue={numberWithCommas(
                                                axion.stats?.totalWithoutDev *
                                                    axion.stats?.currentPriceUsd
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <div
                        className={clsx(classes.cardTitle, classes.secondTitle)}
                        style={{
                            paddingTop: 15,
                            paddingBottom: 13,
                        }}
                    >
                        <div className={clsx(classes.row, classes.between)}>
                            <Typography variant="h5">BIGPAYDAYS OUTLOOK</Typography>
                            <Typography variant="h5">
                                {`${components.d} Days ${components.h}:${components.m}:${components.s} left until BPD #2`}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.cardBody}>
                        <Grid container>
                            <Grid item xs={12} classes={{ root: classes.sharesBarChart }}>
                                {bpd?.times && (
                                    <SharesBarChart
                                        values={
                                            bpd?.stats?.[0]?.map?.((value, index) => ({
                                                axn: parseFloat(web3.utils.fromWei(value)),
                                                shares: moment(
                                                    (bpd?.times?.[index] ?? 0) * 1000
                                                ).format('L'),
                                            })) ?? { axn: [0, 0, 0, 0, 0], shares: [0, 0, 0, 0, 0] }
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} sm={6} classes={{ root: classes.leftSide }}>
                                        <Grid container justify="center">
                                            <ClubCard
                                                axn={axion.stakedStats?.totalAxnStaked5555 ?? 0.0}
                                                amount={
                                                    (axion.stakedStats?.totalAxnStaked5555 ?? 0) *
                                                    axion.stats?.currentPriceUsd
                                                }
                                                stakers={axion.stakedStats?.totalActiveStakes5555}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <VCCard />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Card>
    );
};

export default Overview;
