import React, { useState } from 'react';
// modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
// Components
import { InfoOutlined, Bathtub, Update } from '@material-ui/icons';
import { Grid, IconButton, Typography } from '@material-ui/core';
import MegaSharesDialog from 'components/Dialogs/MegaSharesDialog';
import LeagueDialog from 'components/Dialogs/LeagueDialog';
// Assets
import useStyles from './styles';

const Staking = ({ eco, data, ...props }) => {
    const { axion, staker } = useSelector(({ axion, staker }) => ({
        axion,
        staker,
    }));
    const [dialogOpen, setDialogOpen] = useState(false);
    const [leagueDialogOpen, setLeagueDialogOpen] = useState(false);
    const classes = useStyles(props);

    const totalAXN =
        staker.stakeStats.activeInterest +
        staker.stakeStats.activeBPD +
        staker.stats.totalStaked +
        staker.stats.balance;

    return (
        <div className={classes.root}>
            <MegaSharesDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
            <LeagueDialog open={leagueDialogOpen} onClose={() => setLeagueDialogOpen(false)} />
            <Grid container>
                <Grid item xs={12} md={3} className={classes.contentItemGrid}>
                    <div className={clsx(classes.contentItem, classes.borderItem)}>
                        <div className={classes.row}>
                            <img src={eco.image} className={classes.ecoImage} alt="eco" />
                            <Typography className={classes.subtitle}>{eco.title}</Typography>

                            <IconButton onClick={() => setLeagueDialogOpen(true)}>
                                <InfoOutlined />
                            </IconButton>
                        </div>
                        <Typography variant="h6">
                            You are {/* <span className={classes.bold}>#{13}</span>{' '} */}
                            at this level
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className={classes.contentItemGrid}>
                    <div className={clsx(classes.contentItem, classes.borderItem)}>
                        <Typography className={classes.title} variant="h6">
                            Total Shares Owned
                        </Typography>
                        <Typography className={classes.subtitle}>
                            {((data.totalShares / axion.stats?.sharesTotalSupply) * 100).toFixed(5)}
                            %
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className={classes.contentItemGrid}>
                    <div className={clsx(classes.contentItem, classes.borderItem)}>
                        <Typography className={classes.title} variant="h6">
                            Your average active stake length
                        </Typography>
                        <Typography className={classes.subtitle}>
                            {data.avgActiveStakeLength.toFixed(2)} Years
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className={classes.contentItemGrid}>
                    <div className={clsx(classes.contentItem)}>
                        <Typography className={classes.title} variant="h6">
                            Average AXN stake length
                        </Typography>
                        <Typography className={classes.subtitle}>
                            {(axion.stakedStats?.avgStakedDays / 365).toFixed(2)} Years
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.shares}>
                <div className={classes.sharesColumn}>
                    <div className={classes.sharesStat}>
                        <Bathtub className={classes.statIcon} color="primary" />
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">Shares Owned</Typography>
                        <Typography variant="h1">
                            {staker.stats.totalShares.shorten(0, true)}
                        </Typography>
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">Avg AXN Shares Owned</Typography>
                        <Typography variant="h2">
                            {(axion.stakedStats?.avgShares).shorten(2, true)}
                        </Typography>
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">Total Shares</Typography>
                        <Typography variant="h2">
                            {axion.stats?.sharesTotalSupply.shorten()}
                        </Typography>
                    </div>
                </div>
                <div className={classes.sharesColumn}>
                    <div className={classes.sharesStat}>
                        <Update color="primary" className={classes.statIcon} />
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">Axn Staked</Typography>
                        <Typography variant="h1">
                            {staker.stats.totalStaked.shorten(0, true)}
                        </Typography>
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">Avg Axn Staked</Typography>
                        <Typography variant="h2">
                            {(
                                axion.stats?.totalStaked / axion.stakedStats?.totalActiveStakes
                            ).shorten(2)}
                        </Typography>
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">Total Staked Axn</Typography>
                        <Typography variant="h2">{axion.stats?.totalStaked.shorten()}</Typography>
                    </div>
                </div>
                <div className={clsx(classes.sharesColumn, classes.noBorder)}>
                    {/* TODO: VCA */}
                    <div className={classes.sharesStat}>
                        <Typography variant="h2">Grand Totals</Typography>
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">STAKED + INTEREST + BPD + LIQUID</Typography>
                        <Typography variant="h1">
                            ${(totalAXN * axion.stats?.currentPriceUsd).shorten(1)}
                        </Typography>
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">Big Pay Days</Typography>
                        <Typography variant="h2">
                            {staker.stakeStats.activeBPD.shorten(2, true)}
                        </Typography>
                    </div>
                    <div className={classes.sharesStat}>
                        <Typography variant="h6">Total Axn</Typography>
                        <Typography variant="h2">{totalAXN.shorten(2)}</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Staking;
