import React, { useState, useEffect } from 'react';
// Modules
import { useSelector } from 'react-redux';
// Components
import NewsItem from 'components/NewsItem';
// Styles
import useStyles from './styles';

const Highlights = (props) => {
    const classes = useStyles(props);
    const { events } = useSelector(({ events }) => ({ events }));
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (Array.isArray(events?.Items)) setItems(events.Items);
        console.log(events.Items);
    }, [events.Items]);

    return (
        <div className={classes.root}>
            {items.map((event, index) => {
                if (event.type === 'StakeTransferred') return null;
                if (event.type === 'WithdrawLiquidDiv') {
                    if (event.tokenAddress === '0x839F1a22A59eAAf26c85958712aB32F80FEA23d9')
                        return null;
                }
                return (
                    <NewsItem
                        key={`key-${index}`}
                        classes={{ root: classes.spaceTop }}
                        data={event}
                    />
                );
            })}
        </div>
    );
};

export default Highlights;
