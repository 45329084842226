import { makeStyles } from '@material-ui/core/styles';
import AxionWhite from 'assets/images/axion-white.png';
import EthereumWhite from 'assets/images/ethereum-white.png';
import BTCWhite from 'assets/images/btc-white.png';

const useStyles = makeStyles((theme) => ({
    card: {
        flexBasis: '49%',
        borderRadius: 20,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            marginBottom: theme.spacing(2),
        },
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 20px',
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
        minHeight: 78,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: '10px 20px',
        },
    },
    titleIcon: {
        color: theme.brand.IconColor,
        marginRight: theme.spacing(1),
        fontSize: 24,
    },
    cardBody: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    cardBodyHeader: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },

    segmentedControl: {
        border: `1px solid ${theme.brand.border}`,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        fontSize: 14,
        fontWeight: 'bold',
    },
    control: {
        minWidth: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14',
        textAlign: 'center',
        padding: 10,
        boxSizing: 'border-box',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        // backgroundColor: '#fff',
        transition: 'all .5s ease',
    },
    controlMiddle: {
        borderLeft: `1px solid ${theme.brand.border}`,
        borderRight: `1px solid ${theme.brand.border}`,
    },
    activeControl: {
        cursor: 'default',
        borderRight: 'none',
        backgroundColor: theme.brand.axion,
        color: 'white',
    },
    price: {
        fontSize: 30,
        color: theme.brand.BoldText,
        fontWeight: 'bold',
        marginRight: 8,
    },
    percent: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    marketPrice: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },

    rowColumn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },

    positive: {
        color: theme.brand.JavaGreen,
    },
    graph: {
        flex: 1,
        width: '100%',
    },

    vertical: {
        backgroundColor: theme.brand.border,
        height: 80,
        width: 1,
    },
    exchangeRate: {
        display: 'flex',
        flex: 1,
        marginTop: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            // flexDirection: 'column',
            justifyContent: 'space-around',
            width: '100%',
            paddingBottom: theme.spacing(6),
        },
    },
    rate: {
        flex: 1,
    },
    rateSwitch: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 15,
    },
    switch: {
        borderRadius: 30,
        fontWeight: 'medium',
        padding: '3px 5px',
        fontSize: 14,
        textAlign: 'center',
        textTransform: 'uppercase',
        display: 'flex',
        minHeight: 30,
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 70,
        cursor: 'pointer',
        border: '2px solid white',
        color: 'white',
        zIndex: '1',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
    },
    switchInactive: {
        backgroundColor: 'rgb(162,167,177)',
        marginLeft: -16,
        borderRadius: 30,
        padding: '3px 3px',
        fontSize: '14px',
        textAlign: 'center',
        textTransform: 'uppercase',
        display: 'flex',
        minHeight: 30,
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 70,
        cursor: 'pointer',
        border: '2px solid white',
        color: 'white',
    },
    axion: {
        backgroundColor: theme.brand.axion,
        '&:before': {
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${AxionWhite})`,
            marginRight: 3,
            height: 18,
            width: 18,
            backgroundSize: 'contain',
            alignItems: 'center',
            content: '""',
            display: 'block',
            position: 'relative',
        },
    },
    btc: {
        backgroundColor: theme.brand.orange,
        '&:before': {
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${BTCWhite})`,
            marginRight: 3,
            height: 18,
            width: 18,
            backgroundSize: 'contain',
            alignItems: 'center',
            content: '""',
            display: 'block',
            position: 'relative',
        },
    },
    eth: {
        backgroundColor: theme.brand.purple,
        '&:before': {
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${EthereumWhite})`,
            marginRight: 3,
            height: 20,
            width: 18,
            backgroundSize: 'contain',
            alignItems: 'center',
            content: '""',
            display: 'block',
            position: 'relative',
        },
    },
    ratePercent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
        [theme.breakpoints.up('sm')]: {
            margin: '15px 0px',
        },
    },
    ratePrice: {
        fontSize: 25,
        color: theme.brand.BoldText,
        fontWeight: 'bold',
        marginBottom: 15,
    },
    positive: {
        color: theme.brand.JavaGreen,
        fontSize: 16,
        fontWeight: 'bold',
    },
    negative: {
        color: theme.palette.error.main,
        fontSize: 16,
        fontWeight: 'bold',
    },
}));

export default useStyles;
