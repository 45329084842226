export const SET_DAILY = 'SET_DAILY';
export const DAILY_ERROR = 'DAILY_ERROR';
export const INIT = {
    Historical: {},
    Popularity: {},
    Payouts: {},
    ReservesOf: {},
};

export default function daily(state = INIT, { type, payload }) {
    switch (type) {
        case SET_DAILY:
            return payload;
        case DAILY_ERROR:
        default:
            return state;
    }
}
