import React, { useState, useEffect } from 'react';
// Modules
import clsx from 'classnames';
// Components
import { useTheme, Select, MenuItem } from '@material-ui/core';
import Donut from 'components/Donut';
import Card from 'components/Card';
// styles
import Axion from 'assets/images/axion';
import useStyles from './styles';

const PopularCard = ({
    title,
    Icon = Axion,
    image,
    data = {
        today: 0,
        yesterday: 0,
        week: 0,
        month: 0,
    },
    ...props
}) => {
    const classes = useStyles(props);
    const theme = useTheme();
    const [filter, setFilter] = useState('day');
    const { today, yesterday, week, month } = data;
    const handleChange = (event) => {
        setFilter(event.target.value);
    };

    let details = {
        change: 0,
        changePercent: 0,
        user: 0,
    };
    if (filter === 'day') {
        details.change = today - yesterday;
        details.changePercent = (today - yesterday) / (today + 1);
        details.active = today;
    } else if (filter === 'week') {
        details.change = today - week;
        details.changePercent = (today - week) / (today + 1);
        details.active = week;
    } else if (filter === 'month') {
        details.change = today - month;
        details.changePercent = (today - month) / (today + 1);
        details.active = month;
    }
    details.changePercent *= 100;

    return (
        <Card classes={{ root: classes.card }}>
            <div className={classes.cardTitle}>
                <div className={classes.row}>
                    {image ? (
                        <img src={image} className={classes.cardIcon} />
                    ) : (
                        <Icon className={classes.cardIcon} fill={theme.palette.primary.main} />
                    )}
                    <span className={classes.title}>{title}</span>
                </div>
                <div className={classes.select}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter}
                        onChange={handleChange}
                    >
                        <MenuItem value={'day'}>1D</MenuItem>
                        <MenuItem value={'week'}>1W</MenuItem>
                        <MenuItem value={'month'}>1M</MenuItem>
                    </Select>
                </div>
            </div>
            <div className={classes.cardBody}>
                <Donut
                    classes={{ chartNumber: classes.chartNumber }}
                    head={false}
                    circumference={60}
                    hoverTextRing={null}
                    hoverTextCircle={'New Users'}
                    hoverTextCircleWidth={22}
                    centertext={
                        (Math.sign(details.change) !== -1 ? '+' : '') +
                        details.changePercent.toFixed(1) +
                        '%'
                    }
                    percentage={Math.abs(details.changePercent)}
                    strokeWidth={1.5}
                    innerColor={theme.brand.CircleGray}
                    fade={false}
                    outerColor={
                        Math.sign(details.change) !== -1 ? theme.brand.JavaGreen : theme.brand.error
                    }
                />
                <div className={classes.stats}>
                    <div className={classes.stat}>
                        <div className={clsx(classes.bar, classes.blue)} />
                        <span className={classes.statTitle}>
                            NEW
                            <br />
                            USERS
                        </span>
                        <span className={classes.statNumber}>{details.change}</span>
                    </div>
                    <div className={classes.stat}>
                        <div className={clsx(classes.bar, classes.grey)} />
                        <span className={classes.statTitle}>ACTIVE USERS</span>
                        <span className={classes.statNumber}>{details.active}</span>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default PopularCard;
