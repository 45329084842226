import React, { useState } from 'react';
// Components
import clsx from 'classnames';
import { BiSun, BiMoon } from 'react-icons/bi';
// import { Tree }
import { Link } from 'react-router-dom';
import { Hidden, IconButton, Typography } from '@material-ui/core';
import { setLightMode, setDarkMode } from 'redux/actions/theme';
import { useSelector, useDispatch } from 'react-redux';
// Styles
import useStyles from './styles';
import AxionTodayDark from 'assets/images/axion-today-dark.png';
import AxionTodayWhite from 'assets/images/axion-today-white.png';

export default function ButtonAppBar(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { axion, theme } = useSelector(({ axion, theme }) => ({
        axion,
        theme,
    }));

    function setMode(e) {
        if (theme.mode === 'light') {
            dispatch(setDarkMode());
        } else if (theme.mode === 'dark') {
            dispatch(setLightMode());
        }
    }

    return (
        <Hidden mdUp>
            <div className={classes.root}>
                <Link to="/">
                    <img
                        src={theme.mode === 'dark' ? AxionTodayWhite : AxionTodayDark}
                        alt="logo"
                        className={classes.logo}
                    />
                </Link>
                <div className={classes.row}>
                    <a
                        href="https://www.dextools.io/app/polygon/pair-explorer/0xf716a43d66c40ee90df429af2459e580c1afc09b"
                        target="_blank"
                        rel="noreferrer"
                        className="links"
                    >
                        <Typography variant="h6">${axion.stats?.currentPriceUsd}</Typography>
                    </a>
                    <div className={classes.left}>
                        <IconButton onClick={setMode}>
                            {theme.mode === 'light' ? (
                                <BiSun className={classes.modeIcon} />
                            ) : (
                                <BiMoon className={classes.modeIcon} />
                            )}
                        </IconButton>
                    </div>
                </div>
            </div>
        </Hidden>
    );
}
