import React from 'react';

import { palette } from 'global-styles';

export default ({ fill = palette.primary.main, ...props }) => {
    return (
        <svg
            width="23"
            height="19"
            viewBox="0 0 23 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1678 5.0543L11.2177 5.8761L10.3867 5.77547C7.36211 5.38973 4.71973 4.08155 2.4762 1.88448L1.37936 0.794336L1.09684 1.59937C0.498563 3.39392 0.880795 5.2891 2.1272 6.56373C2.79195 7.26814 2.64238 7.36876 1.49569 6.94948C1.09684 6.81531 0.747845 6.71468 0.714607 6.76499C0.598276 6.88239 0.997126 8.4086 1.31288 9.01237C1.74497 9.85095 2.62577 10.6728 3.58965 11.1591L4.40397 11.5449L3.44009 11.5616C2.50943 11.5616 2.4762 11.5784 2.57591 11.9306C2.90828 13.0208 4.22117 14.178 5.68362 14.6811L6.71398 15.0333L5.81657 15.57C4.48707 16.3415 2.9249 16.7776 1.36274 16.8111C0.614894 16.8279 0 16.895 0 16.9453C0 17.113 2.02749 18.0522 3.20742 18.4212C6.74722 19.5113 10.9518 19.0417 14.1093 17.1801C16.3529 15.8551 18.5964 13.222 19.6434 10.6728C20.2084 9.31426 20.7735 6.83208 20.7735 5.6413C20.7735 4.86981 20.8233 4.76918 21.754 3.84675C22.3024 3.31006 22.8176 2.72306 22.9173 2.55534C23.0835 2.23669 23.0669 2.23669 22.2193 2.5218C20.8067 3.02495 20.6073 2.95786 21.3053 2.20314C21.8204 1.66645 22.4353 0.693707 22.4353 0.408592C22.4353 0.358277 22.1861 0.442135 21.9035 0.593078C21.6044 0.760793 20.9396 1.01237 20.4411 1.16331L19.5437 1.44842L18.7294 0.894965C18.2806 0.593078 17.6491 0.257648 17.3168 0.157019C16.4692 -0.077782 15.1729 -0.044239 14.4085 0.224105C12.3311 0.978823 11.0182 2.92432 11.1678 5.0543Z"
                fill={fill}
            />
        </svg>
    );
};
