import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    card: {
        padding: 20,
        marginTop: theme.spacing(2),
    },
    frame: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            height: 315,
            width: 560,
        },
    },
}));

export default useStyles;
