import { colors, darkColors, darkPalette, palette, NavigationBarHeight } from './global-styles';
var colorManipulator = require('./utils/style');

const theme = {
    mode: 'light',
    mixins: {
        bar: {
            height: NavigationBarHeight,
        },
    },
    palette: {
        ...palette,
        type: 'light',
    },
    typography: {
        useNextVariants: true, // https://material-ui.com/style/typography/#migration-to-typography-v2
        h1: {
            fontSize: 26,
            fontWeight: 'bold',
        },
        h2: {
            fontSize: 20,
            fontWeight: '500',
        },
        h3: {
            fontWeight: 500,
            fontSize: 18,
        },
        h4: {
            color: colors.BoldText,
            fontWeight: 500,
            fontSize: 16,
            textTransform: 'uppercase',
        },
        h5: {
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: 14,
            color: colors.GrayChateau,
        },
        h6: {
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: 12,
            color: colors.GrayChateau,
        },
    },
    overrides: {
        MuiButton: {
            text: {
                fontSize: 14,
                textTransform: 'none',
            },
        },
        MuiPaper: {},
        MuiInput: {},
        MuiInputBase: {},
        MuiDivider: {},
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
                backgroundColor: colors.primary,
            },
            arrow: {
                color: colors.primary,
                '&::before': {
                    backgroundColor: colors.primary,
                },
            },
        },
    },
    shadows: Array.from(new Array(30)).fill('none'),
    brand: colors,
    _shadows: {
        nav: '12px 12px 20px rgba(216,219,228,0.6)',
        bottom: '0px 0px 20px rgb(167 167 167 / 43%)',
        bar: '12px 12px 20px rgba(216,219,228,0.6)',
        news: '0 1px 10px rgb(35 39 36 / 30%)',
    },
    buttons: {
        outlined: {
            white: {
                padding: '9px 30px',
                color: '#fff',
                border: '3px solid #fff',
                '&:hover': {
                    border: '3px solid #fff',
                    filter: 'saturate(150%)',
                    backgroundColor: 'rgba(255,255,255, 0.2)',
                    background: 'rgba(255,255,255, 0.2)',
                },
                '& svg.MuiCircularProgress-svg': {
                    color: '#fff',
                },
            },
        },
        contained: {
            white: {
                color: '#fff',
                backgroundColor: '#fff',

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)('#fff', 0.3),
                },
            },
            selago: {
                color: colors.Selago,
                backgroundColor: colors.Selago,

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)(colors.Selago, 0.3),
                },
            },
        },
    },
};

export const darkTheme = {
    mode: 'dark',
    mixins: {
        bar: {
            height: NavigationBarHeight,
        },
    },
    palette: {
        ...darkPalette,
        type: 'dark',
    },
    typography: {
        useNextVariants: true, // https://material-ui.com/style/typography/#migration-to-typography-v2
        h1: {
            fontSize: 26,
            fontWeight: 'bold',
            color: darkColors.BoldText,
        },
        h2: {
            fontSize: 20,
            fontWeight: '500',
            color: darkColors.BoldText,
        },
        h3: {
            fontWeight: 500,
            fontSize: 18,
            color: darkColors.BoldText,
        },
        h4: {
            color: darkColors.BoldText,
            fontWeight: 500,
            fontSize: 16,
            textTransform: 'uppercase',
        },
        h5: {
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: 14,
            color: darkColors.GrayChateau,
        },
        h6: {
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: 12,
            color: darkColors.GrayChateau,
        },
    },
    overrides: {
        MuiButton: {
            text: {
                fontSize: 14,
                textTransform: 'none',
            },
        },
        MuiPaper: {},
        MuiInput: {},
        MuiInputBase: {},
        MuiDivider: {},
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
                backgroundColor: '#000',
            },
            arrow: {
                color: '#fff',
                '&::before': {
                    backgroundColor: '#000',
                },
            },
        },
    },
    shadows: Array.from(new Array(30)).fill('none'),
    brand: darkColors,
    _shadows: {
        nav: '12px 12px 20px rgba(0,0,0,0.6)',
        bottom: '0px 0px 20px rgb(20 20 20 / 43%)',
        bar: '32px 32px 62px rgba(0,0,0,0.6)',
        news: '0 1px 10px rgb(35 39 36 / 30%)',
    },
    buttons: {
        outlined: {
            white: {
                padding: '9px 30px',
                color: '#fff',
                border: '3px solid #fff',
                '&:hover': {
                    border: '3px solid #fff',
                    filter: 'saturate(150%)',
                    backgroundColor: 'rgba(255,255,255, 0.2)',
                    background: 'rgba(255,255,255, 0.2)',
                },
                '& svg.MuiCircularProgress-svg': {
                    color: '#fff',
                },
            },
        },
        contained: {
            white: {
                color: '#fff',
                backgroundColor: '#fff',

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)('#fff', 0.3),
                },
            },
            selago: {
                color: darkColors.Selago,
                backgroundColor: darkColors.Selago,

                '&:hover': {
                    backgroundColor: (0, colorManipulator.darken)(darkColors.Selago, 0.3),
                },
            },
        },
    },
};

export default theme;
