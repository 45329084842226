import { cloneDeep } from 'lodash';
import { SET_PROFILES } from 'redux/reducers/profiles';
import { get as getStaker, reset as resetStaker } from './staker';

export const setActive = (address) => async (dispatch, getState) => {
    const profiles = cloneDeep(getState().profiles);
    profiles.active = address;

    dispatch({
        type: SET_PROFILES,
        payload: profiles,
    });

    dispatch(getStaker(address));

    return profiles;
};

export const addAddress = (address, active = false) => async (dispatch, getState) => {
    const profiles = cloneDeep(getState().profiles);
    if (profiles.addresses.includes(address)) return;

    profiles.addresses.push(address);
    if (profiles.addresses.length === 1) {
        profiles.active = address;
        dispatch(getStaker(address));
    }

    dispatch({
        type: SET_PROFILES,
        payload: profiles,
    });

    if (active) {
        dispatch(setActive(address));
    }

    return profiles;
};

export const removeAddress = (address) => async (dispatch, getState) => {
    const profiles = cloneDeep(getState().profiles);
    profiles.addresses = profiles.addresses.filter((_address) => _address !== address);

    if (profiles.addresses.length === 0) {
        profiles.active = '';
        dispatch(resetStaker());
    } else if (profiles.active === address) {
        profiles.active = profiles.addresses[0];
        dispatch(getStaker(profiles.active));
    }

    dispatch({
        type: SET_PROFILES,
        payload: profiles,
    });

    return profiles;
};
