import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    card: {
        flexBasis: '49%',
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            marginBottom: theme.spacing(2),
        },
        height: '100%',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
    },
    titleIcon: {
        color: theme.brand.IconColor,
        marginRight: theme.spacing(1),
        fontSize: 24,
    },
    cardBody: {
        overflow: 'auto',
        padding: '0px 20px 20px 20px',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
    },
    th: {
        paddingBottom: 8,
        paddingTop: 8,
        whiteSpace: 'nowrap',
        '&:first-child': {
            paddingLeft: 8,

            '& > h6': {
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
            },
        },

        '&:last-child': {
            paddingRight: 8,

            '& > h6': {
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
            },
        },
    },
    thText: {
        background: theme.brand.Zircon,
        marginTop: 8,
        marginBottom: 8,
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: '100%',
    },
    td: {
        paddingBottom: 8,

        '&:first-child': {
            paddingLeft: 8,

            '& > *': {
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
            },
        },

        '&:last-child': {
            paddingRight: 8,

            '& > *': {
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
            },
        },
    },
    alignLeft: {
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
    },
    level: {
        marginLeft: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    tdCell: {
        background: theme.brand.Zircon,
        padding: 8,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.brand.BoldText,
    },
    tdCellTotal: {
        background: theme.brand.Zircon,
        padding: 8,
        height: 40,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: theme.brand.BoldText,
    },
    tdCellEnd: {
        paddingLeft: 16,
        paddingRight: 16,
    },
    tdCol: {
        flexDirection: 'column',
    },
    totalColItem: {
        background: theme.brand.axion,
        height: 45,
        width: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        color: 'white',
        fontWeight: 500,
        fontSize: 15,
    },
    icon: {
        height: 30,
        marginBottom: 6,
        marginRight: 8,
    },
    league: {
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    stakedShares: {
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
    stakedDollars: {
        color: theme.brand.BoldText,
        fontSize: 14,
        fontWeight: 500,
    },
}));

export default useStyles;
