import React, { useEffect, useState } from 'react';

// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import { Typography } from '@material-ui/core';
import { Equalizer } from '@material-ui/icons';
import Donut from 'components/Donut';
import Stat from 'components/Stat';
import { numberWithCommas } from 'utils/string';
import Card from 'components/Card';
// Styles
import useStyles from './styles';
import { colors, palette } from 'global-styles';

const OverviewWidget = () => {
    const classes = useStyles();
    const { axion } = useSelector(({ axion }) => ({ axion }));

    const percentStaked =
        (axion.stats?.totalStaked / (axion.stats?.total - axion.stats?.devFund)) * 100;
    return (
        <Card classes={{ root: classes.card }}>
            <div className={clsx(classes.cardTitle)}>
                <Link className="links" to="/overview">
                    <div className={classes.row}>
                        <Equalizer className={classes.titleIcon} />
                        <Typography variant="h5" color="primary">
                            OVERVIEW
                        </Typography>
                    </div>
                </Link>
            </div>
            <div className={classes.cardBody}>
                <div className={classes.graph}>
                    <Donut
                        percentage={percentStaked}
                        innerColor={colors.teal}
                        outerColor={palette.primary.main}
                        centertext={axion.stats?.totalWithoutDev?.shorten?.()}
                    />
                </div>
                <div className={classes.stats}>
                    <Stat
                        classes={{ root: classes.stat }}
                        legend={{ color: palette.primary.main }}
                        value={numberWithCommas(axion.stats?.totalStaked, 0)}
                        usdValue={numberWithCommas(
                            axion.stats?.totalStaked * axion.stats?.currentPriceUsd
                        )}
                    />
                    <Stat
                        classes={{ root: classes.stat }}
                        title={'Liquid (Excludes Dev)'}
                        legend={{ color: colors.teal }}
                        value={numberWithCommas(axion.stats?.totalSupplyWithoutDev, 0)}
                        usdValue={numberWithCommas(
                            axion.stats?.totalSupplyWithoutDev * axion.stats?.currentPriceUsd
                        )}
                    />
                    <Stat
                        classes={{ root: classes.stat }}
                        title={'Total Supply'}
                        value={numberWithCommas(axion.stats?.totalWithoutDev, 0)}
                        usdValue={numberWithCommas(
                            axion.stats?.totalWithoutDev * axion.stats?.currentPriceUsd
                        )}
                    />
                </div>
            </div>
            <div className={classes.cardFooter}>
                <div className={classes.footerRow}>
                    <div className={classes.footerStat}>
                        <Typography className={classes.footerStatTitle} variant="h6">
                            Crypto Rank
                        </Typography>
                        <Typography variant="h3">#{axion.stats?.marketCapRank}</Typography>
                    </div>
                    <div className={classes.vertical} />
                    <div className={classes.footerStat}>
                        <Typography className={classes.footerStatTitle} variant="h6">
                            total stakes
                        </Typography>
                        <Typography variant="h3">
                            {numberWithCommas(axion.stakedStats?.totalActiveStakes, 0)}
                        </Typography>
                    </div>
                    <div className={classes.vertical} />
                    <div className={classes.footerStat}>
                        <Typography className={classes.footerStatTitle} variant="h6">
                            Average Stake Size
                        </Typography>
                        <Typography variant="h3">
                            {axion.stakedStats?.avgStakedAxn?.shorten?.(2)}
                        </Typography>
                    </div>
                    <div className={classes.vertical} />
                    <div className={classes.footerStat}>
                        <Typography className={classes.footerStatTitle} variant="h6">
                            Average Stake Length
                        </Typography>
                        <Typography variant="h3">
                            {numberWithCommas(axion.stakedStats?.avgStakedDays ?? 0, 0)} Days
                        </Typography>
                    </div>
                </div>
                <div className={classes.footerRow}>
                    <div className={classes.footerStat}>
                        <Typography className={classes.footerStatTitle} variant="h6">
                            Market Cap
                        </Typography>
                        <Typography variant="h3">${axion.stats?.marketCap.shorten(2)}</Typography>
                    </div>
                    <div className={classes.vertical} />
                    <div className={classes.footerStat}>
                        <Typography className={classes.footerStatTitle} variant="h6">
                            % Staked
                        </Typography>
                        <Typography variant="h3">{percentStaked.toFixed(2)}%</Typography>
                    </div>
                    <div className={classes.vertical} />
                    <div className={classes.footerStat}>
                        <Typography className={classes.footerStatTitle} variant="h6">
                            Share Rate
                        </Typography>
                        <Typography variant="h3">
                            {axion.stats?.shareRate?.toFixed(4) ?? 1.0}
                        </Typography>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default OverviewWidget;
