import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme._shadows.bar,
        backgroundColor: theme.brand.CardBG,
    },
}));

export default useStyles;
