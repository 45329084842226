// let endpoint
// if (!process.env.VERCEL_GIT_COMMIT_REF) {
// const endpoint = 'http://localhost:3000'
// } else {
const endpoint = 'https://ey3aplwfs2.execute-api.us-east-1.amazonaws.com/Prod';
// }

export const base = endpoint;
export const routes = {
    getStaker: '/v3/staker/',
    getCoin: '/v3/coin/',
    getDaily: '/v3/daily/',
    getEvents: '/v3/events/',
};
