import React, { useState } from 'react';
import { withRouter } from 'react-router';

// Components
import { Hidden, AppBar } from '@material-ui/core';
import NavTopBar from '../NavTopBar';
import NavigationBar from '../NavigationBar';
import NavProfiles from '../NavProfiles';
import NavDrawer from '../NavDrawer';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import TreesDialog from 'components/Dialogs/TreesDialog';

import useStyles from './styles';

import menuItems from './menuItems';
import Footer from 'components/Footer';
import Background from 'components/Background';

function Navigation({ children, ...props }) {
    const classes = useStyles();
    const [treeOpen, setTreeOpen] = React.useState(false);

    const handleCloseTree = () => {
        setTreeOpen(false);
    };
    const handleOpenTree = () => [setTreeOpen(true)];

    return (
        <div className={classes.root}>
            <TreesDialog open={treeOpen} onClose={handleCloseTree} />
            <NavDrawer onShowTreeDialog={handleOpenTree} />
            {/* <AppBar className={classes.appbar}></AppBar> */}
            <NavigationBar onShowTreeDialog={handleOpenTree} />
            <main className={classes.content}>
                <NavTopBar />
                <NavProfiles />
                <Background />
                {children}
                <Footer />
            </main>
            <Hidden mdUp>
                <BottomNavigation value={props.location.pathname} className={classes.bottomNav}>
                    {menuItems.map(({ pathname, Icon }) => {
                        return (
                            <BottomNavigationAction
                                onClick={() => props.history.push(pathname)}
                                key={pathname}
                                value={pathname}
                                icon={<Icon className={classes.icon} />}
                            />
                        );
                    })}
                </BottomNavigation>
            </Hidden>
        </div>
    );
}

export default withRouter(Navigation);
