import React from 'react';

import { palette } from 'global-styles';

export default ({ fill = palette.primary.main, ...props }) => {
    return (
        <svg
            width="35"
            height="26"
            viewBox="0 0 35 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M25.3564 24.9928C25.3564 24.9928 24.2907 23.7117 23.4059 22.5932C27.2667 21.4951 28.7346 19.0548 28.7346 19.0548C27.5281 19.8479 26.3819 20.4173 25.3564 20.8036C23.8885 21.4341 22.4809 21.8408 21.0935 22.0848C18.2783 22.6135 15.6844 22.4712 13.4725 22.0645C11.8035 21.7391 10.3557 21.2714 9.14921 20.7833C8.46554 20.5189 7.74164 20.1936 6.99764 19.7665C6.91721 19.7055 6.81667 19.6852 6.73623 19.6242C6.67591 19.6038 6.6558 19.5632 6.61558 19.5428C6.09277 19.2378 5.79115 19.0344 5.79115 19.0344C5.79115 19.0344 7.19872 21.4137 10.9388 22.5525C10.0541 23.6913 8.96824 25.0131 8.96824 25.0131C2.4733 24.7894 0 20.4579 0 20.4579C0 10.8798 4.24282 3.11156 4.24282 3.11156C8.46554 -0.0811516 12.5073 0.000191219 12.5073 0.000191219L12.8089 0.366234C7.50034 1.91175 5.06726 4.25036 5.06726 4.25036C5.06726 4.25036 5.71072 3.88432 6.79656 3.39626C9.93343 1.99309 12.4469 1.60671 13.4725 1.52537C13.6534 1.50504 13.7942 1.46436 13.9752 1.46436C15.7648 1.22034 17.7957 1.15933 19.9071 1.40336C22.7021 1.72873 25.6982 2.56249 28.7547 4.25036C28.7547 4.25036 26.4221 2.01343 21.4353 0.467913L21.8375 0.000191219C21.8375 0.000191219 25.8591 -0.0811518 30.1019 3.13189C30.1019 3.13189 34.3447 10.9001 34.3447 20.4783C34.3648 20.4579 31.8513 24.7894 25.3564 24.9928ZM11.6828 11.1035C10.0139 11.1035 8.68672 12.588 8.68672 14.3979C8.68672 16.2078 10.034 17.6923 11.6828 17.6923C13.3518 17.6923 14.679 16.2078 14.679 14.3979C14.6991 12.588 13.3518 11.1035 11.6828 11.1035ZM22.4206 11.1035C20.7516 11.1035 19.4245 12.588 19.4245 14.3979C19.4245 16.2078 20.7717 17.6923 22.4206 17.6923C24.0896 17.6923 25.4167 16.2078 25.4167 14.3979C25.4167 12.588 24.0896 11.1035 22.4206 11.1035Z"
                fill={fill}
            />
        </svg>
    );
};
