import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },
    amount: {
        fontSize: 30,
        fontWeight: '500',
        margin: '4px 0px',
        color: theme.brand.BoldText,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    value: {
        fontSize: 16,
        fontWeight: '500',
        color: theme.brand.GrayChateau,
        marginBottom: theme.spacing(2),
    },
    positive: {
        color: theme.brand.JavaGreen,
        fontWeight: 'bold',
    },
    negative: {
        color: theme.palette.error.main,
        fontWeight: 'bold',
    },
    positiveBg: {
        backgroundColor: theme.brand.JavaGreen,
    },
    negativeBg: {
        backgroundColor: theme.brand.negative,
    },
    progress: {
        backgroundColor: theme.brand.ProgressGray,
        width: '60%',
        margin: 'auto',
    },
    percentUsdValue: {
        marginTop: theme.spacing(2),
    },
    last24: {
        textTransform: 'none',
        marginTop: theme.spacing(0.4),
        fontSize: 15,
    },
    usdValue: {
        color: theme.brand.BoldText,
        fontSize: 16,
        fontWeight: 'bold',
    },
}));

export default useStyles;
