import axios from 'axios';

async function getCoinStats() {
    const [axion, chart, ethereum, btc] = await Promise.all([
        axios('https://api.coingecko.com/api/v3/coins/axion'),
        axios('https://api.coingecko.com/api/v3/coins/axion/market_chart?vs_currency=usd&days=5'),
        axios('https://api.coingecko.com/api/v3/coins/ethereum'),
        axios('https://api.coindesk.com/v1/bpi/currentprice.json'),
    ]);

    const stats = {
        btcPrice: btc?.data?.bpi?.USD?.rate_float ?? 50000.0,
        ethUsd: ethereum.data.market_data.current_price.usd,
        ethPercentChange24h: ethereum.data.market_data.price_change_percentage_24h_in_currency.usd,
        ethPercentChange7d: ethereum.data.market_data.price_change_percentage_7d_in_currency.usd,
        ethPercentChange14d: ethereum.data.market_data.price_change_percentage_14d_in_currency.usd,
        ethPercentChange30d: ethereum.data.market_data.price_change_percentage_30d_in_currency.usd,
        totalSupply: axion.data.market_data.total_supply,
        currentPriceUsd: axion.data.market_data.current_price.usd,
        currentPriceEth: axion.data.market_data.current_price.eth,
        marketCap: axion.data.market_data.market_cap.usd,
        marketCapRank: axion.data.market_data.market_cap_rank,
        volume24h: axion.data.market_data.total_volume.usd,
        atl: axion.data.market_data.atl.usd,
        atlDate: axion.data.market_data.atl_date.usd,
        ath: axion.data.market_data.ath.usd,
        athDate: axion.data.market_data.ath_date.usd,
        percentChange24hVsEth: axion.data.market_data.price_change_percentage_24h_in_currency.eth,
        percentChange24hVsBtc: axion.data.market_data.price_change_percentage_24h_in_currency.btc,
        percentChange24h: axion.data.market_data.price_change_percentage_24h_in_currency.usd,
        percentChange7d: axion.data.market_data.price_change_percentage_7d_in_currency.usd,
        percentChange14d: axion.data.market_data.price_change_percentage_14d_in_currency.usd,
        percentChange30d: axion.data.market_data.price_change_percentage_30d_in_currency.usd,
        percentChange60d: axion.data.market_data.price_change_percentage_60d_in_currency.usd,
        communityData: axion.data.community_data,
        prices: chart.data.prices,
    };

    return stats;
}
export default getCoinStats;
