export const SET_STAKER = 'SET_STAKER';
export const STAKER_ERROR = 'STAKER_ERROR';
export const INIT = null;

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_STAKER:
            return payload;
        case STAKER_ERROR:
        default:
            return state;
    }
}
