function getUserPayouts(stakes, payouts) {
    const sharesOnDays = new Array(payouts.length).fill(0);

    const shares = {};
    stakes.forEach((stake) => {
        for (let i = stake.firstPayout; i < stake.lastPayout; i++) {
            if (!shares[i]) shares[i] = 0;
            shares[i] += stake.shares;
        }
    });

    for (let i = 0; i < payouts.length; i++) {
        const payout = payouts[i];
        if (shares[i]) sharesOnDays[i] = (shares[i] / payout.shares) * payout.payout;
    }

    return sharesOnDays;
}

export default getUserPayouts;
