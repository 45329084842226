import { isNullEmptyOrUndefined } from './string';
/**
 *
 * @name linearGradient
 * @description Takes two color values and returns a linear gradient
 * @param {String} color1 rgba color value
 * @param {String} color2 rgba color value
 * @returns {String}
 */
export const linearGradient = (color1, color2, opacity) => {
    if (isNullEmptyOrUndefined(opacity)) {
        opacity = '1.0';
    }
    color1 = fade(color1, opacity);
    color2 = fade(color2, opacity);
    return `linear-gradient(180deg, ${color1} 0%, ${color2} 100%)`;
};

/**
 *
 * @name isValidRgb
 * @description Checks if a color is hex or rgb
 * @param {String} color rgba color value
 * @returns {Boolean}
 */
export const isValidRgb = (color) => {
    return /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1(?:\.0*)?)\s*[)]$/.test(
        color
    );
};

/**
 * changeColorOpacity - Returns an excerpt from a longer string
 *
 * @param {String} color rgba color value
 * @param {Number} opacity value of color opacity
 * @returns {String}
 */
export const changeColorOpacity = (color, opacity) => {
    if (!isValidRgb(color)) {
        throw new Error(
            'INVALID Color Argument Passed to changeColorOpacity(): Requires rgba formatted color value!'
        );
    } else return `${color.slice(0, -5)}, ${opacity})`;
};

/**
 * Converts a color from CSS hex format to CSS rgb format.
 *
 * @param {string} color - Hex color, i.e. #nnn or #nnnnnn
 * @returns {string} A CSS rgb color string
 */

export function hexToRgb(color) {
    color = color.substr(1);
    var re = new RegExp('.{1,'.concat(color.length / 3, '}'), 'g');
    var colors = color.match(re);

    if (colors && colors[0].length === 1) {
        colors = colors.map(function (n) {
            return n + n;
        });
    }

    return colors
        ? 'rgb('.concat(
              colors
                  .map(function (n) {
                      return parseInt(n, 16);
                  })
                  .join(', '),
              ')'
          )
        : '';
}
/**
 * Darkens a color.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @param {number} coefficient - multiplier in the range 0 - 1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */

export function darken(color, coefficient) {
    color = decomposeColor(color);
    coefficient = clamp(coefficient);

    if (color.type.indexOf('hsl') !== -1) {
        color.values[2] *= 1 - coefficient;
    } else if (color.type.indexOf('rgb') !== -1) {
        for (var i = 0; i < 3; i += 1) {
            color.values[i] *= 1 - coefficient;
        }
    }

    return recomposeColor(color);
}
/**
 * Set the absolute transparency of a color.
 * Any existing alpha values are overwritten.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @param {number} value - value to set the alpha channel to in the range 0 -1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */

export function fade(color, value) {
    color = decomposeColor(color);
    value = clamp(value);

    if (color.type === 'rgb' || color.type === 'hsl') {
        color.type += 'a';
    }

    color.values[3] = value;
    return recomposeColor(color);
}
/**
 * Returns an object with the type and values of a color.
 *
 * Note: Does not support rgb % values.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {object} - A color object: {type: string, values: number[]}
 */

export function decomposeColor(color) {
    // Idempotent
    if (color.type) {
        return color;
    }

    if (color.charAt(0) === '#') {
        return decomposeColor(hexToRgb(color));
    }

    var marker = color.indexOf('(');
    var type = color.substring(0, marker);

    if (['rgb', 'rgba', 'hsl', 'hsla'].indexOf(type) === -1) {
        throw new Error(
            [
                'Clean Energy: ./utils/style-utils.js: unsupported `'.concat(color, '` color.'),
                'We support the following formats: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla().',
            ].join('\n')
        );
    }

    var values = color.substring(marker + 1, color.length - 1).split(',');
    values = values.map(function (value) {
        return parseFloat(value);
    });
    return {
        type: type,
        values: values,
    };
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * @param {number} value The value to be clamped
 * @param {number} min The lower boundary of the output range
 * @param {number} max The upper boundary of the output range
 * @returns {number} A number in the range [min, max]
 */
export function clamp(value) {
    var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

    if (process.env.NODE_ENV !== 'production') {
        if (value < min || value > max) {
            console.error(
                'Material-UI: the value provided '
                    .concat(value, ' is out of range [')
                    .concat(min, ', ')
                    .concat(max, '].')
            );
        }
    }

    return Math.min(Math.max(min, value), max);
}
/**
 * Converts a color object with type and values to a string.
 *
 * @param {object} color - Decomposed color
 * @param {string} color.type - One of: 'rgb', 'rgba', 'hsl', 'hsla'
 * @param {array} color.values - [n,n,n] or [n,n,n,n]
 * @returns {string} A CSS color string
 */

export function recomposeColor(color) {
    var type = color.type;
    var values = color.values;

    if (type.indexOf('rgb') !== -1) {
        // Only convert the first 3 values to int (i.e. not alpha)
        values = values.map(function (n, i) {
            return i < 3 ? parseInt(n, 10) : n;
        });
    } else if (type.indexOf('hsl') !== -1) {
        values[1] = ''.concat(values[1], '%');
        values[2] = ''.concat(values[2], '%');
    }

    return ''.concat(type, '(').concat(values.join(', '), ')');
}
