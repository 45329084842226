import { makeStyles } from '@material-ui/core/styles';
import AxionWhite from 'assets/images/axion-white.png';
import EthereumWhite from 'assets/images/ethereum-white.png';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },

    // Top area
    price: {
        fontSize: 30,
        color: theme.brand.BoldText,
        fontWeight: 'bold',
        marginRight: 8,
    },
    percent: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },
    marketPrice: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px 24px 8px 24px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    positive: {
        color: theme.brand.JavaGreen,
    },
    negative: {
        color: `${theme.brand.error} !important`,
    },
    graph: {
        marginTop: theme.spacing(2),
        flex: 1,
    },

    /** Switch */
    rateSwitch: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    switch: {
        borderRadius: 30,
        fontWeight: 'medium',
        padding: '3px 5px',
        fontSize: 14,
        textAlign: 'center',
        textTransform: 'uppercase',
        display: 'flex',
        minHeight: 30,
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 70,
        border: '2px solid white',
        color: 'white',
        zIndex: '1',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
    },
    switchInactive: {
        backgroundColor: 'rgb(162,167,177)',
        marginLeft: -16,
        borderRadius: 30,
        padding: '3px 3px',
        fontSize: '14px',
        textAlign: 'center',
        textTransform: 'uppercase',
        display: 'flex',
        minHeight: 30,
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 70,
        border: '2px solid white',
        color: 'white',
    },
    axion: {
        backgroundColor: theme.brand.axion,
        '&:before': {
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${AxionWhite})`,
            marginRight: 3,
            height: 18,
            width: 18,
            backgroundSize: 'contain',
            alignItems: 'center',
            content: '""',
            display: 'block',
            position: 'relative',
        },
    },
    eth: {
        backgroundColor: theme.brand.purple,
        '&:before': {
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${EthereumWhite})`,
            marginRight: 3,
            height: 20,
            width: 18,
            backgroundSize: 'contain',
            alignItems: 'center',
            content: '""',
            display: 'block',
            position: 'relative',
        },
    },
}));

export default useStyles;
