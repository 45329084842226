import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    card: {
        flexBasis: '49%',
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            marginBottom: theme.spacing(2),
        },
        height: '100%',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
    },
    titleIcon: {
        color: theme.brand.IconColor,
        marginRight: theme.spacing(1),
        fontSize: 32,
    },
    cardBody: {
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    stats: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
    },
    stat: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexBasis: '34%',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    legend: {
        height: 8,
        width: 30,
        borderRadius: 10,
        marginRight: theme.spacing(1),
    },
    legend: {
        height: 8,
        width: 30,
        borderRadius: 10,
        marginRight: theme.spacing(1),
    },
    graph: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(3),
        },
    },
    footerRow: {
        display: 'flex',
        borderTop: `1px solid ${theme.brand.border}`,
    },
    vertical: {
        backgroundColor: theme.brand.border,
        alignSelf: 'center',
        width: 1,
        height: 60,
    },
    footerStat: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 0px',
    },
    footerStatTitle: {
        maxWidth: 120,
        marginBottom: theme.spacing(1),
    },
    chart: {
        width: '100%',
    },
    positive: {
        color: theme.brand.JavaGreen,
        fontWeight: 'bold',
    },
    negative: {
        color: theme.palette.error.main,
        fontWeight: 'bold',
    },
    between: {
        justifyContent: 'space-between',
        flex: 1,
    },
    progress: {
        marginTop: theme.spacing(5),
    },

    wbtc: {
        height: 24,
        marginBottom: 8,
    },
}));

export default useStyles;
