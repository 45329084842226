import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
    },
    tabs: {
        backgroundColor: theme.brand.CardBG,
    },
    card: {
        padding: 20,
        marginTop: theme.spacing(2),
        textAlign: 'left',
    },
    frame: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            height: 225,
            width: 400,
        },
    },
}));

export default useStyles;
