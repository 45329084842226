import React from 'react';

import SharesBarChartDesktop from './SharesBarChartDesktop';
import SharesBarChartMobile from './SharesBarChartMobile';

const SharesBarChart = ({ values }) => {
    return (
        <>
            <SharesBarChartDesktop values={values} />
            <SharesBarChartMobile values={values} />
        </>
    );
};

export default SharesBarChart;
