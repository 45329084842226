import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Button,
} from '@material-ui/core';
import BenBradley from 'assets/images/BenBradley.jpg';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MegaSharesDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">The Axion OG</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Where in the world is Ben Bradley?
                </DialogContentText>
                <img src={BenBradley} className={classes.image} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" href="https://twitter.com/axion" target="_blank">
                    Help us find him
                </Button>
                <Button onClick={onClose} color="primary">
                    I've got better things to do.
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MegaSharesDialog;
