import axios from 'axios';
import { base, routes } from 'api/requests';
import { SET_STAKER } from 'redux/reducers/staker';
import { set as setGlobals } from './globals';
import getStakerStats from 'utils/staker-stats';
import { removeAddress } from './profiles';
import { setSnackbar } from './snackbar';

export const current = () => async (dispatch, getState) => {
    const { profiles } = getState();
    const staker = profiles.active;
    if (!staker) return;

    const response = await axios.get(base + routes.getStaker + staker);
    const { sessions, stakeStats } = getStakerStats(response.data);

    dispatch({
        type: SET_STAKER,
        payload: { ...response.data, sessions, stakeStats },
    });

    return response;
};

export const get = (staker) => async (dispatch) => {
    dispatch(setGlobals({ loadingStaker: true }));

    try {
        const response = await axios.get(base + routes.getStaker + staker);
        const { sessions, stakeStats } = getStakerStats(response.data);

        dispatch({
            type: SET_STAKER,
            payload: { ...response.data, sessions, stakeStats },
        });
        dispatch(setGlobals({ loadingStaker: false }));
    } catch (error) {
        dispatch(removeAddress(staker));
        dispatch(setGlobals({ loadingStaker: false }));
        dispatch(setSnackbar('Invalid address or address does not exist'));
        throw error;
    }
};

export const reset = () => async (dispatch, getState) => {
    dispatch({
        type: SET_STAKER,
        payload: null,
    });
};
