export const SET_GLOBALS = 'SET_GLOBALS';
export const GLOBALS_ERROR = 'GLOBALS_ERROR';
export const INIT = {};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_GLOBALS:
            return payload;
        case GLOBALS_ERROR:
        default:
            return state;
    }
}
