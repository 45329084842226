import web3 from 'web3';

export const convertstakedStats = (stakedStats = {}) => {
    stakedStats.totalAxnStaked = parseFloat(web3.utils.fromWei(stakedStats.totalAxnStaked || '0'));
    stakedStats.totalAxnStaked5555 = parseFloat(
        web3.utils.fromWei(stakedStats.totalAxnStaked5555 || '0')
    );
    stakedStats.totalAxnStaked5555And100Mil = parseFloat(
        web3.utils.fromWei(stakedStats.totalAxnStaked5555And100Mil || '0')
    );
    stakedStats.avgShares = parseFloat(web3.utils.fromWei(stakedStats.avgShares || '0'));
    stakedStats.totalActiveShares = parseFloat(
        web3.utils.fromWei(stakedStats.totalActiveShares || '0')
    );
    stakedStats.avgStakedAxn = parseFloat(web3.utils.fromWei(stakedStats.avgStakedAxn || '0'));

    /** Take it back to strings */
    Object.keys(stakedStats.ecosystem).forEach((key) => {
        stakedStats.ecosystem[key].totalShares = parseFloat(
            web3.utils.fromWei(stakedStats.ecosystem[key].totalShares)
        );
        stakedStats.ecosystem[key].totalAmount = parseFloat(
            web3.utils.fromWei(stakedStats.ecosystem[key].totalAmount)
        );
    });

    return stakedStats;
};

export const convertAuctionReserves = (reserves = {}) => {
    reserves.totalEth = parseFloat(web3.utils.fromWei(reserves.totalEth));
    reserves.totalAxion = parseFloat(web3.utils.fromWei(reserves.totalAxion));

    return reserves;
};

export const convertEvents = (events = []) => {
    for (var i = 0; i < events.length; i++) {
        const event = events[i];
        if (event.type === 'Bid') {
        } else if (event.type === 'VentureBid') {
        } else if (event.type === 'WithdrawLiquidDiv') {
        } else if (event.type === 'AcceleratorToken') {
        } else if (event.type === 'AcceleratorEth') {
        } else if (event.type === 'StakeSplit') {
        } else if (event.type === 'StakeTransferred') {
        } else if (event.type === 'Redeemed') {
            event.particles = parseFloat(web3.utils.fromWei(`${event.particles}` || '0'));
        } else {
            event.amount = parseFloat(web3.utils.fromWei(`${event.amount}` || '0'));
            event.shares = parseFloat(web3.utils.fromWei(`${event.shares}` || '0'));

            if (event.type === 'StakeUpgraded') {
                event.newAmount = parseFloat(web3.utils.fromWei(`${event.newAmount}` || '0'));
                event.newShares = parseFloat(web3.utils.fromWei(`${event.newShares}` || '0'));
            }

            if (event.type === 'StakeDeleted') {
                event.initialAmount = parseFloat(
                    web3.utils.fromWei(`${event.initialAmount || '0'}`)
                );
                event.payout = parseFloat(web3.utils.fromWei(`${event.payout || '0'}`));
            }
        }
    }

    return events;
};
