import React, { useState } from 'react';

// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
// Utils
import { numberWithCommas } from 'utils/string';

// Components
import { IconButton, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import LeagueDialog from 'components/Dialogs/LeagueDialog';
import EggDialog from 'components/Dialogs/EggDialog';
import Card from 'components/Card';

// Styles
import useStyles from './styles';

// Assets
import shrimp from 'assets/images/shrimp.png';
import crab from 'assets/images/crab.png';
import fish from 'assets/images/fish.png';
import dolphin from 'assets/images/dolphin.png';
import kraken from 'assets/images/kraken.png';
import greatWhite from 'assets/images/shark.png';
import whale from 'assets/images/whale.png';

const ecosystems = {
    kraken: { image: kraken, name: 'Kraken' },
    whale: { image: whale, name: 'Whale' },
    shark: { image: greatWhite, name: 'Shark' },
    dolphin: { image: dolphin, name: 'Dolphin' },
    fish: { image: fish, name: 'Fish' },
    crab: { image: crab, name: 'Crab' },
    shrimp: { image: shrimp, name: 'Shrimp' },
};

const LeagueDistribution = () => {
    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);
    const { axion } = useSelector(({ axion }) => ({ axion }));

    return (
        <Card classes={{ root: classes.card }}>
            <LeagueDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
            <div
                className={classes.cardTitle}
                style={{
                    paddingTop: 15,
                    paddingBottom: 13,
                }}
            >
                <div className={clsx(classes.row)}>
                    <Typography variant="h5">League Distribution</Typography>
                    <IconButton onClick={() => setDialogOpen(true)}>
                        <InfoOutlined />
                    </IconButton>
                </div>
            </div>
            <div className={classes.cardBody}>
                <table cellPadding="0" cellSpacing="0" border="0" className={classes.table}>
                    <thead>
                        <tr>
                            <th className={classes.th}>
                                <Typography
                                    variant="h6"
                                    className={clsx(classes.thText, classes.alignLeft)}
                                >
                                    Totals
                                </Typography>
                            </th>
                            <th className={classes.th}>
                                <Typography variant="h6" className={classes.thText}>
                                    Avg Duration (Yrs)
                                </Typography>
                            </th>
                            <th className={classes.th}>
                                <Typography variant="h6" className={classes.thText}>
                                    AXN SHARES
                                </Typography>
                            </th>
                            <th className={classes.th}>
                                <Typography variant="h6" className={classes.thText}>
                                    %
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(ecosystems).map((key, index) => {
                            const eco = ecosystems[key];
                            const stats = axion.stakedStats?.ecosystem?.[key] ?? {};

                            return (
                                <tr key={`${key}-${index}`}>
                                    <td className={classes.td}>
                                        <div
                                            className={clsx(classes.tdCellTotal, classes.tdCellEnd)}
                                        >
                                            <div className={classes.totalColItem}>
                                                {stats.count}
                                            </div>
                                            <div className={classes.level}>
                                                <img
                                                    src={eco.image}
                                                    alt={eco.name}
                                                    className={classes.icon}
                                                />
                                                <Typography className={classes.league}>
                                                    {eco.name}
                                                </Typography>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={clsx(classes.td, classes.stretch)}>
                                        <div className={classes.tdCell}>
                                            <Typography>
                                                {(
                                                    ((stats?.length ?? 0) / stats.count || 1) / 365
                                                ).toFixed(2)}
                                            </Typography>
                                        </div>
                                    </td>
                                    <td className={clsx(classes.td, classes.stretch)}>
                                        <div className={clsx(classes.tdCell, classes.tdCol)}>
                                            <Typography className={classes.stakedShares}>
                                                {numberWithCommas(stats?.totalShares ?? 0.0, 0)}
                                            </Typography>
                                            <Typography className={classes.stakedDollars}>
                                                $
                                                {numberWithCommas(
                                                    (stats?.totalAmount ?? 0.0) *
                                                        axion.stats?.currentPriceUsd,
                                                    0
                                                )}
                                            </Typography>
                                        </div>
                                    </td>
                                    <td className={classes.td}>
                                        <div className={clsx(classes.tdCell, classes.tdCellEnd)}>
                                            <Typography>
                                                {(
                                                    (stats.totalShares /
                                                        (axion.stakedStats?.totalActiveShares ??
                                                            0.0)) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </Typography>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export default LeagueDistribution;
