import React, { useState } from 'react';
// modules
import clsx from 'classnames';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addAddress, removeAddress, setActive } from 'redux/actions/profiles';
import FadeIn from 'react-fade-in';
// components
import Card from 'components/Card';
import { Fab, Typography } from '@material-ui/core';
import { Add, Check, Close } from '@material-ui/icons';

// Styles
import useStyles from './styles';

function NavProfiles({ children, bare, ...props }) {
    const classes = useStyles();
    const [address, setAddress] = useState({ value: '', valid: true });
    const [showAddProfile, setShowAddProfile] = useState(false);
    const { profiles } = useSelector(({ profiles }) => ({ profiles }));
    const dispatch = useDispatch();

    const add = () => {
        if (!address.value.startsWith('0x') || !address.value.length === '42') {
            return setAddress({ value: address.value, valid: false });
        }

        const includes = profiles.addresses.includes(address.value);

        !includes && dispatch(addAddress(address.value));
        setAddress({ value: '', valid: true });
        toggleField();
    };
    const remove = (address) => {
        dispatch(removeAddress(address));
    };
    const active = (address) => {
        dispatch(setActive(address));
    };
    const toggleField = () => {
        setShowAddProfile(!showAddProfile);
    };

    if (bare) {
        return (
            <FadeIn>
                <>
                    <div className={classes.row}>
                        <input
                            placeholder="Ethereum Address"
                            className={classes.fieldRoot}
                            value={address.value}
                            onChange={(e) =>
                                setAddress({
                                    value: e.target.value,
                                    valid: true,
                                })
                            }
                        />
                        <Fab
                            classes={{ root: classes.fab }}
                            onClick={add}
                            color="primary"
                            size="small"
                        >
                            <Check className={classes.icon} />
                        </Fab>
                        <Fab
                            classes={{ root: classes.fab }}
                            onClick={toggleField}
                            color="secondary"
                            size="small"
                        >
                            <Close className={classes.icon} />
                        </Fab>
                    </div>
                    {!address.valid && (
                        <span className={classes.invalid}>Invalid Ethereum Address</span>
                    )}
                </>
            </FadeIn>
        );
    }

    return (
        <Card classes={{ root: classes.root }}>
            <div className={classes.profiles}>
                <div className={classes.row}>
                    <Typography color="primary">Profile</Typography>
                    <Fab
                        classes={{ root: classes.fab }}
                        onClick={toggleField}
                        color="primary"
                        size="small"
                    >
                        <Add className={classes.icon} />
                    </Fab>
                </div>
                {showAddProfile && (
                    <FadeIn>
                        <>
                            <div className={classes.row}>
                                <input
                                    placeholder="Ethereum Address"
                                    className={classes.fieldRoot}
                                    value={address.value}
                                    onChange={(e) =>
                                        setAddress({
                                            value: e.target.value,
                                            valid: true,
                                        })
                                    }
                                />
                                <Fab
                                    classes={{ root: classes.fab }}
                                    onClick={add}
                                    color="primary"
                                    size="small"
                                >
                                    <Check className={classes.icon} />
                                </Fab>
                                <Fab
                                    classes={{ root: classes.fab }}
                                    onClick={toggleField}
                                    color="secondary"
                                    size="small"
                                >
                                    <Close className={classes.icon} />
                                </Fab>
                            </div>
                            {!address.valid && (
                                <span className={classes.invalid}>Invalid Ethereum Address</span>
                            )}
                        </>
                    </FadeIn>
                )}
                {!showAddProfile && (
                    <FadeIn>
                        <div className={classes.list}>
                            {profiles.addresses.map((address, index) => {
                                return (
                                    <div
                                        onClick={() =>
                                            address !== profiles.active && active(address)
                                        }
                                        key={`profile-${index}`}
                                        className={clsx(classes.address, {
                                            [classes.activeAddress]: address === profiles.active,
                                        })}
                                    >
                                        <Typography
                                            className={clsx(classes.addressTitle, {
                                                [classes.activeText]: address === profiles.active,
                                            })}
                                        >
                                            Profile {index + 1}
                                        </Typography>
                                        <Typography
                                            className={clsx(classes.addressSubtitle, {
                                                [classes.activeText]: address === profiles.active,
                                                [classes.inactiveText]: address !== profiles.active,
                                            })}
                                        >
                                            {address.substring(0, 6)}...
                                            {address.slice(-4)}
                                        </Typography>

                                        <Fab
                                            onClick={() => remove(address)}
                                            classes={{
                                                root: clsx(classes.fab, classes.addressRemove),
                                            }}
                                            color="secondary"
                                            size="small"
                                        >
                                            <Close
                                                className={clsx(
                                                    classes.icon,
                                                    classes.addressRemoveIcon
                                                )}
                                            />
                                        </Fab>
                                    </div>
                                );
                            })}
                        </div>
                    </FadeIn>
                )}
            </div>
            <div className={classes.activeProfile}>
                <Typography color="primary">Active Profile</Typography>
                <Typography variant="caption" color={'primary'}>
                    {profiles.active
                        ? `${profiles.active.substring(0, 6)}...${profiles.active.slice(-4)}`
                        : 'No active profile'}
                </Typography>
            </div>
        </Card>
    );
}

export default withRouter(NavProfiles);
