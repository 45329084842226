import { cloneDeep } from 'lodash';
import { SET_GLOBALS } from 'redux/reducers/globals';

export const set = (vars) => async (dispatch, getState) => {
    const { globals } = cloneDeep(getState().globals);

    dispatch({
        type: SET_GLOBALS,
        payload: { globals, ...vars },
    });
};
