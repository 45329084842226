import { makeStyles } from '@material-ui/core/styles';
import Crown from 'assets/images/crown.png';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '12px 24px',
        borderRadius: 5,
        marginTop: 12,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    newsHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ecoImage: {
        height: 32,
        width: 32,
    },
    ecoImageAfter: {
        height: 32,
        width: 32,
        marginRight: 24,
    },
    newsType: {
        padding: '10px 20px',
        borderRadius: '18px',
        color: '#fff',
        fontSize: 14,
        lineHeight: 1.2,
        textTransform: 'uppercase',
        display: 'inline-block',
        boxShadow: theme._shadows.news,
        margin: '0px 24px',
        fontWeight: 'bold',
        minWidth: 120,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            margin: '24px 12px',
        },
    },
    crown1: {
        position: 'absolute',
        left: 20,
        top: 7,
        height: 20,
        width: 20,
    },
    crown2: {
        position: 'absolute',
        right: 20,
        top: 7,
        height: 20,
        width: 20,
    },
    crown: {
        height: 36,
        width: 36,
        marginBottom: -theme.spacing(1.5),
    },
    btc: {
        height: 48,
    },
    ecoType: {
        fontSize: 16,
    },

    info: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        '& > div:last-child': {
            borderRight: `1px solid ${theme.brand.border}`,
        },
        [theme.breakpoints.down('sm')]: {
            margin: '16px 0px',

            '& div:last-child': {
                borderRight: `none`,
            },
            '& div:first-child': {
                borderLeft: `none !important`,
            },
        },
    },
    column: {
        borderLeft: `1px solid ${theme.brand.border}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        minWidth: 140,
        height: 70,

        [theme.breakpoints.down('sm')]: {
            flex: 1,
            minWidth: 'auto',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        fontSize: 12,
        color: theme.brand.BoldText,
        textTransform: 'uppercase',
    },
    amount: {
        fontSize: 13,
        color: theme.brand.BoldText,
        fontWeight: '500',
    },
    subtitle: {
        fontSize: 11,
        color: theme.brand.GrayChateau,
    },
    staked: {
        color: theme.palette.primary.main,
    },
    earned: {
        color: theme.brand.JavaGreen,
    },
    paid: {},
    btcEarned: {
        color: theme.brand.orange,
    },
    penalty: {
        color: theme.brand.error,
    },

    actions: {
        flexShrink: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            justifyContent: 'center',
        },
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 15px',
        cursor: 'pointer',
        borderRadius: 12,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 14,
        minHeight: 44,
        marginLeft: theme.spacing(1),
        backgroundColor: theme.brand.Tag,
    },

    Stake: {
        background: 'linear-gradient(136.67deg,#2541B2 8.34%,#6da1e2 95.26%)',
    },
    collider: {
        background: 'linear-gradient(136.67deg,#f2de48 8.34%,#e78651 95.26%)',
    },
    accelerator: {
        background: 'linear-gradient(136.67deg,#10aad6 8.34%,#176ebf 95.26%)',
    },
    Unstake: {
        background: 'linear-gradient(136.67deg, #ec248f 8.34%, #da9abc 95.26%)',
    },
    EarlyUnstake: {
        background: 'linear-gradient(136.67deg,#d32f2f 8.34%,#e57373 95.26%)',
    },
    LateUnstake: {
        background: 'linear-gradient(136.67deg,#d32f2f 8.34%,#e57373 95.26%)',
    },
    AuctionBid: {
        background: 'linear-gradient(90deg,#3ed2b0 0,#229e7c)!important',
    },
    MaxShare: {
        backgroundColor: '#6b0f1a',
        backgroundImage: 'linear-gradient(315deg, #a257b5 0%, #9a2f97 74%)',
    },
    WithdrawLiquidDiv: {
        background: 'linear-gradient(136.67deg, #388e3c 8.34%, #81c784 95.26%)',
    },
    StakeSplit: {
        background: 'linear-gradient(136.67deg, #ff8e3c 8.34%, #81ff84 95.26%)',
    },
}));

export default useStyles;
