import axios from 'axios';
import { base, routes } from 'api/requests';
import { SET_AXION } from 'redux/reducers/axion';
import { set as setBPD } from './bpd';
import { convertstakedStats } from 'utils/convert-from-bn';
import getCoinStats from 'utils/get-coin-stats';

export const get = () => async (dispatch) => {
    const [response, coinStats] = await Promise.all([
        axios.get(base + routes.getCoin),
        getCoinStats(),
    ]);

    const bpd = response.data.bpd;
    dispatch(setBPD(bpd));

    const stats = { ...coinStats, ...response.data.coin };
    stats.total = stats.totalSupply + stats.totalStaked;
    stats.totalWithoutDev = stats.totalSupply + stats.totalStaked - stats.devFund;
    stats.totalSupplyWithoutDev = stats.totalSupply - stats.devFund;
    stats.totalSupplyWithoutDevAndDead = stats.totalSupply - stats.devFund;

    const axion = {
        stats,
        accelerator: response.data.accelerator,
        ventureWithdraws: response.data.store.VentureWithdraws || {},
        stakedStats: convertstakedStats(response.data.store.Staked),
    };

    dispatch({
        type: SET_AXION,
        payload: axion,
    });

    return response;
};
