import React, { useState } from 'react';

// Modules
import clsx from 'classnames';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { numberWithCommas } from 'utils/string';
import web3 from 'web3';
import BN from 'bn.js';
import { WBTC_ADDRESS, WBTC_1E10 } from 'utils/variables';
// Material UI
import { IconButton, Typography, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
// Components
import Card from 'components/Card';
import Bitcoin from 'assets/images/Bitcoin-Logo.png';
import AxionLogo from 'assets/images/axion-logo.png';
// Styles
import useStyles from './styles';
import BasicDialog from 'components/Dialogs/BasicDialog';

const UserPayoutChart = ({ payouts, ...props }) => {
    const [dialog, setDialog] = useState(false);
    const classes = useStyles(props);
    const { staker, axion } = useSelector(({ staker, axion }) => ({
        staker,
        axion,
    }));

    const availableToken = parseFloat(
        web3.utils.fromWei(
            new BN(staker.ventureDivs?.[0]?.interestEarnedToken ?? '0').mul(WBTC_1E10)
        )
    );
    const withdrawn = parseFloat(
        web3.utils.fromWei(
            new BN(staker.staker?.ventureWithdraws?.[WBTC_ADDRESS] ?? '0').mul(WBTC_1E10)
        )
    );

    const allTime = availableToken + withdrawn;
    const totalStakerAXN =
        staker.stakeStats.activeInterest +
        staker.stakeStats.activeBPD +
        staker.stats.totalStaked +
        staker.stats.balance;

    const total = allTime * axion.stats?.btcPrice + totalStakerAXN * axion.stats?.currentPriceUsd;

    const totalSharesPercent = staker.stats.totalShares.toFixed(2) / axion.stats?.sharesTotalSupply;
    const estimatedEarningToday =
        totalSharesPercent *
        parseFloat(
            web3.utils.fromWei(
                new BN(axion?.accelerator?.currentAccelerator?.tokenBought ?? '0').mul(WBTC_1E10)
            )
        );

    return (
        <Card classes={{ root: classes.root }}>
            <BasicDialog
                open={dialog}
                onClose={() => setDialog(false)}
                title="How do bitcoin payouts work?"
                body={`This is your breakdown of bitcoin earned from VCAs. Please note that Today is an approximation and not the true value of amount you have received in today's VCA Auction.`}
                buttonText="I Love Bitcoin Divs!"
            />
            <div
                className={classes.cardTitle}
                style={{
                    paddingTop: 15,
                    paddingBottom: 13,
                }}
            >
                <div className={clsx(classes.row, classes.between)}>
                    <div className={clsx(classes.row)}>
                        <span> Bitcoin Payouts </span>
                        <IconButton onClick={() => setDialog(true)}>
                            <InfoOutlined />
                        </IconButton>
                    </div>
                </div>
            </div>
            <div className={classes.cardBody}>
                {/* TODO: VCA */}
                <div className={classes.walletItems}>
                    <div className={clsx(classes.walletItem)}>
                        <Typography variant="h1">Today</Typography>
                        <Typography variant="h5">{estimatedEarningToday.toFixed(8)} BTC</Typography>
                        <Typography variant="h3">
                            ${numberWithCommas(estimatedEarningToday * axion.stats?.btcPrice)}
                        </Typography>
                    </div>
                    <div className={clsx(classes.walletItem)}>
                        <Typography variant="h1">Available</Typography>
                        <Typography variant="h5">{availableToken.toFixed(8)} BTC</Typography>
                        <Typography variant="h3">
                            ${numberWithCommas(availableToken * axion.stats?.btcPrice)}
                        </Typography>
                    </div>
                    <div className={clsx(classes.walletItem, classes.middleWalletItem)}>
                        <img src={Bitcoin} height={100} alt="btc" />
                    </div>
                    <div className={clsx(classes.walletItem)}>
                        <Typography variant="h1">Withdrawn</Typography>
                        <Typography variant="h5">{withdrawn.toFixed(8)} BTC</Typography>
                        <Typography variant="h3">
                            ${numberWithCommas(withdrawn * axion.stats?.btcPrice)}
                        </Typography>
                    </div>
                    <div className={clsx(classes.walletItem)}>
                        <Typography variant="h1">All Time</Typography>
                        <Typography variant="h5">{allTime.toFixed(8)} BTC</Typography>
                        <Typography variant="h3">
                            ${numberWithCommas(allTime * axion.stats?.btcPrice)}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.cardFooter}>
                <div className={clsx(classes.row)}>
                    Total Holdings
                    <img src={Bitcoin} alt="btc" className={classes.footerLogo} />
                    <span> + </span>
                    <div className={classes.bgAxion}>
                        <img src={AxionLogo} alt="Axion" className={classes.footerLogo} />
                    </div>
                    <span> = </span>
                    <span className={classes.owned}>${total.shorten()}</span>
                </div>
            </div>
        </Card>
    );
};

export default withRouter(UserPayoutChart);
