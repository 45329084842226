import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    tag: {
        width: '90%',
    },
    percent: {
        fontSize: 25,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        color: theme.brand.BoldText,
    },
    token: {
        fontSize: 11,
        color: theme.brand.GrayChateau,
        fontWeight: 'bold',
    },
    switch: {
        fontSize: 16,
        color: theme.brand.GrayChateau,
        margin: '0px 12px',
    },
    exchange: {
        fontSize: 13,
        padding: '5px 10px',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: 30,
    },
    usd: {
        backgroundColor: theme.brand.USDGray,
    },
    eth: {
        backgroundColor: theme.brand.purple,
    },
    btc: {
        backgroundColor: theme.brand.orange,
    },

    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    vertical: {
        height: 75,
        width: 1,
        backgroundColor: theme.brand.border,
    },
    market: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20,
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    marketItem: {
        flexBasis: '40%',
    },

    ranking: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-around',
        },
    },
    rank: {
        flexBasis: '25%',
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rankTitle: {
        fontSize: 13,
        color: theme.brand.GrayChateau,
        marginBottom: 6,
    },
    rankSubtitle: {
        fontSize: 13,
        color: theme.brand.GrayChateau,
        marginTop: 4,
    },
    rankValue: {
        color: theme.brand.BoldText,
        fontSize: 16,
        fontWeight: 'bold',
    },
    positive: {
        color: theme.brand.JavaGreen,
    },
    negative: {
        color: theme.brand.negative,
    },
    positiveBg: {
        backgroundColor: theme.brand.JavaGreen,
    },
    negativeBg: {
        backgroundColor: theme.brand.negative,
    },
    progress: {
        backgroundColor: theme.brand.ProgressGray,
        width: '60%',
        margin: 'auto',
    },
    detail: {
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
        },
    },
}));

export default useStyles;
