import { cloneDeep } from 'lodash';
import { SET_NOTIFICATIONS } from 'redux/reducers/notifications';

export const add = (_notifications) => (dispatch, getState) => {
    if (!Array.isArray(_notifications)) return;
    const notifications = cloneDeep(getState().notifications);

    notifications.push(..._notifications);

    dispatch({
        type: SET_NOTIFICATIONS,
        payload: notifications,
    });
};

export const pop = () => (dispatch, getState) => {
    const notifications = cloneDeep(getState().notifications);
    notifications.pop();

    dispatch({
        type: SET_NOTIFICATIONS,
        payload: notifications,
    });
};

export const shift = () => (dispatch, getState) => {
    const notifications = cloneDeep(getState().notifications);
    notifications.shift();

    dispatch({
        type: SET_NOTIFICATIONS,
        payload: notifications,
    });
};

export const reset = () => ({
    type: SET_NOTIFICATIONS,
    payload: [],
});
