import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        zIndex: '-1',
        bottom: 0,
        left: 0,
        overflow: 'hidden',
    },
    svgbg: {
        position: 'absolute',
        top: -300,
        right: -200,
    },
}));

export default useStyles;
