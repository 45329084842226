import React, { useState, useEffect } from 'react';
// Modules
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { numberWithCommas } from 'utils/string';
import Card from 'components/Card';
import AxnValueTooltip from 'components/AxnValueTooltip';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
// Styles
import useStyles from './styles';

const V1Headers = ['Start Date', 'End Date', 'Progress', 'Principal'];
const V2Headers = [...V1Headers, 'Shares', 'Interest', 'Total'];

const ProfileTable = ({ data, title = 'Active Stakes', ...props }) => {
    const classes = useStyles(props);
    const [stakes, setStakes] = useState([]);
    const [order, setOrder] = useState({
        orderBy: 'Start Date',
        order: 'desc',
    });

    useEffect(() => {
        const clonedStakes = cloneDeep(data.stakes);
        if (order.orderBy === 'Total') {
            const ordered = clonedStakes.sort((a, b) => {
                const totalA = parseFloat(a.principal) + parseFloat(a.interest);
                const totalB = parseFloat(b.principal) + parseFloat(b.interest);
                return order.order === 'asc' ? totalB - totalA : totalA - totalB;
            });
            setStakes(ordered);
        } else if (order.orderBy === 'Interest') {
            const ordered = clonedStakes.sort((a, b) => {
                const interestA = parseFloat(a.interest) + parseFloat(a.bigPayDay);
                const interestB = parseFloat(b.interest) + parseFloat(b.bigPayDay);
                return order.order === 'asc' ? interestB - interestA : interestA - interestB;
            });
            setStakes(ordered);
        } else {
            let translation = {
                id: 'id',
                'Start Date': 'start',
                'End Date': 'end',
                Progress: 'progress',
                Principal: 'principal',
                Shares: 'shares',
                Interest: 'interest',
                Total: 'total',
                APY: 'apy',
            };
            const key = translation[order.orderBy];
            const ordered = clonedStakes.sort((a, b) => {
                if (key === 'start' || key === 'end') {
                    const dateA = new Date(a[key]).getTime();
                    const dateB = new Date(b[key]).getTime();
                    return order.order === 'asc' ? dateB - dateA : dateA - dateB;
                } else return order.order === 'asc' ? b[key] - a[key] : a[key] - b[key];
            });
            setStakes(ordered);
        }
    }, [order, data.stakes]);

    function handleSort(header) {
        let newOrder = order.order;
        if (order.orderBy === header) {
            if (order.order === 'desc') {
                newOrder = 'asc';
            } else {
                newOrder = 'desc';
            }
        }

        setOrder({ orderBy: header, order: newOrder });
    }

    const isV1 = title.toLowerCase().includes('v1');
    const headers = isV1 ? V1Headers : V2Headers;

    return (
        <Card classes={{ root: classes.root }}>
            <div className={classes.cardTitle}>
                {title}
                <div className={classes.number}>
                    <span>{data.stakes.length}</span>
                </div>
            </div>
            <div className={classes.cardBody}>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            {headers.map((header, index) => {
                                return (
                                    <th onClick={() => handleSort(header)} key={`th-${index}`}>
                                        <div className={classes.theader}>
                                            {header}
                                            {order.orderBy === header && order.order === 'asc' && (
                                                <ExpandLess />
                                            )}
                                            {order.orderBy === header && order.order === 'desc' && (
                                                <ExpandMore />
                                            )}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {stakes.map((stake, index) => {
                            return (
                                <Tooltip title={`Stake Id: ${stake.id}`}>
                                    <tr key={`${title}-${index}`}>
                                        <td>{moment(stake.start).format('L')}</td>
                                        <td>{moment(stake.end).format('L')}</td>
                                        <td>{stake.progress}%</td>
                                        <td>
                                            <AxnValueTooltip value={stake.principal} />
                                        </td>
                                        {!isV1 && (
                                            <>
                                                <td>{numberWithCommas(stake.shares, 0)}</td>
                                                <td>
                                                    <AxnValueTooltip
                                                        value={stake.interest + stake.bigPayDay}
                                                    >
                                                        <span>
                                                            {numberWithCommas(stake.interest, 0)}{' '}
                                                            <br />+{' '}
                                                            {numberWithCommas(stake.bigPayDay, 0)}{' '}
                                                            BPD
                                                        </span>
                                                    </AxnValueTooltip>
                                                </td>
                                                <td>
                                                    <AxnValueTooltip
                                                        value={stake.principal + stake.interest}
                                                    >
                                                        <span>
                                                            {(
                                                                parseFloat(stake.principal) +
                                                                parseFloat(stake.interest)
                                                            ).shorten()}
                                                        </span>
                                                    </AxnValueTooltip>
                                                </td>
                                                {/* <td>{stake.apy}%</td> */}
                                            </>
                                        )}
                                    </tr>
                                </Tooltip>
                            );
                        })}
                        <tr className={classes.totals}>
                            <td>TOTALS</td>
                            <td>-</td>
                            <td>-</td>
                            <td>
                                <AxnValueTooltip value={data.principal} />
                            </td>
                            {!isV1 && (
                                <>
                                    <td>{numberWithCommas(data.shares, 0)}</td>
                                    <td>
                                        <AxnValueTooltip value={data.interest + data.bigPayDay}>
                                            <span>
                                                {numberWithCommas(data.interest, 0)}
                                                <br />+ {numberWithCommas(data.bigPayDay, 0)} AXN
                                            </span>
                                        </AxnValueTooltip>
                                    </td>
                                    <td>{data.total.shorten()}</td>
                                    {/* <td>-</td> */}
                                </>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

export default ProfileTable;
