import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 5,
    },
    cardTitle: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: 20,
        color: theme.brand.GrayChateau,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    tag: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },
    bare: {
        padding: 30,
    },
    progress: {
        padding: 30,
        width: '100%',
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    cardFooter: {
        borderTop: `1px solid ${theme.brand.border}`,
    },
    graph: {
        flexShrink: 1,
        height: 250,
        width: 250,
    },
    stats: {
        flexShrink: 1,
        paddingRight: theme.spacing(2),
        borderRight: `1px solid ${theme.brand.border}`,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-around',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            alignItems: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    },
    stat: {
        [theme.breakpoints.down('sm')]: {
            flexBasis: '40%',
            marginBottom: theme.spacing(1),
        },
    },
    walletItems: {
        display: 'flex',
        padding: '12px 0px',
        flex: 2,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            borderTop: `1px solid ${theme.brand.border}`,
            alignItems: 'baseline',
        },
    },
    walletItem: {
        flex: 1,
        padding: '8px 24px',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '40%',
            marginBottom: theme.spacing(1),
        },
    },
    middleWalletItem: {
        borderRight: `1px solid ${theme.brand.border}`,
        borderLeft: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            padding: '12px 0px',
            borderRight: 'none',
            borderLeft: 'none',
        },
    },
    penalty: {
        color: theme.brand.error,
    },
    /** Common */
    row: {
        display: 'flex',
    },
    ml12: {
        marginLeft: 12,
    },
    mb12: {
        marginBottom: 12,
    },
}));

export default useStyles;
