import BN from 'bn.js';

export const tradeUrl = `https://stake.axion.network/buy`;
export const tokenInfoUrl = `https://info.uniswap.org/token/0x71f85b2e46976bd21302b64329868fd15eb0d127`;
export const stakeUrl = 'https://stake.axion.network/staking';
export const mineUrl = 'https://stake.axion.network/mining';
export const auctionUrl = 'https://stake.axion.network/accelerator/ETH';
export const MEGA_SHARE = 1000000;
export const START_OF_CONTRACTS = 1605250956;
export const SECONDS_IN_DAY = 86400;

export const TODAY_SITES = {
    YOUTUBE: 'https://www.youtube.com/axiontoday',
    FACEBOOK: 'https://www.facebook.com/AxionToday/',
    TWITTER: 'https://twitter.com/axiontoday',
    DLIVE: 'https://dlive.tv/axiontoday',
    INSTAGRAM: 'https://www.instagram.com/axiontoday/',
    APPLE: 'https://podcasts.apple.com/us/podcast/axion-today/id1542075418',
};
export const AXION_TODAY_EMAIL = 'axiontoday@gmail.com';
export const MAIL_CHIMP_URL =
    'https://today.us7.list-manage.com/subscribe/post?u=cd2771236bf68124eb3a86143&amp;id=28be7ad25b';
export const AXION_SITES = {
    MAIN: 'https://axion.network',
    TELEGRAM: 'https://t.me/axionofficial',
    DISCORD: 'https://discord.gg/u6uChFV',
    TWITTER: 'https://twitter.com/axion_network',
    GITHUB: 'https://github.com/Axion-Network/axion-contracts',
};
export const AXN_TODAY_ETH = `0xC4407227Ad23420Fecb0902E0484364F7C220d7e`;

export const VCA_STAKER_PCT = 0.85;
export const WBTC_ADDRESS = '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6';
export const WBTC_1E10 = new BN('10000000000');
export const WBTC_1E18 = new BN('1000000000000000000');
