import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    card: {
        flexBasis: '49%',
        boxShadow: theme._shadows.bar,
        backgroundColor: theme.brand.CardBG,
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            marginBottom: theme.spacing(2),
        },
        height: '100%',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
    },
    titleIcon: {
        color: theme.brand.IconColor,
        marginRight: theme.spacing(1),
        fontSize: 24,
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    vertical: {
        backgroundColor: theme.brand.border,
        height: 40,
        width: 1,
    },
    countdown: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 12px',
    },
    cdTitle: {
        color: theme.palette.primary.main,
        fontSize: 20,
    },
    cdSubtitle: {
        fontSize: 10,
        marginTop: 4,
        textTransform: 'uppercase',
        color: theme.brand.BoldText,
    },

    circle: {
        minHeight: 32,
        minWidth: 32,
        height: 32,
        width: 32,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circleImage: {
        objectFit: 'scale-down',
        height: '70%',
        width: '70%',
    },
    wbtc: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    axion: {
        backgroundColor: theme.brand.axion,
    },
    ethereum: {
        backgroundColor: theme.brand.teal,
    },
    uniswap: {
        backgroundColor: theme.brand.Viola,
    },
    btc: {
        backgroundColor: '#fff',
    },
    caption: {
        minHeight: 28,
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 120,
        textTransform: 'uppercase',
        marginBottom: 10,
        marginTop: 15,
        fontWeight: 'bold',
        color: theme.brand.GrayChateau,
    },
    count: {
        fontSize: 30,
        color: theme.brand.BoldText,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    value: {
        fontSize: 20,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    gridContainer: {
        padding: '24px 0px',
        borderBottom: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    borderGrid: {
        borderRight: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            marginBottom: theme.spacing(1),
            padding: theme.spacing(2),
        },
    },
    block: {
        border: `1px solid ${theme.brand.border}`,
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        fontSize: 14,
        margin: '0px 12px',
        borderRadius: 4,
    },
    equal: {
        color: theme.brand.BoldText,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1),
        },
    },
    rate: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.brand.border}`,
        padding: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    rates: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    cardFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3),
    },
    positive: {
        color: `${theme.brand.JavaGreen} !important`,
        display: 'block',
    },
    blockPositive: {
        backgroundColor: 'rgba(14, 193, 172, 0.15)',
        color: theme.brand.JavaGreen,
    },
    negative: {
        color: `${theme.palette.error.main} !important`,
        display: 'block',
    },
    blockNegative: {
        // #e57373
        backgroundColor: 'rgba(229, 115, 115, 0.35)',
        color: theme.palette.error.main,
    },
    alignLeft: {
        textAlign: 'left',
    },
    ratePrice: {
        fontSize: 14,
        color: theme.brand.BoldText,
        marginLeft: theme.spacing(1),
        fontWeight: 'bold',
    },
}));

export default useStyles;
