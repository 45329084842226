import React, { useState, useEffect } from 'react';

// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Components
import Chart from 'react-apexcharts';
import { useTheme, Typography } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown, BarChart } from '@material-ui/icons';
import Card from 'components/Card';
// Styles
import useStyles from './styles';
import Tag from 'components/Tag/Tag';
import getMarketPrices from 'utils/get-market-prices';
import apexStyle from 'utils/apex-style';
import { numberWithCommas } from 'utils/string';

const TAB_VALUES = [
    {
        indexes: [24, 20, 16, 12, 8, 4, 2, 0],
        labels: ['24h', '20h', '16h', '12h', '8h', '4h', '2h', 'NOW'],
    },
    {
        indexes: [168, 144, 120, 96, 72, 48, 24, 0],
        labels: ['7d', '6d', '5d', '4d', '3d', '2d', '1d', 'NOW'],
    },
    {
        indexes: [662, 504, 336, 168, 48, 24, 12, 0],
        labels: ['4w', '3w', '2w', '1w', '2d', '1d', '12h', 'NOW'],
    },
];

const MarketWidget = (props) => {
    const { axion } = useSelector(({ axion }) => ({ axion }));
    const classes = useStyles();
    const theme = useTheme();
    const [prices, setPrices] = useState([]);
    const [tab, setTab] = useState(2);

    useEffect(() => {
        async function _getStats() {
            const response = await getMarketPrices();
            setPrices(response.prices);
        }

        _getStats();
    }, []);

    const values = TAB_VALUES[tab];
    const indexes = values.indexes;
    const labels = values.labels;
    const _prices = prices?.slice?.().reverse?.() ?? new Array(720);
    const series = indexes.map((index) => _prices?.[index]?.[1]?.toFixed?.(5) ?? 0.0);

    const min = Math.min(...series);
    const max = Math.max(...series);
    const _graph = apexStyle({
        theme,
        series,
        max,
        min,
        labels,
        xFormatter: (index) => {
            if (index === labels.length) return labels[index - 1];
            return `${labels[index - 1]} ago`;
        },
    });

    let axnPercentChange;
    let ethPercentChange;
    let btcPercentChange;

    if (tab === 0) {
        ethPercentChange = {
            positive: axion.stats?.ethPercentChange24h >= 0,
            value: Math.abs(axion.stats?.ethPercentChange24h.toFixed(3)),
        };
        axnPercentChange = {
            positive: axion.stats?.percentChange24h >= 0,
            value: Math.abs(axion.stats?.percentChange24h.toFixed(3)),
        };
        btcPercentChange = {
            positive: axion.stats?.percentChange24hVsBtc >= 0,
            value: Math.abs(axion.stats?.percentChange24hVsBtc?.toFixed(3)),
        };
    } else if (tab === 1) {
        ethPercentChange = {
            positive: axion.stats?.ethPercentChange7d >= 0,
            value: Math.abs(axion.stats?.ethPercentChange7d.toFixed(3)),
        };
        axnPercentChange = {
            positive: axion.stats?.percentChange7d >= 0,
            value: Math.abs(axion.stats?.percentChange7d.toFixed(3)),
        };
        btcPercentChange = {
            positive: axion.stats?.percentChange24hVsBtc >= 0,
            value: Math.abs(axion.stats?.percentChange24hVsBtc?.toFixed(3)),
        };
    } else if (tab === 2) {
        ethPercentChange = {
            positive: axion.stats?.ethPercentChange30d >= 0,
            value: Math.abs(axion.stats?.ethPercentChange30d.toFixed(3)),
        };
        axnPercentChange = {
            positive: axion.stats?.percentChange30d >= 0,
            value: Math.abs(axion.stats?.percentChange30d.toFixed(3)),
        };
        btcPercentChange = {
            positive: axion.stats?.percentChange24hVsBtc >= 0,
            value: Math.abs(axion.stats?.percentChange24hVsBtc?.toFixed(3)),
        };
    }

    return (
        <Card classes={{ root: classes.card }}>
            <div className={classes.cardTitle}>
                <div className={clsx(classes.row, classes.between)}>
                    <Link className="links" to="/analytics">
                        <div className={classes.row}>
                            <BarChart className={classes.titleIcon} />
                            <div className={classes.column}>
                                <Typography variant="h5" color="primary">
                                    MARKET
                                </Typography>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={classes.segmentedControl}>
                    <span
                        onClick={() => setTab(0)}
                        className={clsx(classes.control, {
                            [classes.activeControl]: tab === 0,
                        })}
                    >
                        Day
                    </span>
                    <span
                        onClick={() => setTab(1)}
                        className={clsx(classes.control, classes.controlMiddle, {
                            [classes.activeControl]: tab === 1,
                        })}
                    >
                        Week
                    </span>
                    <span
                        onClick={() => setTab(2)}
                        className={clsx(classes.control, {
                            [classes.activeControl]: tab === 2,
                        })}
                    >
                        Month
                    </span>
                </div>
            </div>
            <div className={classes.cardBody}>
                <div className={classes.cardBodyHeader}>
                    <Tag
                        noImage
                        text="View Detail"
                        href="https://www.dextools.io/app/polygon/pair-explorer/0xf716a43d66c40ee90df429af2459e580c1afc09b"
                        target="_blank"
                    />
                    {/* <div className={classes.marketPrice}>
                        <span className={classes.price}>
                            ${axion.stats?.currentPriceUsd}
                        </span>
                        <span
                            className={clsx(classes.percent, {
                                [classes.positive]: axnPercentChange.positive,
                                [classes.negative]: !axnPercentChange.positive,
                            })}
                        >
                            {axnPercentChange.positive ? '+' : ''}
                            {axnPercentChange.value}%
                        </span>
                    </div> */}
                </div>
                <div className={classes.exchangeRate}>
                    <div className={classes.rate}>
                        <div className={classes.rateSwitch}>
                            <span className={clsx(classes.switch, classes.axion)}>AXN</span>
                            <span className={classes.switchInactive}>USD</span>
                        </div>
                        <span className={classes.ratePrice}>
                            ${axion.stats?.currentPriceUsd.toFixed(5)}
                        </span>
                        <div className={classes.ratePercent}>
                            {!axnPercentChange.positive ? (
                                <ArrowDropDown className={classes.negative} />
                            ) : (
                                <ArrowDropUp className={classes.positive} />
                            )}
                            <span
                                className={clsx({
                                    [classes.positive]: axnPercentChange.positive,
                                    [classes.negative]: !axnPercentChange.positive,
                                })}
                            >
                                {axnPercentChange.positive ? '+' : ''}
                                {axnPercentChange.value}%
                            </span>
                        </div>
                    </div>
                    <div className={classes.vertical} />
                    <div className={classes.rate}>
                        <div className={classes.rateSwitch}>
                            <span className={clsx(classes.switch, classes.btc)}>BTC</span>
                            <span className={classes.switchInactive}>USD</span>
                        </div>
                        <span className={classes.ratePrice}>
                            ${numberWithCommas(axion.stats?.btcPrice || 0, 0)}
                        </span>
                        <div className={classes.ratePercent}>
                            {!btcPercentChange.positive ? (
                                <ArrowDropDown className={classes.negative} />
                            ) : (
                                <ArrowDropUp className={classes.positive} />
                            )}
                            <span
                                className={clsx({
                                    [classes.positive]: btcPercentChange.positive,
                                    [classes.negative]: !btcPercentChange.positive,
                                })}
                            >
                                {btcPercentChange.positive ? '+' : ''}
                                {btcPercentChange.value}%
                            </span>
                        </div>
                    </div>
                    <div className={classes.vertical} />
                    <div className={classes.rate}>
                        <div className={classes.rateSwitch}>
                            <span className={clsx(classes.switch, classes.eth)}>ETH</span>
                            <span className={classes.switchInactive}>USD</span>
                        </div>
                        <span className={classes.ratePrice}>
                            ${numberWithCommas(axion.stats?.ethUsd || 0, 0)}
                        </span>
                        <div className={classes.ratePercent}>
                            {!ethPercentChange.positive ? (
                                <ArrowDropDown className={classes.negative} />
                            ) : (
                                <ArrowDropUp className={classes.positive} />
                            )}
                            <span
                                className={clsx({
                                    [classes.positive]: ethPercentChange.positive,
                                    [classes.negative]: !ethPercentChange.positive,
                                })}
                            >
                                {ethPercentChange.positive ? '+' : ''}
                                {ethPercentChange.value}%
                            </span>
                        </div>
                    </div>
                </div>

                <div className={classes.graph}>
                    <Chart
                        options={_graph.options}
                        series={_graph.series}
                        type="area"
                        height={250}
                    />
                </div>
            </div>
        </Card>
    );
};

export default MarketWidget;
