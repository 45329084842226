export const NavigationBarHeight = 70;

export const colors = {
    primary: '#144AD9',
    ScatterPoint: '#144AD9',
    Zircon: '#F5F7FF',
    IconColor: 'rgba(0,0,0,0.3)',
    IconColorHover: 'rgba(0,0,0,0.6)',
    ActiveText: '#fff',
    axion: '#144AD9',
    ClubCard: '#edeef5',
    CardBG: 'white',
    NavigationBackground: '#fff',
    ThemeMode: '#144AD9',
    ChartLine: '#144AD9',

    Body: '#edeef5',
    RoyalBlue: '#6554E1',
    CircleGray: '#e9ebf2',
    negative: '#ff5660',
    border: '#e2e2e2',
    Viola: '#BF79B4',
    RiverBed: '#4A5568',
    Trees: '#74904c',
    Selago: '#F0F3Fd',
    Whisper: '#edeef5',
    GrayChateau: '#A2A7B1',
    Swirl: '#d2d2d2',
    BackgroundGrey: '#d2d2d2',
    ProgressGray: '#f2f2f2',
    SoftBorder: '#f1f1f1',
    BoldText: '#4a4a4a',
    JavaGreen: '#0fc1ac',
    purple: 'rgb(131,116,236)',
    error: '#ff5666',
    teal: 'rgb(119,212,237)',
    orange: 'rgb(255,114,53)',
    yellow: 'rgb(255,190,33)',
    DeYork: 'rgb(123, 196, 139)',
    SunGlo: 'rgb(233, 108, 110)',
    BlueViolet: 'rgb(91, 77, 190)',
    USDGray: 'rgb(162, 167, 177)',
    Tag: '#F5F7FF',
};

export const darkColors = {
    primary: '#144AD9',
    ScatterPoint: 'rgba(187,134,252,1.0)',
    border: 'rgba(255,255,255,0.2)',
    ClubCard: '#4a4a4a',
    BoldText: '#fff',
    Body: '#041627',
    Swirl: '#2c3338',
    BlueViolet: 'rgba(255, 255, 255, 0.4)',
    ActiveText: '#4a4a4a',
    RiverBed: 'rgba(255, 255, 255, 0.4)',
    GrayChateau: 'rgba(255, 255, 255, 0.5)',
    IconColor: 'rgba(255,255,255,0.6)',
    IconColorHover: 'rgba(255,255,255,0.8)',
    axion: '#5086ea',
    Tag: 'rgba(255, 255, 255, 0.08)',
    NavigationBackground: '#081B2B',
    ThemeMode: '#fff',
    ChartLine: '#5086ea',

    Zircon: '#29669C',
    CardBG: '#173C5F',
    RoyalBlue: '#6554E1',
    CircleGray: '#e9ebf2',
    negative: '#ff5660',
    Viola: '#BF79B4',
    Trees: '#74904c',
    Selago: '#F0F3Fd',
    Whisper: '#edeef5',
    BackgroundGrey: '#d2d2d2',
    ProgressGray: '#f2f2f2',
    SoftBorder: '#f1f1f1',
    JavaGreen: '#0fc1ac',
    purple: 'rgb(131,116,236)',
    error: '#ff5666',
    teal: 'rgb(119,212,237)',
    orange: 'rgb(255,114,53)',
    yellow: 'rgb(255,190,33)',
    DeYork: 'rgb(123, 196, 139)',
    SunGlo: 'rgb(233, 108, 110)',
    USDGray: 'rgb(162, 167, 177)',
};

export const darkPalette = {
    primary: {
        main: '#fff',
    },
    error: {
        main: colors.error,
    },
};

export const palette = {
    primary: {
        main: colors.primary,
    },
    error: {
        main: colors.error,
    },
};
