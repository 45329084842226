import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';
const drawerWidth = 80;

// #2a333a navdrawer
const useStyles = makeStyles((theme) => ({
    drawer: {
        whiteSpace: 'nowrap',
        backgroundColor: theme.brand.NavigationBackground,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: drawerWidth,
    },
    list: {
        width: '100%',
        position: 'relative',
        margin: 'auto',
    },
    menuIcons: {
        transition: '.4s ease-out',
        fontSize: 20,
    },
    listItem: {
        paddingTop: 30,
        paddingBottom: 30,
        color: `${theme.brand.IconColor} !important`,
        transition: '.4s ease-out',
        justifyContent: 'center',
        '&:hover': {
            color: `${theme.brand.IconColorHover} !important`,
            '&:after': {
                transition: '.4s ease-out',
                content: '""',
                position: 'absolute',
                right: 0,
                height: '50%',
                width: '4px',
                borderRadius: '1em',
                backgroundColor: theme.palette.primary.main,
                // opacity: 1,

                transition: 'all .5s linear',
                background: 'transparent',
                color: 'transparent',
            },
        },
    },
    activeItem: {
        color: `${theme.palette.primary.main} !important`,
        '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            height: '50%',
            width: '4px',
            borderRadius: '1em',
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
        },
    },
    activeIcon: {
        color: theme.palette.primary.main,
    },
    /** pledge */
    pledgeListItem: {
        marginTop: theme.spacing(2),
    },
    pledge: {
        borderRadius: 12,
        width: 56,
        height: 45,
        border: `1px solid ${theme.brand.border}`,
        margin: 'auto',
    },
    pledgeTotal: {
        fontSize: 14,
        lineHeight: 0.7857142857,
        marginTop: 9,
        fontWeight: 500,
        color: theme.brand.Trees,
        textAlign: 'center',
    },
    tree: {
        width: 44,
        height: 33,
        display: 'block',
        margin: '-18px auto 0',
    },
    /** Abs */
    bottom: {
        position: 'absolute',
        bottom: '2%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modeIcon: {
        fontSize: 20,
    },
    top: {
        position: 'absolute',
        top: '2%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        left: 8,
    },
    logo: {
        width: 60,
        fill: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
    },

    /** Price */
    marketItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    percent: {
        fontSize: 11,
        marginTop: theme.spacing(0.5),
        fontWeight: 'bold',
        color: theme.brand.BoldText,
    },
    token: {
        fontSize: 11,
        color: theme.brand.GrayChateau,
        fontWeight: 'bold',
    },
    switch: {
        fontSize: 12,
        color: theme.brand.GrayChateau,
    },
    exchange: {
        fontSize: 11,
        color: 'white',
        color: theme.brand.GrayChateau,
        fontWeight: 'bold',
    },
    negative: {
        color: theme.brand.error,
    },
    positive: {
        color: theme.brand.JavaGreen,
    },
    price: {
        margin: '6px 0px',
        fontWeight: 'bold',
    },
}));

export default useStyles;
