import axios from 'axios';
import { base, routes } from 'api/requests';
import { SET_DAILY } from 'redux/reducers/daily';

export const get = () => async (dispatch) => {
    const response = await axios.get(base + routes.getDaily);

    dispatch({
        type: SET_DAILY,
        payload: response.data,
    });

    return response;
};
