import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 12,
        padding: 24,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderRadius: 5,
        boxSizing: 'border-box',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    profiles: {
        textAlign: 'left',
        width: '100%',
    },
    list: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',

        [theme.breakpoints.down('sm')]: {
            flexWrap: 'nowrap',
            overflow: 'scroll',
            width: '100%',
        },
    },
    row: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    activeProfile: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginBottom: theme.spacing(1),
        },
    },
    fab: {
        marginLeft: theme.spacing(1),
        height: 20,
        width: 20,
        minHeight: 'auto',
    },
    icon: {
        fontSize: 14,
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
    },
    fieldRoot: {
        backgroundColor: theme.brand.Zircon,
        padding: '12px',
        borderRadius: 12,
        border: 'none',
        outline: 'none',
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
            width: 350,
        },

        color: theme.brand.BoldText,
        '&::placeholder': {
            color: theme.brand.IconColor,
        },
    },
    invalid: {
        textAlign: 'left',
        fontSize: '12px',
        marginLeft: '6px',
        marginTop: '3px',
        color: theme.palette.error.main,
    },
    address: {
        position: 'relative',
        minWidth: 130,
        maxWidth: 130,
        width: 130,
        padding: 10,
        border: `2px solid ${theme.palette.primary.main}`,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '12px',
        margin: '0 7px 7px 0',
        overflow: 'hidden',
        cursor: 'pointer',
        transition: 'all .5s ease',
        WebkitTransition: 'all .5s ease',
        MozTransition: 'all .5s ease',
    },
    addressTitle: {
        fontSize: 11,
        textTransform: 'uppercase',
        color: theme.brand.RiverBed,
        opacity: 0.5,
    },
    addressSubtitle: {
        fontSize: 14,
        textTransform: 'uppercase',
    },
    activeText: {
        color: theme.brand.ActiveText,
    },
    inactiveText: {
        color: theme.brand.BoldText,
    },
    addressRemove: {
        position: 'absolute',
        width: 20,
        height: 20,
        borderRadius: '2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        right: 6,
        top: 6,
        cursor: 'pointer',
        ...theme.buttons.contained.selago,
    },
    addressRemoveIcon: {
        color: 'black',
    },
    activeAddress: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        cursor: 'default',
    },
}));

export default useStyles;
