import React, { useState } from 'react';
// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
// Components
import PopularCard from 'components/PopularCard';
import Card from 'components/Card';
import { Grid, useTheme } from '@material-ui/core';
// Containers
import Graph from './Graph';
import Statistics from './Statistics';
import Discord from 'assets/images/discord';
import Telegram from 'assets/images/telegram';
import Twitter from 'assets/images/twitter';
import AxionWhite from 'assets/images/axion-white.png';
import AxionColor from 'assets/images/axion-logo.png';
// styles
import useStyles from './styles';

const defaultPopularity = {
    twitterUsers: 0,
    telegramUsers: 0,
    discordUsers: 0,
    stakersAndLiquid: 0,
};

const Analytics = () => {
    const classes = useStyles();
    const { daily } = useSelector(({ daily }) => ({ daily }));
    const theme = useTheme();

    const today = daily.Popularity?.Items?.[0] ?? defaultPopularity;
    const yesterday = daily.Popularity?.Items?.[1] ?? today;
    const weekAgo =
        daily.Popularity?.Items?.[6] ??
        daily.Popularity?.Items?.[daily.Popularity?.Items?.length - 1 ?? 0];
    const monthAgo =
        daily.Popularity?.Items?.[28] ??
        daily.Popularity?.Items?.[daily.Popularity?.Items?.length - 1 ?? 0];

    const walletHolders = {
        today: today?.stakersAndLiquid ?? 0,
        yesterday: yesterday?.stakersAndLiquid ?? 0,
        week: weekAgo?.stakersAndLiquid ?? 0,
        month: monthAgo?.stakersAndLiquid ?? 0,
    };
    const twitter = {
        today: today?.twitterUsers ?? 0,
        yesterday: yesterday?.twitterUsers ?? 0,
        week: weekAgo?.twitterUsers ?? 0,
        month: monthAgo?.twitterUsers ?? 0,
    };
    const telegram = {
        today: today?.telegramUsers ?? 0,
        yesterday: yesterday?.telegramUsers ?? 0,
        week: weekAgo?.telegramUsers ?? 0,
        month: monthAgo?.telegramUsers ?? 0,
    };
    const discord = {
        today: today?.discord ?? 0,
        yesterday: yesterday?.discord ?? 0,
        week: weekAgo?.discord ?? 0,
        month: monthAgo?.discord ?? 0,
    };

    return (
        <>
            <Card classes={{ root: clsx(classes.card, classes.graphCard) }}>
                <Graph
                    classes={{
                        root: clsx(classes.section, classes.borderedSection),
                    }}
                />
            </Card>
            <Card classes={{ root: classes.card }}>
                <Statistics classes={{ root: classes.section }} />
            </Card>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} md={3}>
                    <PopularCard
                        title="axion wallets"
                        image={theme.mode === 'dark' ? AxionWhite : AxionColor}
                        data={walletHolders}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <PopularCard title="twitter" Icon={Twitter} data={twitter} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <PopularCard title="discord" Icon={Discord} data={discord} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <PopularCard title="telegram" Icon={Telegram} data={telegram} />
                </Grid>
            </Grid>
        </>
    );
};

export default Analytics;
