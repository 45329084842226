import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 12,
        height: 58,
        position: 'sticky',
        top: 0,
        zIndex: 99,
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        borderRadius: 5,
        boxSizing: 'border-box',
        boxShadow: theme._shadows.bar,
        backgroundColor: theme.brand.CardBG,
        [theme.breakpoints.down('sm')]: {
            top: 20,
            position: 'inherit',
        },
    },
    links: {
        width: '100%',
        height: '100%',
    },
    divider: {
        height: '50%',
        width: 1,
        backgroundColor: theme.brand.BlueViolet,
        opacity: 0.4,
    },
    linkIcon: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
        },
    },
    reactIcon: {
        fontSize: 24,
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
        },
    },
    bitcoin: {
        fontSize: 24,
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(1),
        },
    },
}));

export default useStyles;
