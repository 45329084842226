export const SET_PROFILES = 'SET_PROFILES';
export const INIT = { active: '', addresses: [] };

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_PROFILES:
            return payload;
        default:
            return state;
    }
}
