import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        // columnCount: 2,
        // marginTop: theme.spacing(2),
        // '& div:first-child': {
        //     marginTop: 0,
        // },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    card: {
        padding: 14,
        marginTop: theme.spacing(2),
        textAlign: 'left',

        flexBasis: '47%',

        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    },
    frame: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            height: 315,
            width: 560,
        },
    },
}));

export default useStyles;
