import { SECONDS_IN_DAY } from './variables';

export const calculateStakingInterest = (firstPayout, lastPayout, shares, payouts) => {
    let stakingInterest = 0;
    const lastIndex = Math.min(payouts.length, lastPayout);

    for (var i = firstPayout; i < lastIndex; i++) {
        stakingInterest += (payouts[i].payout * shares) / payouts[i].shares;
    }

    return stakingInterest;
};

export const getAmountOutAndPenalty = (amount, start, end, actualEnd, stakingInterest) => {
    const stakingSeconds = parseInt(end) - parseInt(start);
    const stakingDays = stakingSeconds / SECONDS_IN_DAY;
    const secondsStaked = actualEnd - parseInt(start);
    const daysStaked = secondsStaked / SECONDS_IN_DAY;
    const amountAndInterest = amount + stakingInterest;

    // Early
    if (stakingDays > daysStaked) {
        const payOutAmount = (amountAndInterest * secondsStaked) / stakingSeconds;

        const earlyUnstakePenalty = amountAndInterest - payOutAmount;

        return [payOutAmount, earlyUnstakePenalty, 'Early Unstake'];
        // In time
    } else if (daysStaked < stakingDays + 14) {
        return [amountAndInterest, 0, 'Completed'];
        // Late
    } else if (daysStaked < stakingDays + 714) {
        const daysAfterStaking = daysStaked - stakingDays;

        const payOutAmount = (amountAndInterest * (714 - daysAfterStaking)) / 700;

        const lateUnstakePenalty = amountAndInterest - payOutAmount;

        return [payOutAmount, lateUnstakePenalty, 'Late Unstake'];
        // Nothing
    } else {
        return [0, amountAndInterest, 'Late Unstake'];
    }
};
