import React from 'react';
// Modules
import { useSelector } from 'react-redux';

// Components
import ProfileWidget from './Widgets/Profile';
import OverviewWidget from './Widgets/Overview';
import AuctionWidget from './Widgets/Auction';
import MarketWidget from './Widgets/Market';
// styles
import useStyles from './styles';
import { Grid } from '@material-ui/core';

const App = () => {
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} md={6}>
                    <OverviewWidget />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProfileWidget />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <AuctionWidget />
                </Grid> */}
                <Grid item xs={12} md={6}>
                    <MarketWidget />
                </Grid>
            </Grid>
        </>
    );
};

export default App;
