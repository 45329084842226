import React from 'react';
import clsx from 'classnames';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Button, Hidden, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import {
    Email,
    Facebook,
    Telegram,
    Twitter,
    YouTube,
    Apple,
    GitHub,
    RedeemOutlined,
    Instagram,
} from '@material-ui/icons';
import AxionWhite from 'assets/images/axion-white.png';
import AxionColor from 'assets/images/axion-logo.png';
import Discord from 'assets/images/discord';
import AppleStore from 'assets/images/apple.png';
import GoogleStore from 'assets/images/google.png';
import { iOS } from 'utils/detect-device';
//vars
import {
    TODAY_SITES,
    AXION_TODAY_EMAIL,
    AXION_SITES,
    MAIL_CHIMP_URL,
    auctionUrl,
} from 'utils/variables';
// styles
import useStyles from './styles';

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    const classes = useStyles();

    let email;
    const submit = () =>
        email &&
        email.value.indexOf('@') > -1 &&
        onValidated({
            EMAIL: email.value,
        });

    return (
        <>
            <div className={classes.inputContainer}>
                {status === 'success' ? (
                    <Typography className={classes.thanks}>{message}</Typography>
                ) : (
                    <>
                        <input
                            ref={(node) => (email = node)}
                            className={classes.input}
                            placeholder="ENTER YOUR EMAIL"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={status === 'sending'}
                            onClick={submit}
                            fullWidth
                        >
                            subscribe
                        </Button>
                    </>
                )}
            </div>
            {status === 'error' && (
                <Typography variant="h6" className={classes.error}>
                    {message.split('<a')?.[0]}
                </Typography>
            )}
        </>
    );
};

const Footer = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();

    const Axion = (
        <img src={theme.mode === 'dark' ? AxionWhite : AxionColor} className={classes.socialIcon} />
    );

    return (
        <div className={classes.root}>
            <div className={classes.outerContainer}>
                <div className={classes.container}>
                    <span className={classes.title}>OFFICIAL AXION NETWORK</span>
                    <div className={classes.row}>
                        <Hidden smDown>
                            {!iOS() && (
                                <a
                                    target="_blank"
                                    href={AXION_SITES.MAIN}
                                    className={classes.social}
                                >
                                    {Axion}
                                </a>
                            )}
                            <a
                                target="_blank"
                                href={AXION_SITES.TELEGRAM}
                                className={classes.social}
                            >
                                <Telegram className={classes.socialIcon} />
                            </a>
                            <a
                                target="_blank"
                                href={AXION_SITES.TWITTER}
                                className={classes.social}
                            >
                                <Twitter className={classes.socialIcon} />
                            </a>
                            <a
                                target="_blank"
                                href={AXION_SITES.DISCORD}
                                className={classes.social}
                            >
                                <Discord
                                    className={classes.socialIcon}
                                    fill={theme.palette.primary.main}
                                />
                            </a>
                            <a target="_blank" href={AXION_SITES.GITHUB} className={classes.social}>
                                <GitHub className={classes.socialIcon} />
                            </a>
                        </Hidden>
                        <Hidden mdUp>
                            {!iOS() && (
                                <IconButton href={AXION_SITES.MAIN} target="_blank">
                                    {Axion}
                                </IconButton>
                            )}
                            <IconButton href={AXION_SITES.TELEGRAM} target="_blank">
                                <Telegram className={classes.socialIcon} />
                            </IconButton>
                            <IconButton href={AXION_SITES.TWITTER} target="_blank">
                                <Twitter className={classes.socialIcon} />
                            </IconButton>
                            <IconButton href={AXION_SITES.DISCORD} target="_blank">
                                <Discord
                                    className={classes.socialIcon}
                                    fill={theme.palette.primary.main}
                                />
                            </IconButton>
                            <IconButton href={AXION_SITES.GITHUB} target="_blank">
                                <GitHub className={classes.socialIcon} />
                            </IconButton>
                        </Hidden>
                    </div>
                </div>
                <div className={classes.container}>
                    <span className={classes.title}>CONNECT WITH AXION TODAY</span>
                    <div className={classes.row}>
                        <Hidden smDown>
                            <a
                                target="_blank"
                                href={TODAY_SITES.YOUTUBE}
                                className={classes.social}
                            >
                                <YouTube className={classes.socialIcon} />
                            </a>
                            <a
                                target="_blank"
                                href={TODAY_SITES.FACEBOOK}
                                className={classes.social}
                            >
                                <Facebook className={classes.socialIcon} />
                            </a>
                            <a
                                target="_blank"
                                href={TODAY_SITES.TWITTER}
                                className={classes.social}
                            >
                                <Twitter className={classes.socialIcon} />
                            </a>
                            <a
                                target="_blank"
                                href={TODAY_SITES.INSTAGRAM}
                                className={classes.social}
                            >
                                <Instagram
                                    fill={theme.palette.primary.main}
                                    className={classes.socialIcon}
                                />
                            </a>
                            <a target="_blank" href={TODAY_SITES.APPLE} className={classes.social}>
                                <Apple className={classes.socialIcon} />
                            </a>
                            <a
                                target="_blank"
                                href={`mailto:${AXION_TODAY_EMAIL}`}
                                className={classes.social}
                            >
                                <Email className={classes.socialIcon} />
                            </a>
                        </Hidden>
                        <Hidden mdUp>
                            <IconButton target="_blank" href={TODAY_SITES.YOUTUBE}>
                                <YouTube className={classes.socialIcon} />
                            </IconButton>
                            <IconButton target="_blank" href={TODAY_SITES.FACEBOOK}>
                                <Facebook className={classes.socialIcon} />
                            </IconButton>
                            <IconButton target="_blank" href={TODAY_SITES.TWITTER}>
                                <Twitter className={classes.socialIcon} />
                            </IconButton>
                            <IconButton target="_blank" href={TODAY_SITES.INSTAGRAM}>
                                <Instagram
                                    fill={theme.palette.primary.main}
                                    className={classes.socialIcon}
                                />
                            </IconButton>
                            <IconButton target="_blank" href={TODAY_SITES.APPLE}>
                                <Apple className={classes.socialIcon} />
                            </IconButton>
                            <IconButton target="_blank" href={`mailto:${AXION_TODAY_EMAIL}`}>
                                <Email className={classes.socialIcon} />
                            </IconButton>
                        </Hidden>
                    </div>
                </div>
            </div>
            <div className={classes.center}>
                <Typography variant="h6" className={classes.signup}>
                    Sign up for our newsletter
                </Typography>
                <MailchimpSubscribe
                    url={MAIL_CHIMP_URL}
                    render={({ subscribe, status, message }) => (
                        <CustomForm
                            status={status}
                            message={message}
                            onValidated={(formData) => subscribe(formData)}
                        />
                    )}
                />
                <div className={classes.row}>
                    <a
                        href="https://apps.apple.com/us/app/axion-today/id1559779486"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={AppleStore} alt="apple-store" className={classes.store} />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.axiontoday&hl=en_GB&gl=US"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={GoogleStore} alt="google-store" className={classes.store} />
                    </a>
                </div>
                <span className={clsx(classes.title, classes.copy)}>
                    © {new Date().getFullYear()} AXION TODAY
                </span>
            </div>
        </div>
    );
};

export default Footer;
