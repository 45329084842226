import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100%',
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    logo: {
        height: 120,
    },
    countdown: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    count: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '30px 24px',
    },
    number: {
        textAlign: 'center',
        fontSize: 62,
        color: 'white',
        fontFamily: `'Dosis', sans-serif`,
    },
    line: {
        marginTop: -1,
        height: 1,
        width: 30,
        margin: '8px auto',
        backgroundColor: 'white',
    },
    text: {
        textAlign: 'center',
        fontSize: 16,
        color: 'white',
    },

    graphic: {
        marginTop: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    liner: {
        width: 40,
        height: 1,
        backgroundColor: 'white',
    },
    square: {
        height: 8,
        width: 8,
        margin: '0px 8px',
        border: '2px solid #fff',
    },

    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    socialIcon: {
        width: 25,
        height: 33,
        objectFit: 'contain',
        color: '#fff',
    },

    link: {
        color: 'white',
        marginBottom: 12,
    },
}));

export default useStyles;
