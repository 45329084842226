const apexStyle = ({ theme, series, min, max, labels, xFormatter }) => ({
    options: {
        chart: {
            id: 'apexchart-example',
            toolbar: {
                show: false,
            },
            height: '100%',
            sparkline: { enabled: true },
        },
        tooltip: {
            theme: theme.mode,
            // fillSeriesColor: theme.brand.ChartLine,
            x: {
                formatter: xFormatter,
            },
        },
        stroke: {
            curve: 'smooth',
            colors: series.map(() => theme.brand.ChartLine),
        },
        markers: {
            colors: [theme.brand.ChartLine],
        },
        xaxis: {
            position: 'top',
            categories: labels,
            labels: {
                show: true,
                trim: true,
                style: {
                    colors: series.map(() => theme.brand.BoldText),
                    cssClass: 'apex-custom-line-x-axis',
                },
            },
            axisTicks: {
                color: theme.brand.BoldText,
                show: true,
            },
            // tickPlacement: 'between',
            // floating: true,
        },
        yaxis: {
            show: false,
            min: min - 0.00005,
            max: max + 0.00005,
        },
        grid: {
            show: true,
            borderColor: theme.brand.border,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        fill: {
            type: 'gradient',
            // colors: ['#ff0000', '#00ff00', '#0000ff'],
            gradient: {
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: !1,
                opacityFrom: 0.8,
                opacityTo: 0.2,
                // stops: [100],
            },
            colors: [theme.brand.ChartLine],
        },
    },
    series: [
        {
            name: 'AXN USD Value',
            data: series,
        },
    ],
});

export default apexStyle;
