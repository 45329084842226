import React from 'react';

import { palette } from 'global-styles';

export default ({ fill = palette.primary.main, ...props }) => {
    return (
        <svg
            width="24"
            height="21"
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.7091 11.6941L0.406404 9.6965C-0.132294 9.49299 -0.136509 8.72549 0.400083 8.51642L22.1615 0.0433294C22.623 -0.136565 23.0992 0.271149 22.998 0.760128L19.093 19.7199C19.001 20.1679 18.4812 20.3707 18.1139 20.1012L12.7831 16.1949C12.4593 15.9581 12.0183 15.9706 11.7085 16.2255L8.75236 18.6572C8.40962 18.9406 7.89269 18.7829 7.76135 18.3557L5.7091 11.6941ZM17.7934 4.18928L7.43027 10.6308C7.03274 10.8787 6.84592 11.3628 6.97445 11.8157L8.09469 15.7894C8.17405 16.0707 8.5793 16.0422 8.61793 15.7512L8.9094 13.5723C8.96419 13.1618 9.15803 12.7839 9.45864 12.5012L18.0111 4.47753C18.1712 4.3275 17.9795 4.07329 17.7934 4.18928Z"
                fill={fill}
            />
        </svg>
    );
};
