import React from 'react';

// Modules
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setLightMode, setDarkMode } from 'redux/actions/theme';
// Components
import { BiSun, BiMoon } from 'react-icons/bi';
import { Drawer, List, ListItem, Hidden, Switch, Tooltip } from '@material-ui/core';
import { ImportExport } from '@material-ui/icons';

// styles and images
import Tree from 'assets/images/tree.png';
import AxionTodayDark from 'assets/images/axion-today-dark.png';
import AxionTodayWhite from 'assets/images/axion-today-white.png';
import menuItems from '../Navigation/menuItems';
import useStyles from './styles';

function NavDrawer({ children, ...props }) {
    const dispatch = useDispatch();
    const { axion, theme } = useSelector(({ axion, auction, theme }) => ({
        axion,
        auction,
        theme,
    }));
    const classes = useStyles();

    function setMode(e) {
        if (theme.mode === 'light') {
            dispatch(setDarkMode());
        } else if (theme.mode === 'dark') {
            dispatch(setLightMode());
        }
    }

    return (
        <Hidden smDown>
            <Drawer variant="permanent" classes={{ paper: clsx(classes.drawer) }}>
                <div className={classes.top}>
                    <Link to="/">
                        <img
                            src={theme.mode === 'dark' ? AxionTodayWhite : AxionTodayDark}
                            className={classes.logo}
                            alt="logo"
                        />
                    </Link>
                    <div className={classes.marketItem}>
                        <div className={classes.row}>
                            <span className={classes.token}>AXN</span>
                            <ImportExport className={classes.switch} />
                            <span className={clsx(classes.exchange, classes.usd)}>USD</span>
                        </div>
                        <p className={classes.price}>{axion.stats?.currentPriceUsd.toFixed(5)}</p>
                        <p
                            className={clsx(classes.percent, {
                                [classes.positive]: Math.sign(axion.stats?.percentChange24h) !== -1,
                                [classes.negative]: Math.sign(axion.stats?.percentChange24h) === -1,
                            })}
                        >
                            {axion.stats?.percentChange24h.toFixed(2)}%
                        </p>
                    </div>
                </div>
                <List classes={{ root: classes.list }}>
                    {menuItems.map(({ name, pathname, Icon }) => {
                        return (
                            <Tooltip title={name} key={pathname}>
                                <ListItem
                                    button
                                    onClick={() => props.history.push(pathname)}
                                    classes={{
                                        root: clsx(classes.listItem, {
                                            [classes.activeItem]:
                                                props.location.pathname === pathname,
                                        }),
                                    }}
                                >
                                    <Icon
                                        className={clsx(classes.menuIcons, {
                                            [classes.activeIcon]:
                                                props.location.pathname === pathname,
                                        })}
                                    />
                                </ListItem>
                            </Tooltip>
                        );
                    })}
                </List>
                <div className={classes.bottom}>
                    {theme.mode === 'light' ? (
                        <BiSun className={classes.modeIcon} />
                    ) : (
                        <BiMoon className={classes.modeIcon} />
                    )}
                    <Switch color="primary" onChange={setMode} checked={theme.mode === 'light'} />
                    {/* <BsMoon className={classes.modeIcon} /> */}
                </div>
            </Drawer>
        </Hidden>
    );
}

export default withRouter(NavDrawer);
