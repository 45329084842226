import React from 'react';
// Modules
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { store, persistor } from './redux/config';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import reportWebVitals from './reportWebVitals';
// CSS Imports
import './index.css';

const ConnectedApp = (props) => {
    const { theme } = useSelector(({ theme }) => ({ theme }));
    const muiTheme = createMuiTheme(theme);

    return (
        <MuiThemeProvider theme={muiTheme}>
            <Helmet>
                <style>{`
                    body { background-color: ${theme.brand.Body}; }
                `}</style>
            </Helmet>
            <Router />
        </MuiThemeProvider>
    );
};

const App = (props) => (
    <Provider store={store}>
        <PersistGate loading={<CircularProgress size={150} />} persistor={persistor}>
            <ConnectedApp />
        </PersistGate>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
