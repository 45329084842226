import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 12,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        width: '100%',
        maxWidth: 296,
        minHeight: 100,
        maxHeight: 100,
        margin: 20,
        backgroundColor: theme.brand.Body,
    },
    span: {
        display: 'block',
        fontSize: 14,
        margin: '3px 0px',
        textTransform: 'none',
    },
    alignBottom: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
    },
    h4: {
        textTransform: 'none',
        fontSize: 24,
    },
    row: {
        display: 'flex',
    },

    box: {
        height: 40,
        // width: 50,
    },
    middle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
    },
    since: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        color: theme.brand.BoldText,
        fontSize: 12,
    },
}));

export default useStyles;
