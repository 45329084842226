export const SET_AXION = 'SET_AXION';
export const AXION_ERROR = 'AXION_ERROR';
export const INIT = {
    ethUsd: 0.0,
    ethPercentChange24h: 0.0,
    ethPercentChange7d: 0.0,
    ethPercentChange14d: 0.0,
    ethPercentChange30d: 0.0,
    totalSupply: 0.0,
    currentPriceUsd: 0.0,
    currentPriceEth: 0.0,
    marketCap: 0.0,
    marketCapRank: 0.0,
    percentChange24h: 0.0,
    percentChange7d: 0.0,
    percentChange14d: 0.0,
    percentChange30d: 0.0,
    percentChange60d: 0.0,
    total: 0.0,
    communityData: {},
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_AXION:
            return payload;
        case AXION_ERROR:
        default:
            return state;
    }
}
