import React, { useState, useEffect } from 'react';
// modules
import clsx from 'classnames';
import TimeAgo from 'react-timeago';
import { withRouter } from 'react-router-dom';
import { numberWithCommas } from 'utils/string';
import { useDispatch, useSelector } from 'react-redux';
import { addAddress } from 'redux/actions/profiles';
import { SECONDS_IN_DAY, START_OF_CONTRACTS, WBTC_1E10 } from 'utils/variables';
import web3 from 'web3';
import BN from 'bn.js';
// Components
import Card from 'components/Card';
import { Button, Tooltip } from '@material-ui/core';
import { IoOpenOutline } from 'react-icons/io5';
import { calculateStakingInterest, getAmountOutAndPenalty } from 'utils/calculate-payout';
// styles
import useStyles from './styles';
// Assets
import { ReactComponent as Crown } from 'assets/images/crown.svg';
import { ReactComponent as Fire } from 'assets/images/fire.svg';
import Bitcoin from 'assets/images/Bitcoin-Logo.png';
import AcceleratorLogo from 'assets/images/AcceleratorLogo.png';
import ColliderFailure from 'assets/images/collider-failure.png';
import ColliderSuccess from 'assets/images/collider-success.png';
import shrimp from 'assets/images/shrimp.png';
import crab from 'assets/images/crab.png';
import fish from 'assets/images/fish.png';
import dolphin from 'assets/images/dolphin.png';
import kraken from 'assets/images/kraken.png';
import shark from 'assets/images/shark.png';
import whale from 'assets/images/whale.png';

const image = {
    shrimp,
    crab,
    fish,
    dolphin,
    kraken,
    shark,
    whale,
};

const NewsItem = ({ data, ...props }) => {
    const classes = useStyles(props);
    const { axion, daily } = useSelector(({ axion, daily }) => ({ axion, daily }));
    const dispatch = useDispatch();
    const [amountOut, setAmountOut] = useState({
        earned: 0,
        penalty: 0,
        type: '',
    });

    useEffect(() => {
        if (data.type === 'StakeDeleted') {
            Math.round((data.start - START_OF_CONTRACTS) / SECONDS_IN_DAY);
            Math.round((data.end - START_OF_CONTRACTS) / SECONDS_IN_DAY);
            const firstPayout = Math.floor((data.start - START_OF_CONTRACTS) / SECONDS_IN_DAY);
            const end = Math.min(data.end, data.selectedEndDate);
            const lastPayout = Math.floor((end - START_OF_CONTRACTS) / SECONDS_IN_DAY);

            let payout = calculateStakingInterest(
                firstPayout,
                lastPayout,
                data.shares,
                [...(daily?.Payouts?.Items ?? [])].reverse()
            );

            const amountOutAndPenalty = getAmountOutAndPenalty(
                data.amount,
                data.start,
                data.selectedEndDate,
                end,
                payout
            );

            setAmountOut({
                earned: payout,
                penalty: amountOutAndPenalty[1],
                type: amountOutAndPenalty[2],
            });
        } else {
            setAmountOut({
                earned: 0,
                penalty: 0,
                type: '',
            });
        }
    }, [data.id]);

    function handleAddProfile() {
        dispatch(addAddress(data.address ? data.address : data.from, true));
        props.history.push('/portfolio');
    }

    function getDaysStaked() {
        return data.stakeDays;
    }
    function getDaysServed() {
        return data.servedDays;
    }

    /** render */
    function renderAccelerator() {
        const burned = parseFloat(web3.utils.fromWei(data.axionBought ?? '0')); // "5743034.602988242227197480"
        const staker = parseFloat(
            web3.utils.fromWei(new BN(data.tokenBought ?? '0').mul(WBTC_1E10))
        );

        return (
            <>
                <div className={classes.column}>
                    <span className={clsx(classes.title, classes.paid)}>Buyback burn</span>
                    <Tooltip arrow title={`${burned} AXN bought back and burned`}>
                        <span className={clsx(classes.amount, classes.btcEarned)}>
                            {numberWithCommas(burned, 0)} Burned
                        </span>
                    </Tooltip>
                    <span className={clsx(classes.subtitle)}>
                        ${numberWithCommas(burned * axion.stats?.currentPriceUsd)}
                    </span>
                </div>
                <div className={classes.column}>
                    <div className={classes.innerColumn}>
                        <img src={AcceleratorLogo} className={classes.btc} alt="accelerator" />
                    </div>
                </div>
                <div className={classes.column}>
                    <span className={clsx(classes.title, classes.paid)}>Paid to Stakers</span>
                    <Tooltip arrow title={`${staker} BTC`}>
                        <span className={clsx(classes.amount, classes.btcEarned)}>
                            {staker.toFixed(4)} BTC
                        </span>
                    </Tooltip>
                    <span className={clsx(classes.subtitle)}>
                        ${numberWithCommas(staker * axion.stats?.btcPrice)}
                    </span>
                </div>

                <div className={classes.column}>
                    <span className={clsx(classes.title, classes.staked)}>Stake Days</span>
                    <span className={clsx(classes.amount, classes.staked)}>{data.stakeDays}</span>
                </div>
            </>
        );
    }

    function renderVentureWithdraw() {
        const interest = parseFloat(
            web3.utils.fromWei(new BN(data.interest ?? '0').mul(WBTC_1E10))
        );

        return (
            <>
                <div className={classes.column}>
                    <span className={clsx(classes.title, classes.earned)}>Amount</span>
                    <Tooltip arrow title={`${interest} BTC`}>
                        <span className={clsx(classes.amount, classes.earned)}>
                            {interest.toFixed(4)} BTC
                        </span>
                    </Tooltip>
                    <span className={clsx(classes.subtitle)}>
                        ${numberWithCommas(interest * axion.stats?.btcPrice)}
                    </span>
                </div>
                <div className={classes.column}>
                    <div className={classes.innerColumn}>
                        <img src={Bitcoin} className={classes.btc} alt="bitcoin" />
                    </div>
                </div>
            </>
        );
    }

    return (
        <Card classes={{ root: classes.root }}>
            <div className={classes.content}>
                <a
                    className="links"
                    href={`https://stake.axion.network/${
                        data.type === 'Redeemed'
                            ? 'collider'
                            : data.type === 'AcceleratorEth' || data.type === 'AcceleratorToken'
                            ? 'accelerator'
                            : ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className={classes.newsHeader}>
                        <Tooltip arrow title={data.ecoBefore}>
                            <img
                                src={image[data.ecoBefore]}
                                className={classes.ecoImage}
                                alt="eco-before"
                            />
                        </Tooltip>
                        <span
                            className={clsx(
                                classes.newsType,
                                clsx({
                                    [classes.EarlyUnstake]: amountOut.type === 'Early Unstake',
                                    [classes.LateUnstake]: amountOut.type === 'Late Unstake',
                                    [classes.Stake]: data.type === 'StakeCreated',
                                    [classes.Unstake]: data.type === 'StakeDeleted',
                                    [classes.MaxShare]: data.type === 'StakeUpgraded',
                                    [classes.collider]: data.type === 'Redeemed',
                                    [classes.WithdrawLiquidDiv]: data.type === 'WithdrawLiquidDiv',
                                    [classes.StakeSplit]: data.type === 'StakeSplit',
                                    [classes.accelerator]:
                                        data.type === 'AcceleratorEth' ||
                                        data.type === 'AcceleratorToken',
                                })
                            )}
                        >
                            {amountOut.type
                                ? amountOut.type
                                : data.type === 'Redeemed'
                                ? 'Collider'
                                : data.type === 'AcceleratorEth'
                                ? 'Accelerator'
                                : data.type === 'AcceleratorToken'
                                ? 'Accelerator'
                                : data.type === 'StakeUpgraded'
                                ? 'Stake Upgrade'
                                : data.type === 'WithdrawLiquidDiv'
                                ? 'Cashed Out'
                                : data.type === 'StakeCreated'
                                ? 'New Stake'
                                : data.type === 'StakeDeleted'
                                ? 'Unstake'
                                : data.type === 'StakeSplit'
                                ? 'Stake Split'
                                : `${data.type}`}
                        </span>
                        {data.ecoBefore !== data.ecoAfter && data.ecoAfter ? (
                            <Tooltip arrow title={data.ecoAfter}>
                                <img
                                    src={image[data.ecoAfter]}
                                    className={classes.ecoImageAfter}
                                    alt="ecoafter"
                                />
                            </Tooltip>
                        ) : (
                            <div
                                className={classes.ecoImageAfter}
                                style={{
                                    color: 'transparent',
                                    backgroundColor: 'transparent',
                                }}
                            />
                        )}
                    </div>
                </a>

                <div className={classes.info}>
                    {data.type === 'StakeUpgraded' && (
                        <>
                            <div className={classes.column}>
                                <span className={clsx(classes.title, classes.staked)}>
                                    Staked (axn)
                                </span>
                                <span className={clsx(classes.amount, classes.staked)}>
                                    {numberWithCommas(data.amount, 0)}
                                </span>
                                <span className={clsx(classes.subtitle)}>
                                    ${numberWithCommas(data.amount * axion.stats?.currentPriceUsd)}
                                </span>
                            </div>
                            <div className={classes.column}>
                                <div className={classes.innerColumn}>
                                    <Fire className={classes.crown} />
                                </div>
                                <div className={classes.innerColumn}>
                                    <span className={classes.title}>MAX SHARES</span>
                                </div>

                                {data.type === 'StakeDeleted' && (
                                    <div className={classes.innerColumn}>
                                        <span className={classes.title}>SERVED </span>
                                        <span className={classes.amount}>{getDaysServed()}</span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {data.type === 'StakeSplit' && (
                        <>
                            <div className={classes.column}>
                                <span className={clsx(classes.title, classes.staked)}>Percent</span>
                                <span className={clsx(classes.amount, classes.staked)}>
                                    {data.percent}
                                </span>
                            </div>
                        </>
                    )}
                    {data.type === 'Redeemed' && (
                        <>
                            <div className={classes.column}>
                                <span className={clsx(classes.title, classes.staked)}>
                                    Particles
                                </span>
                                <span className={clsx(classes.amount, classes.staked)}>
                                    {numberWithCommas(data.particles, 2)}
                                </span>
                            </div>
                            <div className={classes.column}>
                                <div className={classes.innerColumn}>
                                    <img
                                        src={data.succeeded ? ColliderSuccess : ColliderFailure}
                                        className={classes.btc}
                                        alt="collider"
                                    />
                                </div>
                            </div>
                            <div className={classes.column}>
                                <span className={clsx(classes.title, classes.staked)}>
                                    Multiplier
                                </span>
                                <span className={clsx(classes.amount, classes.staked)}>
                                    {data.rarity}x
                                </span>
                            </div>
                            <div className={classes.column}>
                                <span className={clsx(classes.title, classes.staked)}>
                                    Collided?
                                </span>
                                <span className={clsx(classes.amount, classes.staked)}>
                                    {data.succeeded ? (
                                        <span className={classes.earned}>Success!</span>
                                    ) : (
                                        <span className="error">Failure!</span>
                                    )}
                                </span>
                            </div>
                        </>
                    )}
                    {data.type === 'AcceleratorEth' && renderAccelerator()}
                    {data.type === 'AcceleratorToken' && renderAccelerator()}
                    {data.type === 'WithdrawLiquidDiv' && renderVentureWithdraw()}
                    {data.type === 'StakeCreated' && (
                        <>
                            <div className={classes.column}>
                                <span className={clsx(classes.title, classes.staked)}>
                                    Staked (axn)
                                </span>
                                <span className={clsx(classes.amount, classes.staked)}>
                                    {numberWithCommas(data.amount, 0)}
                                </span>
                                <span className={clsx(classes.subtitle)}>
                                    ${numberWithCommas(data.amount * axion.stats?.currentPriceUsd)}
                                </span>
                            </div>
                            <div className={classes.column}>
                                {data.type === 'StakeCreated' &&
                                    parseInt(getDaysStaked()) === 5555 && (
                                        <div className={classes.innerColumn}>
                                            <Crown className={classes.crown} />
                                        </div>
                                    )}
                                <div className={classes.innerColumn}>
                                    <span className={classes.title}>DAYS </span>
                                    <span className={classes.amount}>{getDaysStaked()}</span>
                                </div>

                                {data.type === 'StakeDeleted' && (
                                    <div className={classes.innerColumn}>
                                        <span className={classes.title}>SERVED </span>
                                        <span className={classes.amount}>{getDaysServed()}</span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {data.type === 'StakeDeleted' && (
                        <>
                            <div className={classes.column}>
                                <span className={clsx(classes.title, classes.staked)}>
                                    Principal (axn)
                                </span>
                                <span className={clsx(classes.amount, classes.staked)}>
                                    {numberWithCommas(data.amount, 0)}
                                </span>
                                <span className={clsx(classes.subtitle)}>
                                    ${numberWithCommas(data.amount * axion.stats?.currentPriceUsd)}
                                </span>
                            </div>
                            <div className={classes.column}>
                                {data.type === 'Stake' && parseInt(getDaysStaked()) === 5555 && (
                                    <div className={classes.innerColumn}>
                                        <Crown className={classes.crown} />
                                    </div>
                                )}
                                <div className={classes.innerColumn}>
                                    <span className={classes.title}>DAYS </span>
                                    <span className={classes.amount}>{getDaysStaked()}</span>
                                </div>

                                {data.type === 'StakeDeleted' && (
                                    <div className={classes.innerColumn}>
                                        <span className={classes.title}>SERVED </span>
                                        <span className={classes.amount}>{getDaysServed()}</span>
                                    </div>
                                )}
                            </div>
                            <div className={classes.column}>
                                <span className={clsx(classes.title, classes.earned)}>
                                    WITHDRAWN
                                </span>
                                <span className={clsx(classes.amount, classes.earned)}>
                                    {numberWithCommas(data.payout, 0)}
                                </span>
                                <span className={clsx(classes.subtitle)}>
                                    ${numberWithCommas(data.payout * axion.stats?.currentPriceUsd)}
                                </span>
                            </div>
                        </>
                    )}
                    {!!amountOut.penalty && (
                        <div className={classes.column}>
                            <span className={clsx(classes.title, classes.penalty)}>PENALTY</span>
                            <span className={clsx(classes.amount, classes.penalty)}>
                                {numberWithCommas(amountOut.penalty)}
                            </span>
                            <span className={clsx(classes.subtitle)}>
                                $
                                {numberWithCommas(amountOut.penalty * axion.stats?.currentPriceUsd)}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.actions}>
                <Button color="primary" className={classes.button}>
                    <span className={classes.buttonText}>
                        {data.type === 'StakeDeleted' ? (
                            <TimeAgo date={new Date(parseInt(data.end) * 1000)} />
                        ) : data.type === 'Bid' ? (
                            <TimeAgo date={new Date(parseInt(data.start) * 1000)} />
                        ) : data.type === 'VentureBid' ||
                          data.type === 'WithdrawLiquidDiv' ||
                          data.type === 'AcceleratorEth' ||
                          data.type === 'AcceleratorToken' ||
                          data.type === 'StakeSplit' ||
                          data.type === 'Redeemed' ||
                          (data.type === 'StakeCreated' && data.stakeSplit) ? (
                            data.time ? (
                                <TimeAgo date={new Date(parseInt(data.time) * 1000)} />
                            ) : (
                                'N/A'
                            )
                        ) : data.type === 'StakeCreated' ? (
                            <TimeAgo date={new Date(parseInt(data.start) * 1000)} />
                        ) : (
                            <TimeAgo date={new Date(parseInt(data.start) * 1000)} />
                        )}
                    </span>
                </Button>
                <Button color="primary" onClick={handleAddProfile} className={classes.button}>
                    <IoOpenOutline className={classes.openIcon} />
                </Button>
            </div>
        </Card>
    );
};

export default withRouter(NewsItem);
