import React from 'react';

import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';

const PriceTooltip = ({
    value,
    tooltipValue,
    children,
    suffix = 'AXN',
    tooltipSuffix = '',
    fixed = 0,
}) => {
    const { axion } = useSelector(({ axion }) => ({ axion }));

    if (children) {
        return (
            <Tooltip
                placement="top"
                title={`$${(
                    parseFloat(tooltipValue || value || 0) * (axion.stats?.currentPriceUsd || 0)
                ).numberWithCommas(2)}${tooltipSuffix}`}
                arrow
            >
                {children}
            </Tooltip>
        );
    }

    return (
        <Tooltip
            placement="top"
            title={`$${(
                parseFloat(tooltipValue || value || 0) * (axion.stats?.currentPriceUsd || 0)
            ).numberWithCommas(2)}${tooltipSuffix}`}
            arrow
        >
            <span>
                {value.numberWithCommas(fixed)} {suffix}
            </span>
        </Tooltip>
    );
};

export default PriceTooltip;
