import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1200,
        marginTop: 100,
        paddingBottom: 40,

        [theme.breakpoints.down('sm')]: {
            paddingBottom: 60,
        },
    },
    progress: {
        color: 'white',
    },
    store: {
        height: 60,
        margin: '0px 6px',
        cursor: 'pointer',
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-around',
            flexWrap: 'nowrap',
            width: '80%',
            margin: '12px 0px',
        },
    },
    outerContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    social: {
        boxShadow: theme._shadows.bar,
        width: 60,
        height: 60,
        borderRadius: 20,
        backgroundColor: theme.brand.CardBG,
        margin: '21px 11px',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
    },
    socialIcon: {
        width: 25,
        height: 33,
        objectFit: 'contain',
        color: theme.palette.primary.main,
    },
    connect: {
        boxShadow: theme._shadows.bar,
        width: 30,
        height: 30,
        borderRadius: 10,
        backgroundColor: theme.brand.CardBG,
        margin: '0 11px',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
    },
    connectIcon: {
        width: 16,
        height: 20,
        objectFit: 'contain',
        color: theme.palette.primary.main,
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: 16,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            margin: '12px 0px',
        },
    },
    inputContainer: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.brand.CardBG,
        width: 300,
        padding: '12px 12px',
        borderRadius: 12,
        boxShadow: theme._shadows.bar,
    },
    input: {
        flexGrow: 1,
        minWidth: 200,
        color: theme.brand.BoldText,
        '&::placeholder': {
            color: theme.brand.IconColor,
        },
    },
    thanks: {
        textAlign: 'center',
        width: '100%',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    error: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
    },
    center: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    copy: {
        marginTop: theme.spacing(6),
    },
    signup: {
        marginTop: theme.spacing(4),
    },
    donations: {
        margin: '12px 0px 0px 0px',
        color: theme.brand.BoldText,
    },
    verified: {
        marginTop: theme.spacing(6),
    },
    noTransform: {
        textTransform: 'none',
    },
}));

export default useStyles;
