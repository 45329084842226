import { combineReducers } from 'redux';

// Import reducers here
import axion from './axion';
import bpd from './bpd';
import daily from './daily';
import events from './events';
import snackbar from './snackbar';
import notifications from './notifications';
import profiles from './profiles';
import staker from './staker';
import globals from './globals';
import theme from './theme';

const rootReducer = combineReducers({
    axion,
    bpd,
    events,
    daily,
    snackbar,
    profiles,
    notifications,
    globals,
    staker,
    theme,
});

export default rootReducer;
