import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 5,
    },
    cardTitle: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: 20,
        color: theme.brand.GrayChateau,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    number: {
        marginLeft: theme.spacing(1),
        backgroundColor: theme.brand.axion,
        borderRadius: '50%',
        height: 30,
        width: 30,
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
            marginBottom: 2,
        },
    },
    cardBody: {
        overflow: 'auto',
        padding: '0px 20px',
    },
    table: {
        width: '100%',
        borderCollapse: 'inherit',
        borderSpacing: '0 5px',
        marginBottom: 10,
        marginTop: 15,

        '& thead th': {
            padding: '12px 10px',
            fontWeight: '500',
            fontSize: 12,
            textAlign: 'center',
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
            cursor: 'default',
        },

        '& tbody tr td': {
            background: theme.brand.Zircon,
            padding: '25px 5px',
            textAlign: 'center',
            cursor: 'default',
            fontWeight: '400',
            fontSize: 16,
            color: theme.brand.BoldText,
        },
        '& tbody tr td:first-child': {
            borderRadius: '20px 0 0 20px',
        },
        '& tbody tr td:last-child': {
            borderRadius: '0 20px 20px 0',
        },
    },
    theader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },

    totals: {
        '& td': {
            minHeight: 40,
            backgroundColor: `${theme.brand.axion} !important`,
            color: '#fff !important',
        },
    },
}));

export default useStyles;
