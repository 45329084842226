import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        flexBasis: '49%',
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            marginBottom: theme.spacing(2),
        },
        height: '100%',
    },
    leftSide: {
        borderRight: `1px solid ${theme.brand.border}`,
        display: 'flex',
        flexDirection: 'column',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
    },
    secondTitle: {
        [theme.breakpoints.down('md')]: {
            borderTop: `1px solid ${theme.brand.border}`,
            marginTop: 24,
        },
    },
    titleIcon: {
        color: theme.brand.IconColor,
        marginRight: theme.spacing(1),
        fontSize: 24,
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    maxWidthCardBody: {
        maxWidth: 480,
        alignSelf: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    between: {
        justifyContent: 'space-between',
        flex: 1,
    },
    leftSideInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        paddingBottom: 20,

        [theme.breakpoints.down('sm')]: {
            // alignItems: 'center',
        },
    },
    donut: {
        width: '100%',
        maxWidth: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stats: {
        marginTop: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stat: {
        marginBottom: 24,
    },
    bottomStat: {
        alignItems: 'center',
        marginRight: -24,

        [theme.breakpoints.up('sm')]: {
            marginRight: 0,
            alignItems: 'flex-end',
        },
    },
    shares: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        marginRight: 24,
        marginTop: -20,
        alignItems: 'flex-end',
        alignSelf: 'flex-end',

        [theme.breakpoints.up('sm')]: {
            marginLeft: 24,
            marginTop: -24,
            alignItems: 'flex-start',
            alignSelf: 'unset',
        },
    },
    sharesTotalSupply: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    shareRate: {
        color: theme.brand.purple,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    sharesBarChart: {
        borderBottom: `1px solid ${theme.brand.border}`,
    },
    venture: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 30,
    },
    ventureImage: {
        maxHeight: 140,
        width: 349,
    },
}));

export default useStyles;
