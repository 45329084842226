import { makeStyles } from '@material-ui/core/styles';

import { NavigationBarHeight } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    appbar: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            height: 80,
            backgroundColor: 'white',
            width: '100%',
        },
    },

    root: {
        display: 'flex',
        height: '100%',
    },
    logo: {
        height: 160,
        width: 'auto',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        textAlign: 'center',
        maxWidth: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 100,
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            // paddingBottom: 80,
            padding: theme.spacing(1),
            marginTop: theme.mixins?.bar?.height ?? NavigationBarHeight,
        },
    },

    bottomNav: {
        width: '100%',
        maxWidth: '100%',
        position: 'fixed',
        bottom: 0,
        boxShadow: theme._shadows.bottom,
        zIndex: 99,
        backgroundColor: theme.brand.NavigationBackground,
    },
    icon: {
        fontSize: 22,
    },
}));

export default useStyles;
