const styles = (theme) => ({
    root: {
        padding: theme.spacing(2),
        position: 'fixed',
        bottom: 20,
        right: 20,
        borderRadius: 12,
        minWidth: 350,
        minHeight: 80,
        zIndex: 999,
        backgroundColor: theme.brand.NavigationBackground,

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            minWidth: 'unset',
            bottom: 20,
            // top: 20,
            left: 10,
            right: 10,
        },
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    league: {
        marginLeft: theme.spacing(1),
        height: 30,
        width: 30,
    },
    crown: {
        height: 30,
        width: 30,
        marginRight: theme.spacing(1),
    },
    btc: {
        height: 30,
    },
    body: {
        fontWeight: 'bold',
        fontSize: 16,
        color: theme.brand.BoldText,
        margin: '12px 0px 0px 0px',
    },
    note: {
        margin: '6px 0px 12px 0px',
        fontSize: 14,
        color: theme.brand.BoldText,
    },
    cardFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
});

export default styles;
