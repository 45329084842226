import { BsGraphUp, BsFillPersonFill } from 'react-icons/bs';
import { MdDonutSmall } from 'react-icons/md';
import { BiNews } from 'react-icons/bi';
import { IoHomeOutline } from 'react-icons/io5';

export default [
    { name: 'Home', pathname: '/', Icon: IoHomeOutline },
    { name: 'Analytics', pathname: '/analytics', Icon: BsGraphUp },
    { name: 'Overview', pathname: '/overview', Icon: MdDonutSmall },
    { name: 'Portfolio', pathname: '/portfolio', Icon: BsFillPersonFill },
    { name: 'Activity', pathname: '/activity', Icon: BiNews },
];
