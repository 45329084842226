import React, { useState, useEffect } from 'react';
// Modules
import { useSelector } from 'react-redux';
import getUserPayouts from 'utils/get-user-payout';
// Components
import Account from './Widgets/Account';
import ProfileTable from './Widgets/ProfileTable';
// import UserPayoutChart from './Widgets/UserPayoutChart';
import Venture from './Widgets/Venture';
// styles
import useStyles from './styles';

const order = ['Active Stakes', 'Matured Stakes', 'Completed Stakes'];

const Portfolio = () => {
    const classes = useStyles();
    const { staker, daily } = useSelector(({ staker, daily }) => ({
        staker,
        daily,
    }));

    const [payouts, setPayouts] = useState(new Array(daily?.Payouts?.Items?.length ?? 0).fill(0));

    useEffect(() => {
        if (staker?.stakes && daily?.Payouts?.Items) {
            const payouts = [...daily?.Payouts?.Items?.reverse?.()];

            const _payouts = getUserPayouts(staker.stakes, payouts);
            setPayouts(_payouts);
        }
    }, [staker?.stakes, daily.Payouts]);

    return (
        <div className={classes.spacing}>
            <Account payouts={payouts} />
            {staker && <Venture classes={{ root: classes.spacing }} />}
            {/* {staker && <UserPayoutChart payouts={payouts} classes={{ card: classes.spacing }} />} */}
            {staker &&
                order.map((key) => {
                    if (!staker.sessions[key]) return null;
                    return (
                        <ProfileTable
                            key={key}
                            data={staker.sessions[key]}
                            classes={{ root: classes.spacing }}
                            title={key}
                        />
                    );
                })}
        </div>
    );
};

export default Portfolio;
