import { colors } from 'global-styles';

const barHeight = 24;
const dotSize = 8;

const styles = (theme) => ({
    root: {
        boxSizing: 'border-box',

        display: 'flex',
        flexDirection: 'column',

        padding: 40,

        width: '100%',

        '@media (min-width: 860px)': {
            display: 'none',
        },
    },
    bigPayDay: {
        bottom: barHeight,

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    bigPayDayAmt: {
        color: theme.brand.BoldText,
        fontSize: 30,
        fontWeight: 500,
        lineHeight: 1.2,
    },
    bars: {
        flex: 1,
    },
    bar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        marginBottom: 15,
    },
    segment: {
        borderTopRightRadius: barHeight / 2,
        borderBottomRightRadius: barHeight / 2,

        height: barHeight,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        marginLeft: 5,
        marginRight: 5,
    },
    down: {
        borderTopLeftRadius: barHeight / 2,
    },
    bpd: {
        marginLeft: 10,
        marginRight: 10,

        color: 'white',

        fontSize: 12,
        fontWeight: 500,
        whiteSpace: 'nowrap',
    },
    info: {
        position: 'absolute',
    },
    infoBottom: {
        top: 12,
    },
    infoTop: {
        bottom: 12,
    },
    infoLine: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    axion: {
        color: theme.brand.BoldText,

        fontSize: 17,
        fontWeight: 500,

        lineHeight: 1.2,
    },
    axn: {
        marginLeft: 4,
        // fontColor: theme.brand.BoldText,
    },
    smallLabel: {
        color: theme.brand.GrayChateau,

        fontSize: 12,
        fontWeight: 500,
        textTransform: 'uppercase',

        lineHeight: 1.2,
        whiteSpace: 'nowrap',
    },
});

export default styles;
