import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 5,
        marginTop: theme.spacing(2),
    },
    cardTitle: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.brand.border}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },

    vertical: {
        backgroundColor: theme.brand.border,
        height: 40,
        width: 1,
    },
    title: {
        padding: '10px 20px',
        fontWeight: 'bold',
        fontSize: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'uppercase',
        borderRadius: 18,
        width: 'max-content',
        color: 'white',
        fontSize: 30,
        margin: '10px 0',
        background: 'linear-gradient(136.67deg, rgb(14, 51, 151) 8.34%, rgb(67, 110, 224) 95.26%)',
        boxShadow: theme._shadows.news,
    },
    cardBody: {
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    section: {
        flex: 1,
        padding: 20,
    },
    sectionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingBottom: 20,
        textTransform: 'uppercase',
        borderBottom: `1px solid ${theme.brand.border}`,
        color: theme.brand.BoldText,
    },
    sectionContent: {
        paddingTop: 20,
        display: 'flex',
    },
    sectionBorder: {
        borderRight: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            borderBottom: `1px solid ${theme.brand.border}`,
        },
    },
    stats: {
        flexGrow: 1,
    },
    stat: {
        marginBottom: theme.spacing(3),
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    legend: {
        height: 8,
        width: 30,
        borderRadius: 10,
        marginRight: theme.spacing(1),
    },
    graph: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },

    /** Auction pool */
    circle: {
        minHeight: 32,
        minWidth: 32,
        height: 32,
        width: 32,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circleImage: {
        objectFit: 'scale-down',
        height: '70%',
        width: '70%',
    },
    wbtc: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    axion: {
        backgroundColor: theme.brand.axion,
    },
    ethereum: {
        backgroundColor: theme.brand.teal,
    },
    uniswap: {
        backgroundColor: theme.brand.Viola,
    },
    btc: {
        backgroundColor: '#fff',
    },
    caption: {
        minHeight: 28,
        fontSize: 12,
        textAlign: 'center',
        maxWidth: 120,
        textTransform: 'uppercase',
        marginBottom: 10,
        marginTop: 15,
        fontWeight: 'bold',
        color: theme.brand.GrayChateau,
    },
    count: {
        fontSize: 30,
        color: theme.brand.BoldText,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    value: {
        fontSize: 20,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    gridContainer: {
        padding: '24px 0px',
        borderBottom: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    borderGrid: {
        borderRight: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            marginBottom: theme.spacing(1),
            padding: theme.spacing(2),
        },
    },
    cardFooter: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    trees: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
        border: `1px solid ${theme.brand.border}`,
        borderRadius: 12,
        cursor: 'pointer',
    },
    tree: {
        height: 50,
        marginTop: -20,
        marginLeft: -20,
    },
    planted: {
        color: theme.brand.Trees,
        fontWeight: 'bold',
    },
    /** constant */

    positive: {
        color: theme.brand.JavaGreen,
        fontWeight: 'bold',
    },
    negative: {
        color: theme.palette.error.main,
        fontWeight: 'bold',
    },

    /** Countdown */

    countdown: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 12px',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
        },
    },
    cdTitle: {
        color: theme.palette.primary.main,
        fontSize: 20,
    },
    cdSubtitle: {
        fontSize: 10,
        marginTop: 4,
        textTransform: 'uppercase',
        color: theme.brand.BoldText,
    },

    shares: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        marginTop: 12,
        alignItems: 'flex-end',
        alignSelf: 'flex-end',

        [theme.breakpoints.up('sm')]: {
            marginLeft: 24,
            marginTop: 12,
            alignItems: 'flex-start',
            alignSelf: 'unset',
        },
    },
    sharesTotalSupply: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    shareRate: {
        color: theme.brand.purple,
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
}));

export default useStyles;
