import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';

// Modules

import { useDispatch, useSelector } from 'react-redux';
import { addAddress, setActive } from 'redux/actions/profiles';
import { current as currentStaker } from 'redux/actions/staker';
import { get as getAxion } from 'redux/actions/axion';
import { get as getEvents } from 'redux/actions/events';
import { get as getDaily } from 'redux/actions/daily';
import { history } from './redux/config';
import Web3 from 'web3';

// COmponents
import App from './containers/App';
import Snackbar from 'components/Snackbar';
import Notification from 'components/Notification';
import Analytics from './containers/Analytics';
import Overview from './containers/Overview';
import portfolio from './containers/Portfolio';
import Activity from 'containers/Activity';
import Navigation from 'components/Navigation';
import ScrollToTop from 'components/ScrollToTop';
import queryString from 'query-string';
import Countdown from 'containers/Countdown/Countdown';

const _Router = (props) => {
    const dispatch = useDispatch();
    const { profiles } = useSelector(({ profiles }) => ({ profiles }));
    const dailyTimer = React.useRef();
    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        if (parsed.address && Web3.utils.isAddress(parsed.address)) {
            const address = Web3.utils.toChecksumAddress(parsed.address);

            const includes = profiles.addresses.includes(address);
            if (!includes) dispatch(addAddress(address, true));
            else dispatch(setActive(address));
        }
    }, []);

    useEffect(() => {
        async function _getStaker() {
            await dispatch(currentStaker());
        }
        async function _getEvents() {
            await dispatch(getEvents());
        }

        async function _getAxion() {
            await dispatch(getAxion());
        }

        async function _getDaily() {
            await dispatch(getDaily());

            const nextTime = new Date(new Date(new Date()).setUTCHours(7, 35, 0, 0)) - new Date();
            dailyTimer.current = setTimeout(() => {
                _getDaily();
            }, 60 * 60 * 24 * 1000); // every day at 7:35 UTC
        }

        _getAxion(); // Initial call
        _getStaker(); // Initial call
        _getEvents(); // Initial Call
        _getDaily(); // Inital Call
        /** Update data every 3mins */
        const dataInterval = setInterval(() => {
            _getAxion();
        }, 60 * 1000 * 3); // 3mins

        /** Update events every 5 minutes */
        const eventInterval = setInterval(() => {
            _getEvents();
        }, 60 * 1000 * 5); // 5mins

        const stakerInterval = setInterval(() => {
            _getStaker();
        }, 60 * 1000 * 10); // 30 mins

        return () => {
            clearInterval(dataInterval);
            clearInterval(stakerInterval);
            clearInterval(eventInterval);
            clearTimeout(dailyTimer.current);
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <Router history={history}>
                <Snackbar />
                <Notification />
                <Switch>
                    <Navigation>
                        <ScrollToTop>
                            <Route exact path="/" component={App} />
                            <Route exact path="/analytics" component={Analytics} />
                            <Route exact path="/overview" component={Overview} />
                            <Route exact path="/portfolio" component={portfolio} />
                            <Route exact path="/activity" component={Activity} />
                        </ScrollToTop>
                    </Navigation>
                </Switch>
            </Router>
        </React.Fragment>
    );
};

export default _Router;
