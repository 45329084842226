import React, { useState } from 'react';

// Modules
import clsx from 'classnames';

// Utils
import { numberWithCommas } from 'utils/string';

// Material UI
import { Typography } from '@material-ui/core';
import EggDialog from 'components/Dialogs/EggDialog';

// Assets
import ClubTrophy from 'assets/images/club-trophy.svg';

import useStyles from './styles';

const ClubCard = ({ axn, amount, stakers = 0, ...props }) => {
    const classes = useStyles();
    const [egg, showEgg] = useState(false);

    function handleShowEgg() {
        showEgg(true);
    }

    return (
        <div className={classes.root} {...props}>
            <EggDialog open={egg} onClose={() => showEgg(false)} />
            <div>
                <div className={clsx(classes.row, classes.alignBottom)}>
                    <img src={ClubTrophy} alt="Club Trophy" className={classes.icon} />
                    <Typography variant="h4" className={classes.h4}>
                        Max Shares Club
                    </Typography>
                </div>
                <div className={classes.row}>
                    <div className={clsx(classes.cell, classes.cellLeft)}>
                        <Typography variant="h6">Total Amount AXN</Typography>
                        <Typography variant="h3">
                            {axn.shortenNoLetter(0, true)}
                            <span onClick={handleShowEgg}>B</span>
                        </Typography>
                        <Typography variant="h4" className={classes.lowerLabel}>
                            ${amount.shorten(2)}
                        </Typography>
                    </div>
                    <div className={clsx(classes.cell2)}>
                        <Typography variant="h6">Stakes</Typography>
                        <Typography variant="h3">{numberWithCommas(stakers, 0)}</Typography>
                        <Typography variant="h4" className={classes.lowerLabel}>
                            For 5555 Days
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClubCard;
