import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 12,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: 20,
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: theme.brand.Body,

        [theme.breakpoints.down('sm')]: {
            margin: 20,
            width: '100%',
            maxWidth: 296,
        },
    },
    content: {},
    alignBottom: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
    },
    purple: {
        color: theme.brand.BlueViolet,
        marginLeft: -6,
        marginBottom: 12,
    },
    cell2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    h4: {
        textTransform: 'none',
        fontSize: 24,
    },
    icon: {
        marginRight: 8,
    },
    row: {
        display: 'flex',
    },
    info: {
        marginLeft: 8,
    },
    cell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    cellLeft: {
        marginRight: 24,
    },
    lowerLabel: {
        color: theme.brand.GrayChateau,

        marginTop: 4,
    },
}));

export default useStyles;
