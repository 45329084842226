import { cloneDeep } from 'lodash';
import { getAmountOutAndPenalty } from './calculate-payout';

const getStakerStats = ({ stakes }) => {
    const session = {
        stakes: [],
        principal: 0,
        shares: 0,
        interest: 0,
        total: 0,
        bigPayDay: 0,
    };
    const sessions = {};
    const stakeStats = {
        accrued: 0,
        earned: 0,
        penalty: 0,
        avgStakeLength: 0,
        avgActiveStakeLength: 0,
        totalInterest: 0.0,
        activeInterest: 0.0,
        totalBPD: 0.0,
        activeBPD: 0.0,
    };

    stakes.forEach((stake, index) => {
        stakeStats.totalInterest += parseFloat(stake.interest || 0);
        stakeStats.totalBPD += parseFloat(stake.bigPayDay || 0);
        if (!stake.isWithdrawn) {
            stakeStats.activeInterest += parseFloat(stake.interest);
            stakeStats.activeBPD += parseFloat(stake.bigPayDay);
        }

        /** Historical data */

        let key = 'Active Stakes';

        if (stake.isWithdrawn) {
            key = 'Completed Stakes';
        } else if (stake.isMatured) {
            key = 'Matured Stakes';
        }
        if (!sessions[key]) sessions[key] = cloneDeep(session);

        sessions[key].stakes.push(stake);
        sessions[key].principal += parseFloat(stake.principal) || 0.0;
        sessions[key].shares += parseFloat(stake.shares) || 0.0;
        sessions[key].interest += parseFloat(stake.interest) || 0.0;
        sessions[key].bigPayDay += parseFloat(stake.bigPayDay) || 0.0;
        sessions[key].total += parseFloat(stake.interest) || 0.0;
        sessions[key].total += parseFloat(stake.principal) || 0.0;

        if (key === 'Active Stakes') {
            stakeStats.accrued += parseFloat(stake.interest) || 0.0;
            const length = sessions[key].stakes.length - 1;
            stakeStats.avgActiveStakeLength =
                (stakeStats.avgActiveStakeLength * length +
                    parseInt(stake.endSeconds) -
                    parseInt(stake.startSeconds)) /
                (length + 1);
        } else {
            stakeStats.earned += parseFloat(stake.interest) || 0.0;
        }

        if (stake.isWithdrawn) {
            stakeStats.penalty += stake.penalty;
        }

        stakeStats.avgStakeLength =
            (stakeStats.avgStakeLength * index +
                parseInt(stake.endSeconds) -
                parseInt(stake.startSeconds)) /
            (index + 1);
    });

    return { sessions, stakeStats };
};

export default getStakerStats;
