import React from 'react';
// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
// Components
import { useTheme } from '@material-ui/core';
import Chart from 'react-apexcharts';
import apexStyle from 'utils/apex-style';
// styles
import useStyles from './styles';

const Graph = (props) => {
    const { axion } = useSelector(({ axion }) => ({ axion }));
    const classes = useStyles(props);
    const theme = useTheme(props);

    const indexes = [96, 72, 48, 24, 10, 8, 6, 0];
    const labels = ['96h', '72h', '48h', '24h', '10h', '8h', '6h', 'NOW'];
    const values = axion.stats?.prices?.slice?.().reverse?.() ?? new Array(121);
    const series = indexes.map((index) => values?.[index]?.[1]?.toFixed?.(5) ?? 0.0);

    const min = Math.min(...series);
    const max = Math.max(...series);

    const _graph = apexStyle({
        theme,
        series,
        max,
        min,
        labels,
        xFormatter: (index) => {
            if (index === labels.length) return labels[index - 1];
            return `${labels[index - 1]} ago`;
        },
    });

    const axnPercentChange = {
        positive: axion.stats?.percentChange24h >= 0,
        value: Math.abs(axion.stats?.percentChange24h.toFixed(3)),
    };

    return (
        <div className={classes.root}>
            <div className={classes.marketPrice}>
                <div className={classes.rateSwitch}>
                    <span className={clsx(classes.switch, classes.axion)}>AXN</span>
                    <span className={classes.switchInactive}>USD</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.price}>
                        ${axion.stats?.currentPriceUsd.toFixed(6)}
                    </span>
                    <span
                        className={clsx(classes.percent, {
                            [classes.positive]: axnPercentChange.positive,
                            [classes.negative]: !axnPercentChange.positive,
                        })}
                    >
                        {axnPercentChange.positive ? '+' : ''}
                        {axnPercentChange.value}%
                    </span>
                </div>
            </div>
            <div className={classes.graph}>
                <Chart options={_graph.options} series={_graph.series} type="area" height={400} />
            </div>
        </div>
    );
};

export default Graph;
