import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '12px 0px',
        borderBottom: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    toSell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.brand.BoldText,
        fontWeight: 500,
        fontSize: 18,
    },
    soldOut: {
        color: theme.brand.error,
        fontWeight: 'bold',
        fontSize: 18,
    },
    caption: {
        minHeight: 28,
        fontSize: 12,
        textAlign: 'center',
        maxWidth: '80%',
        textTransform: 'uppercase',
        marginTop: 6,
        fontWeight: 'bold',
        color: theme.brand.GrayChateau,
    },
    oversold: {
        color: theme.brand.error,
    },
    icon: {
        marginLeft: 6,
        cursor: 'pointer',
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useStyles;
