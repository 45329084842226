import { makeStyles } from '@material-ui/core/styles';
import { NavigationBarHeight } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: theme.mixins?.bar?.height ?? NavigationBarHeight,
        width: '100%',
        backgroundColor: theme.brand.NavigationBackground,
        position: 'fixed',
        top: 0,
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        boxShadow: theme._shadows.nav,
        padding: '0px 10px',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: 68,
        fill: theme.palette.primary.main,
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    modeIcon: {
        fontSize: 20,
        color: theme.brand.ThemeMode,
    },

    /** pledge */
    icon: {
        height: 14,
        marginRight: theme.spacing(1),
    },
    pledgeListItem: {
        marginTop: theme.spacing(2),
    },
    pledge: {
        borderRadius: 12,
        padding: 5,
        border: `1px solid ${theme.brand.border}`,
        margin: 'auto',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    pledgeTotal: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.brand.Trees,
        textAlign: 'center',
    },
    usdValue: {
        fontSize: 12,
        fontWeight: 500,
        color: theme.brand.BoldText,
    },
    mb4: {
        marginBottom: 4,
    },
    between: {
        justifyContent: 'space-between',
        width: '100%',
    },
}));

export default useStyles;
