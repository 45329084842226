import axios from 'axios';

const getMarketPrices = async () => {
    const stats = await axios(
        'https://api.coingecko.com/api/v3/coins/axion/market_chart?vs_currency=usd&days=30'
    );

    return stats.data;
};

export default getMarketPrices;
