import React from 'react';
// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { numberWithCommas } from 'utils/string';
// Components
import Tag from 'components/Tag';
import { ImportExport, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Typography, Hidden, LinearProgress } from '@material-ui/core';
// styles
import useStyles from './styles';
import { iOS } from 'utils/detect-device';

const Statistics = () => {
    const { axion } = useSelector(({ axion }) => ({ axion }));
    const classes = useStyles();

    let axnPercentChange = {
        Week: {
            positive: axion.stats?.percentChange7d >= 0,
            value: Math.abs(axion.stats?.percentChange7d.toFixed(3)),
        },
        Month: {
            positive: axion.stats?.percentChange30d >= 0,
            value: Math.abs(axion.stats?.percentChange30d.toFixed(3)),
        },
    };

    return (
        <div className={classes.root}>
            <Typography variant="h5">AXION PERFORMANCE (LAST 24HRS)</Typography>
            <div className={classes.market}>
                <div className={classes.marketItem}>
                    <div className={classes.row}>
                        <span className={classes.token}>AXN</span>
                        <ImportExport className={classes.switch} />
                        <span className={clsx(classes.exchange, classes.usd)}>USD</span>
                    </div>
                    <p
                        className={clsx(classes.percent, {
                            [classes.positive]: Math.sign(axion.stats?.percentChange24h) !== -1,
                            [classes.negative]: Math.sign(axion.stats?.percentChange24h) === -1,
                        })}
                    >
                        {axion.stats?.percentChange24h.toFixed(2)}%
                    </p>
                    <LinearProgress
                        variant="determinate"
                        value={Math.abs(axion.stats?.percentChange24h)}
                        classes={{
                            root: classes.progress,
                            bar1Determinate: clsx({
                                [classes.positiveBg]:
                                    Math.sign(axion.stats?.percentChange24h) !== -1,
                                [classes.negativeBg]:
                                    Math.sign(axion.stats?.percentChange24h) === -1,
                            }),
                        }}
                    />
                </div>
                <Hidden smDown>
                    {' '}
                    <div className={classes.vertical} />{' '}
                </Hidden>

                <Hidden smDown>
                    <div className={classes.marketItem}>
                        <div className={classes.row}>
                            <span className={classes.token}>AXN</span>
                            <ImportExport className={classes.switch} />
                            <span className={clsx(classes.exchange, classes.btc)}>BTC</span>
                        </div>
                        <p
                            className={clsx(classes.percent, {
                                [classes.positive]:
                                    Math.sign(axion.stats?.percentChange24hVsBtc) !== -1,
                                [classes.negative]:
                                    Math.sign(axion.stats?.percentChange24hVsBtc) === -1,
                            })}
                        >
                            {axion.stats?.percentChange24hVsBtc?.toFixed(2)}%
                        </p>
                        <LinearProgress
                            variant="determinate"
                            value={Math.abs(axion.stats?.percentChange24hVsBtc)}
                            classes={{
                                root: classes.progress,
                                bar1Determinate: clsx({
                                    [classes.positiveBg]:
                                        Math.sign(axion.stats?.percentChange24hVsBtc) !== -1,
                                    [classes.negativeBg]:
                                        Math.sign(axion.stats?.percentChange24hVsBtc) === -1,
                                }),
                            }}
                        />
                    </div>
                </Hidden>
                <Hidden smDown>
                    {' '}
                    <div className={classes.vertical} />{' '}
                </Hidden>
                <div className={classes.marketItem}>
                    <div className={classes.row}>
                        <span className={classes.token}>AXN</span>
                        <ImportExport className={classes.switch} />
                        <span className={clsx(classes.exchange, classes.eth)}>ETH</span>
                    </div>
                    <p
                        className={clsx(classes.percent, {
                            [classes.positive]:
                                Math.sign(axion.stats?.percentChange24hVsEth) !== -1,
                            [classes.negative]:
                                Math.sign(axion.stats?.percentChange24hVsEth) === -1,
                        })}
                    >
                        {axion.stats?.percentChange24hVsEth?.toFixed(2)}%
                    </p>
                    <LinearProgress
                        variant="determinate"
                        value={Math.abs(axion.stats?.percentChange24hVsEth)}
                        classes={{
                            root: classes.progress,
                            bar1Determinate: clsx({
                                [classes.positiveBg]:
                                    Math.sign(axion.stats?.percentChange24hVsEth) !== -1,
                                [classes.negativeBg]:
                                    Math.sign(axion.stats?.percentChange24hVsEth) === -1,
                            }),
                        }}
                    />
                </div>
                <Hidden smDown>
                    {' '}
                    <div className={classes.vertical} />{' '}
                </Hidden>

                <Hidden smDown>
                    <div className={classes.marketItem}>
                        <div className={classes.row}>
                            <span className={classes.token}>ETH</span>
                            <ImportExport className={classes.switch} />
                            <span className={clsx(classes.exchange, classes.usd)}>USD</span>
                        </div>
                        <p
                            className={clsx(classes.percent, {
                                [classes.positive]:
                                    Math.sign(axion.stats?.ethPercentChange24h) !== -1,
                                [classes.negative]:
                                    Math.sign(axion.stats?.ethPercentChange24h) === -1,
                            })}
                        >
                            {axion.stats?.ethPercentChange24h?.toFixed(2)}%
                        </p>
                        <LinearProgress
                            variant="determinate"
                            value={Math.abs(axion.stats?.ethPercentChange24h)}
                            classes={{
                                root: classes.progress,
                                bar1Determinate: clsx({
                                    [classes.positiveBg]:
                                        Math.sign(axion.stats?.ethPercentChange24h) !== -1,
                                    [classes.negativeBg]:
                                        Math.sign(axion.stats?.ethPercentChange24h) === -1,
                                }),
                            }}
                        />
                    </div>
                </Hidden>
            </div>
            <div className={classes.ranking}>
                {Object.keys(axnPercentChange).map((key) => {
                    const change = axnPercentChange[key];

                    return (
                        <div className={classes.rank} key={key}>
                            <span className={classes.rankTitle}>{key}</span>
                            <div className={classes.row}>
                                {!change.positive ? (
                                    <ArrowDropDown className={classes.negative} />
                                ) : (
                                    <ArrowDropUp className={classes.positive} />
                                )}
                                <span
                                    className={clsx(classes.rankValue, {
                                        [classes.positive]: change.positive,
                                        [classes.negative]: !change.positive,
                                    })}
                                >
                                    {change.positive ? '+' : ''}
                                    {change.value}%
                                </span>
                            </div>
                        </div>
                    );
                })}
                <div className={classes.rank}>
                    <span className={classes.rankTitle}>Rank</span>
                    <span className={clsx(classes.rankValue)}># {axion.stats?.marketCapRank}</span>
                </div>
                <div className={classes.rank}>
                    <span className={classes.rankTitle}>Volume</span>
                    <span className={clsx(classes.rankValue)}>
                        ${numberWithCommas(axion.stats?.volume24h)}
                    </span>
                </div>
                <div className={classes.rank}>
                    <span className={classes.rankTitle}>ATH</span>
                    <span className={clsx(classes.rankValue)}>
                        ${axion.stats?.ath?.toFixed?.(5)}
                    </span>
                    <span className={clsx(classes.rankSubtitle)}>
                        {moment(axion.stats?.athDate).format('L')}
                    </span>
                </div>
                <div className={classes.rank}>
                    <span className={classes.rankTitle}>Mkt Cap</span>
                    <span className={clsx(classes.rankValue)}>
                        $
                        {numberWithCommas(
                            axion.stats?.totalSupply * axion.stats?.currentPriceUsd,
                            0
                        )}
                    </span>
                </div>
                <div className={classes.rank}>
                    <span className={classes.rankTitle}>Diluted Mkt Cap</span>
                    <span className={clsx(classes.rankValue)}>
                        $
                        {numberWithCommas(
                            (axion.stats?.totalSupply + axion.stats?.totalStaked) *
                                axion.stats?.currentPriceUsd,
                            0
                        )}
                    </span>
                </div>

                <div className={clsx(classes.rank, classes.detail)}>
                    {!iOS() && (
                        <Tag
                            classes={{ root: classes.tag }}
                            noImage
                            text="View in Detail"
                            href="https://www.dextools.io/app/polygon/pair-explorer/0xf716a43d66c40ee90df429af2459e580c1afc09b"
                            target="_blank"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Statistics;
