import React from 'react';
// Modules
import { connect } from 'react-redux';
import {
    pop as popNotification,
    shift as shiftNotification,
    reset as resetNotifications,
} from 'redux/actions/notifications';
import { withRouter } from 'react-router-dom';
import { SECONDS_IN_DAY, WBTC_1E10 } from 'utils/variables';
import BN from 'bn.js';
import web3 from 'web3';
// Components
import Card from 'components/Card';
import { Typography, Button, LinearProgress, withStyles } from '@material-ui/core';
import { ReactComponent as Crown } from 'assets/images/crown.svg';
import { ReactComponent as Fire } from 'assets/images/fire.svg';
import Bitcoin from 'assets/images/Bitcoin-Logo.png';
import shrimp from 'assets/images/shrimp.png';
import crab from 'assets/images/crab.png';
import fish from 'assets/images/fish.png';
import dolphin from 'assets/images/dolphin.png';
import kraken from 'assets/images/kraken.png';
import shark from 'assets/images/shark.png';
import whale from 'assets/images/whale.png';
// styles
import styles from './styles';
import { numberWithCommas } from 'utils/string';

const leagues = {
    shrimp,
    crab,
    fish,
    dolphin,
    kraken,
    shark,
    whale,
};
const COUNTDOWN_SECONDS = 15;

class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeLeft: COUNTDOWN_SECONDS,
        };
    }

    componentDidMount() {
        if (this.props.notifications.length > 0) {
            this.setTimer();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.location.pathname.includes('/activity') &&
            this.props.notifications.length > 0
        ) {
            this.handleClose();
        } else if (prevProps.notifications.length !== this.props.notifications.length) {
            if (!this.timer && this.props.notifications.length > 0) {
                this.setState(
                    {
                        timeLeft: COUNTDOWN_SECONDS,
                    },
                    () => {
                        this.setTimer();
                    }
                );
            }
        } else if (!this.timer && this.props.notifications.length > 0) {
            this.setTimer();
        }
    }

    componentWillUnmount() {
        this.timer = null;
        clearInterval(this.timer);
    }

    setTimer() {
        if (this.timer) clearInterval(this.timer);
        const { shiftNotification } = this.props;

        this.timer = setInterval(() => {
            /** Clear interval, pop notification, and empty timer */
            if (this.state.timeLeft <= 0) {
                clearInterval(this.timer);
                this.timer = null;
                return shiftNotification();
            }

            /** Countdown */
            this.setState({
                timeLeft: this.state.timeLeft - 0.2,
            });
        }, 200);
    }

    handleClose() {
        clearInterval(this.timer);
        this.timer = null;
        this.props.resetNotifications();
    }

    handleNext() {
        this.setState({
            timeLeft: 0,
        });
    }

    handleGoToHighlights() {
        this.props.history.push('/activity');
    }

    renderBody(notification) {
        if (notification.type === 'StakeCreated') {
            const stakeDays =
                (parseInt(notification.end) - parseInt(notification.start)) / SECONDS_IN_DAY;
            return `A ${notification.ecoBefore} has staked ${notification.amount.shorten(
                2
            )} for ${stakeDays.toFixed(0)} days!`;
        } else if (notification.type === 'StakeDeleted') {
            const stakeDays =
                (parseInt(notification.end) - parseInt(notification.start)) / SECONDS_IN_DAY;
            return `A ${
                notification.ecoBefore
            } has unstaked ${notification.payout.shorten()} after ${stakeDays.toFixed(0)} days.`;
        } else if (notification.type === 'StakeUpgraded') {
            return `A ${
                notification.ecoBefore
            } has upgraded their ${notification.newAmount.shorten()} stake for max shares`;
        } else if (notification.type === 'WithdrawLiquidDiv') {
            const interest = parseFloat(
                web3.utils.fromWei(new BN(notification.interest ?? '0').mul(WBTC_1E10))
            );

            return `A ${notification.ecoBefore} has withdrawn ${interest} BTC!`;
        } else if (
            notification.type === 'AcceleratorEth' ||
            notification.type === 'AcceleratorToken'
        ) {
            const burned = parseFloat(web3.utils.fromWei(notification.axionBought ?? '0')); // "5743034.602988242227197480"
            const staker = parseFloat(
                web3.utils.fromWei(new BN(notification.tokenBought ?? '0').mul(WBTC_1E10))
            );

            return `${numberWithCommas(burned, 2)} AXN burned and ${staker} BTC generated!`;
        }
    }

    render() {
        const { classes, notifications } = this.props;
        const { timeLeft } = this.state;

        const notification = notifications[0];

        if (!notification) return <div style={{ display: 'none' }} />;
        if (notification.type === 'StakeSplit') return <div style={{ display: 'none' }} />;
        if (notification.type === 'StakeTransferred') return <div style={{ display: 'none' }} />;
        if (notification.type === 'Redeemed') return <div style={{ display: 'none' }} />;
        if (notification.type === 'WithdrawLiquidDiv') {
            if (notification.tokenAddress === '0x839F1a22A59eAAf26c85958712aB32F80FEA23d9')
                return <div style={{ display: 'none' }} />;
        }

        return (
            <Card classes={{ root: classes.root }}>
                <div className={classes.cardTitle}>
                    {notification.type === 'StakeUpgraded' && <Fire className={classes.crown} />}
                    {notification.type === 'StakeCreated' &&
                        parseInt(
                            (parseInt(notification.end) - parseInt(notification.start)) /
                                SECONDS_IN_DAY
                        ) === 5555 && <Crown className={classes.crown} />}
                    {(notification.type === 'WithdrawLiquidDiv' ||
                        notification.type === 'AcceleratorEth' ||
                        notification.type === 'AcceleratorToken') && (
                        <img src={Bitcoin} alt="btc" className={classes.btc} />
                    )}
                    <Typography variant="h5">
                        A new{' '}
                        {notification.type === 'WithdrawLiquidDiv'
                            ? 'VCA Withdraw'
                            : notification.type === 'AcceleratorEth'
                            ? 'Accelerator Purchase'
                            : notification.type === 'AcceleratorToken'
                            ? 'Accelerator Purchase'
                            : notification.type}
                    </Typography>
                    <img
                        src={leagues[notification.ecoBefore ?? 'shrimp']}
                        className={classes.league}
                        alt="league"
                    />
                </div>
                <div className={classes.cardBody}>
                    <p className={classes.body}>{this.renderBody(notification)}</p>
                    {notifications.length > 1 ? (
                        <p className={classes.note}>
                            There are {notifications.length - 1} other new events
                        </p>
                    ) : (
                        <p className={classes.note}>There are no more notifications</p>
                    )}
                </div>

                <div className={classes.cardFooter}>
                    {notifications.length > 1 && (
                        <Button color="primary" onClick={this.handleNext.bind(this)}>
                            Next
                        </Button>
                    )}
                    <Button color="primary" onClick={this.handleGoToHighlights.bind(this)}>
                        Go to Activity
                    </Button>
                    <Button color="primary" onClick={this.handleClose.bind(this)}>
                        Close
                    </Button>
                </div>
                <LinearProgress
                    variant="determinate"
                    value={(timeLeft / 15) * 100}
                    className={classes.progress}
                />
            </Card>
        );
    }
}

const mapStateToProps = ({ notifications }) => ({ notifications });

export default connect(mapStateToProps, {
    resetNotifications,
    shiftNotification,
    popNotification,
})(withStyles(styles)(withRouter(Notification)));
