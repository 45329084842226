import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.brand.Zircon,
        padding: '10px 15px',
        cursor: 'pointer',
        borderRadius: 12,
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 14,
    },
    img: {
        height: 20,
        width: 20,
        marginRight: theme.spacing(1),
    },
}));

export default useStyles;
