import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: theme.spacing(2),
    },
    card: {
        flexBasis: '49%',
        borderRadius: 20,
        padding: '0px 20px',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            marginBottom: theme.spacing(2),
        },
        height: '100%',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 12,
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
    },
    cardTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 0px',
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
    },
    cardIcon: {
        height: 20,
        width: 20,
        marginRight: theme.spacing(1),
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'row',
    },

    stats: {
        flexGrow: 1,
        paddingTop: 20,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    stat: {
        width: '100%',
        marginBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        textAlign: 'left',
    },
    statTitle: {
        fontSize: 10,
        fontWeight: 'bold',
        color: theme.brand.BoldText,
        marginBottom: theme.spacing(1),
    },
    statNumber: {
        fontSize: 15,
        fontWeight: 'bold',
        color: theme.brand.BoldText,
    },
    chartNumber: {
        fontSize: '0.4em',
        '-webkit-transform': 'translateY(-0.8em)',
    },
    bar: {
        height: 5,
        width: 30,
        marginBottom: theme.spacing(0.5),
    },
    grey: {
        backgroundColor: theme.brand.GrayChateau,
    },
    blue: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default useStyles;
