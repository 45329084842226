import axios from 'axios';
import { base, routes } from 'api/requests';
import { SET_EVENTS } from 'redux/reducers/events';
import { add as addNotifications } from './notifications';
import { convertEvents } from 'utils/convert-from-bn';

export const get = () => async (dispatch, getState) => {
    const { events } = getState();

    const perPage = 200;
    const response = await axios.get(base + routes.getEvents + `?perPage=${perPage}`);

    response.data.Items = convertEvents(response.data.Items);

    /** New events */
    if (events.Items.length !== 0) {
        const lastEvent = events.Items[0];

        const notifications = [];
        for (let i = 0; i < response.data.Items.length; i++) {
            if (i === 12) break;
            const event = response.data.Items[i];
            if (event.txID === lastEvent.txID) break;
            notifications.push(event);
        }
        if (notifications.length > 0) dispatch(addNotifications(notifications));
    } else {
        const notifications = [];
        for (let i = 0; i < response.data.Items.length; i++) {
            if (i === 12) break;
            const event = response.data.Items[i];
            notifications.push(event);
        }
        if (notifications.length > 0) dispatch(addNotifications(notifications));
    }

    // Testing
    // const notifications = [];
    // for (let i = 0; i < 5; i++) {
    //     if (i === 5) break;
    //     const event = response.data.Items[i];
    //     notifications.push(event);
    // }
    // console.log(notifications);
    // dispatch(addNotifications(notifications));

    dispatch({
        type: SET_EVENTS,
        payload: response.data,
    });

    return response;
};
