export const SET_BPD = 'SET_BPD';
export const BPD_ERROR = 'BPD_ERROR';
export const INIT = {
    yearly: [],
};

export default function (state = INIT, { type, payload }) {
    switch (type) {
        case SET_BPD:
            return payload;
        case BPD_ERROR:
        default:
            return state;
    }
}
