import React from 'react';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import LeagueDistribution from './Widgets/LeagueDistribution';
import OverviewWidget from './Widgets/Overview';
// import PayoutChart from './Widgets/PayoutChart';

// Styles
import useStyles from './styles';

const Overview = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
                <OverviewWidget />
            </Grid>
            {/* <Grid item xs={12}>
                <Auction />
            </Grid> */}
            {/* <Grid item xs={12}>
                <PayoutChart />
            </Grid> */}
            <Grid item xs={12}>
                <LeagueDistribution />
            </Grid>
        </Grid>
    );
};

export default Overview;
