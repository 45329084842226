import React, { useRef } from 'react';

import classNames from 'classnames';
import { sum, uniqueId } from 'lodash';
import { numberWithCommas } from 'utils/string';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import styles from './styles';
import colors from '../colors';

const SharesBarChartDesktop = ({ classes, values }) => {
    const id = useRef(uniqueId());

    const total = sum(values.map(({ axn }) => axn));

    return (
        <div className={classes.root}>
            <div className={classes.bar}>
                {values.map(({ axn, shares }, i) => {
                    return (
                        <div
                            key={`${id.current}-segment-${i}`}
                            className={classNames(
                                classes.segment,
                                { [`${classes.up}`]: i % 2 !== 0 },
                                { [`${classes.down}`]: i % 2 === 0 }
                            )}
                            style={{
                                backgroundColor: colors[i],
                                flexBasis: `${(axn / total) * 100}%`,
                            }}
                        >
                            <div
                                className={classNames(classes.line, {
                                    [`${classes.lineUp}`]: i % 2 !== 0,
                                    [`${classes.lineDown}`]: i % 2 === 0,
                                })}
                                style={{ backgroundColor: colors[i] }}
                            ></div>
                            <div
                                className={classNames(classes.dotContainer, {
                                    [`${classes.dotTop}`]: i % 2 !== 0,
                                    [`${classes.dotBottom}`]: i % 2 === 0,
                                })}
                            >
                                <div
                                    className={classes.dot}
                                    style={{ backgroundColor: colors[i] }}
                                ></div>
                                <Typography
                                    variant="overline"
                                    className={classes.bpd}
                                    style={{ color: colors[i] }}
                                >
                                    BPD {i + 1}
                                </Typography>
                                <div
                                    className={classNames(classes.info, {
                                        [`${classes.infoTop}`]: i % 2 !== 0,
                                        [`${classes.infoBottom}`]: i % 2 === 0,
                                    })}
                                >
                                    <div className={classes.infoLine}>
                                        <Typography className={classes.axion}>
                                            {numberWithCommas(axn, 0)}
                                        </Typography>
                                        <Typography
                                            className={classNames(
                                                classes.smallLabel,
                                                classes.axionLabel
                                            )}
                                        >
                                            axn
                                        </Typography>
                                    </div>
                                    <div className={classes.infoLine}>
                                        <Typography className={classes.smallLabel}>
                                            Date {shares}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            {i === values.length - 1 && (
                                <div className={classes.bigPayDay}>
                                    <Typography className={classes.smallLabel}>
                                        Total Bigpaydays:
                                    </Typography>
                                    <Typography className={classes.bigPayDayAmt}>
                                        {numberWithCommas(total, 0)}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default withStyles(styles)(SharesBarChartDesktop);
