import React from 'react';

// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
import web3 from 'web3';
import BN from 'bn.js';
import { WBTC_ADDRESS, WBTC_1E10, VCA_STAKER_PCT } from 'utils/variables';
// Material UI
import { Typography, Tooltip } from '@material-ui/core';
// Assets
import BTCBox from 'assets/images/Bitcoin-Logo.png';
import { numberWithCommas } from 'utils/string';

import useStyles from './styles';

const VCCards = ({ axn, amount, stakers = 0, ...props }) => {
    const classes = useStyles();
    const { axion } = useSelector(({ axion }) => ({ axion }));
    const acceleratorBTC = new BN(axion.accelerator?.acceleratorAllTimes?.tokenBought ?? '0').mul(
        WBTC_1E10
    );
    const ventureAuctionBTC = new BN(
        axion.accelerator?.acceleratorAllTimes?.auction?.coins?.[WBTC_ADDRESS] ?? '0'
    ).mul(WBTC_1E10);

    return (
        <div className={classes.root} {...props}>
            <div className={clsx(classes.row, classes.alignBottom)}>
                <Typography variant="h4" className={classes.h4}>
                    VC Bitcoin
                    <br />
                    <span className={classes.span}>Since March 12th 2021</span>
                </Typography>
            </div>
            <div className={clsx(classes.middle)}>
                <div className={classes.column}>
                    <Typography variant="h5" className={classes.span}>
                        Stakers Paid
                    </Typography>
                    <Tooltip
                        title={`$${numberWithCommas(
                            parseFloat(web3.utils.fromWei(acceleratorBTC.add(ventureAuctionBTC))) *
                                axion.stats?.btcPrice *
                                VCA_STAKER_PCT,
                            2
                        )} USD`}
                    >
                        <Typography variant="h4" className={classes.span}>
                            {/* 2 decimals */}
                            {/* TODO: VCA */}
                            {numberWithCommas(
                                parseFloat(
                                    web3.utils.fromWei(acceleratorBTC.add(ventureAuctionBTC)) *
                                        VCA_STAKER_PCT
                                ),
                                2
                            )}{' '}
                            wBTC
                        </Typography>
                    </Tooltip>
                </div>
                <img src={BTCBox} alt="box" className={classes.box} />
                <div className={classes.column}>
                    <Typography variant="h5" className={classes.span}>
                        Cashed Out
                    </Typography>
                    <Tooltip
                        title={`$${numberWithCommas(
                            parseFloat(
                                web3.utils.fromWei(
                                    new BN(
                                        axion.ventureWithdraws?.totalWithdrawn?.[WBTC_ADDRESS] ??
                                            '0'
                                    ).mul(WBTC_1E10)
                                )
                            ) * axion.stats?.btcPrice,
                            2
                        )} USD`}
                    >
                        <Typography variant="h4" className={classes.span}>
                            {/* 2 decimals */}
                            {numberWithCommas(
                                web3.utils.fromWei(
                                    new BN(
                                        axion.ventureWithdraws?.totalWithdrawn?.[WBTC_ADDRESS] ??
                                            '0'
                                    ).mul(WBTC_1E10)
                                ),
                                4
                            )}{' '}
                            wBTC
                        </Typography>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default VCCards;
