import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    contentItemGrid: {
        padding: '16px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 25%',
        },
    },
    borderItem: {
        borderRight: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            borderRight: `none`,
            // borderBottom: `1px solid ${theme.brand.border}`,
        },
    },
    contentItem: {
        minHeight: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    title: {
        marginBottom: 8,
    },
    subtitle: {
        fontSize: 20,
        fontWeight: '500',
        color: theme.brand.BoldText,
    },
    ecoImage: {
        height: 48,
        width: 48,
        marginRight: 12,
    },
    /** common */
    row: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 8,
    },
    bold: {
        color: theme.brand.BoldText,
        fontWeight: 'bold',
    },
    shares: {
        padding: 20,
        borderTop: `1px solid ${theme.brand.border}`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: '20px 0px',
        },
    },
    sharesColumn: {
        flex: 1,
        borderRight: `1px solid ${theme.brand.border}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            marginBottom: theme.spacing(2),
        },
    },
    noBorder: {
        borderRight: `none`,
    },
    sharesStat: {
        marginBottom: theme.spacing(2),
    },

    statIcon: {
        fontSize: 36,
        height: 36,
        width: 36,
    },
    bgWhite: {
        backgroundColor: 'white',
        borderRadius: '50%',
    },
}));

export default useStyles;
