import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    league: {
        marginTop: theme.spacing(1.5),
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    leagueImage: {
        height: 40,
        width: 40,
    },
    name: {
        marginLeft: theme.spacing(1),
        color: theme.brand.BoldText,
        fontWeight: 'bold',
    },
    info: {
        marginLeft: theme.spacing(1),
        color: theme.brand.BoldText,
    },
}));

export default useStyles;
