import React from 'react';
import { useTheme } from '@material-ui/core';

import useStyles from './styles';

const BackgroundWrap = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();

    return (
        <div className={classes.root}>
            <svg
                width="1440"
                height="1233"
                viewBox="0 0 1440 1233"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classes.svgbg}
            >
                <circle
                    opacity="0.3"
                    cx="768.5"
                    cy="429.5"
                    r="628.5"
                    stroke={theme.brand.Swirl}
                    strokeWidth="350"
                ></circle>
                <defs>
                    <linearGradient
                        id="axion-bg-light_linear"
                        x1="1676"
                        y1="201"
                        x2="140"
                        y2="1102"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#889EED" stopOpacity="0.38"></stop>
                        <stop offset="1" stopColor="white" stopOpacity="0.25"></stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default BackgroundWrap;
