import { cloneDeep } from 'lodash';
import { SET_THEME } from 'redux/reducers/theme';
import theme, { darkTheme } from 'theme';

export const setLightMode = () => (dispatch) => {
    const payload = cloneDeep(theme);

    dispatch({
        type: SET_THEME,
        payload,
    });
};

export const setDarkMode = () => (dispatch) => {
    const payload = cloneDeep(darkTheme);

    dispatch({
        type: SET_THEME,
        payload,
    });
};
