import React from 'react';

import { Button } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import Crab from 'assets/images/crab.png';

import useStyles from './styles';

const Tag = ({ image = Crab, noImage, text, ...props }) => {
    const classes = useStyles(props);

    return (
        <Button className={classes.root} {...props}>
            {!noImage && <img src={image} className={classes.img} />}
            <span className={classes.text}>{text}</span>
            <ChevronRight className={classes.icon} />
        </Button>
    );
};

export default Tag;
