import React, { useRef } from 'react';

import classNames from 'classnames';
import { sum, uniqueId } from 'lodash';
import { numberWithCommas } from 'utils/string';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import styles from './styles';
import colors from '../colors';

const SharesBarChartMobile = ({ classes, values }) => {
    const id = useRef(uniqueId());

    const total = sum(values.map(({ axn }) => axn));

    return (
        <div className={classes.root}>
            <div className={classes.bigPayDay}>
                <Typography className={classes.smallLabel}>Total Bigpaydays:</Typography>
                <Typography className={classes.bigPayDayAmt}>
                    {numberWithCommas(total, 0)}
                </Typography>
            </div>
            <div className={classes.bars}>
                {values.map(({ axn, shares }, i) => {
                    return (
                        <div key={`${id.current}-segment-${i}`} className={classes.bar}>
                            <Typography className={classes.axion}>
                                {numberWithCommas(axn, 0)}
                            </Typography>

                            <Typography variant="h5" className={classes.axn}>
                                axn
                            </Typography>
                            <div
                                className={classNames(classes.segment, classes.down)}
                                style={{
                                    backgroundColor: colors[i],
                                    flexBasis: `25%`,
                                }}
                            >
                                <Typography variant="overline" className={classes.bpd}>
                                    BPD {i + 1}
                                </Typography>
                            </div>
                            <Typography className={classes.smallLabel}>{shares}</Typography>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default withStyles(styles)(SharesBarChartMobile);
