import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: theme.spacing(2),
    },
    card: {
        borderRadius: 20,
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
    },
    graphCard: {
        overflow: 'hidden',
    },
    section: {
        flex: 1,
    },
    borderedSection: {
        borderRight: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            borderRight: 'none',
            borderBottom: `1px solid ${theme.brand.border}`,
        },
    },
}));

export default useStyles;
