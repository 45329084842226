import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'visible',
        zIndex: 11,
        '&:focus': {
            border: 'none',
        },
    }, // This is here so we can style by classes={{ root: blah }}
    svgroot: {
        overflow: 'visible',
        '&:focus': {
            border: 'none',
        },
    },
    chartText: {
        font: `12px "Roboto", Montserrat, sans-serif`,
        fill: '#000',
        '-moz-transform': 'translateY(0.5em)',
        '-ms-transform': 'translateY(0.5em)',
        '-webkit-transform': 'translateY(0.5em)',
        transform: 'translateY(0.5em)',
    },

    chartNumber: {
        fontSize: '0.6em',
        lineHeight: 1,
        textAnchor: 'middle',
        '-moz-transform': 'translateY(-0.5em)',
        '-ms-transform': 'translateY(-0.5em)',
        '-webkit-transform': 'translateY(-0.5em)',
        transform: 'translateY(-0.5em)',
    },
}));

export default useStyles;
