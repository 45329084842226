import { SET_BPD, INIT } from 'redux/reducers/bpd';
import { cloneDeep } from 'lodash';

export const set = (payload = cloneDeep(INIT)) => async (dispatch) => {
    dispatch({
        type: SET_BPD,
        payload,
    });

    return payload;
};
