import React from 'react';
import { useHistory } from 'react-router-dom';
// Modules
import clsx from 'classnames';
import { useSelector } from 'react-redux';
import { numberWithCommas } from 'utils/string';
import { getEcoShares } from 'utils/get-eco';
import web3 from 'web3';
import { WBTC_ADDRESS, WBTC_1E10 } from 'utils/variables';
import BN from 'bn.js';
// Components
import { Typography, CircularProgress, Tooltip } from '@material-ui/core';
import Tag from 'components/Tag';
import Stat from 'components/Stat';
import Card from 'components/Card';
import { PersonOutlineSharp } from '@material-ui/icons';
import Donut from 'components/Donut';
import NavProfiles from 'components/NavProfiles/NavProfiles';
import WrappedBTC from 'assets/images/Bitcoin-Logo.png';
// Styles
import useStyles from './styles';
import { colors } from 'global-styles';

const ProfileWidget = () => {
    const { profiles } = useSelector(({ profiles }) => ({ profiles }));
    const classes = useStyles();
    const history = useHistory();
    const { staker, axion, globals } = useSelector(({ staker, axion, globals }) => ({
        staker,
        axion,
        globals,
    }));

    const data = {};
    if (staker) {
        data.totalStaked = staker.stats.totalStaked.toFixed(2);
        data.totalStakedUsd = data.totalStaked * axion.stats?.currentPriceUsd;
        data.totalShares = staker.stats.totalShares.toFixed(2);
        data.liquid = staker.stats.balance.toFixed(2);
        data.liquidUsd = data.liquid * axion.stats?.currentPriceUsd;
        data.totalSupply = staker.stats.balance + staker.stats.totalStaked;
        data.totalSupplyUsd = data.totalSupply * axion.stats?.currentPriceUsd;
        data.interest = staker.stats.totalInterest;
        data.shares = staker.stats.totalShares.toFixed(2);
        data.percentOwned = (data.totalShares / axion.stats?.sharesTotalSupply) * 100;
        data.stakes = staker.stakes.length;
        data.activeStakes = staker.sessions['Active Stakes']?.stakes?.length;
        data.stakedPercent = data.totalStaked / data.totalSupply;
        data.eco = getEcoShares(data.percentOwned);

        const availableToken = parseFloat(
            web3.utils.fromWei(
                new BN(staker.ventureDivs?.[0]?.interestEarnedToken ?? '0').mul(WBTC_1E10)
            )
        );
        const withdrawn = parseFloat(
            web3.utils.fromWei(
                new BN(staker.staker?.ventureWithdraws?.[WBTC_ADDRESS] ?? '0').mul(WBTC_1E10)
            )
        );
        data.allTime = availableToken + withdrawn;
        data.divs = (availableToken + withdrawn) * axion.stats?.btcPrice;
    }

    function renderAddStaker() {
        return globals.loadingStaker ? (
            <div className={classes.progress}>
                <CircularProgress />
            </div>
        ) : (
            <div className={classes.cardBody}>
                <NavProfiles bare />
            </div>
        );
    }

    function renderStaker() {
        return (
            <>
                <div className={classes.cardBody}>
                    <div className={classes.graph}>
                        <Donut
                            percentage={data.stakedPercent * 100}
                            innerColor={colors.yellow}
                            outerColor={colors.orange}
                            centertext={data.totalSupply.shorten(0, true)}
                        />
                    </div>
                    <div className={classes.stats}>
                        <Stat
                            classes={{ root: classes.stat }}
                            legend={{ color: colors.orange }}
                            // percent={{ value: 32, positive: true }}
                            value={numberWithCommas(data.totalStaked, 0)}
                            usdValue={numberWithCommas(data.totalStakedUsd)}
                        />
                        <Stat
                            classes={{ root: classes.stat }}
                            title={'Liquid'}
                            legend={{ color: colors.yellow }}
                            // percent={{ value: 32, positive: true }}
                            value={numberWithCommas(data.liquid, 0)}
                            usdValue={numberWithCommas(data.liquidUsd)}
                        />
                        <Stat
                            classes={{ root: classes.stat }}
                            title={'Total Owned'}
                            value={numberWithCommas(data.totalSupply, 0)}
                            usdValue={numberWithCommas(data.totalSupplyUsd)}
                        />
                    </div>
                </div>

                <div className={classes.cardFooter}>
                    <div className={classes.footerRow}>
                        <div className={classes.footerStat}>
                            <Typography className={classes.footerStatTitle} variant="h6">
                                Shares owned
                            </Typography>
                            <Typography variant="h3">{numberWithCommas(data.shares)}</Typography>
                        </div>
                        <div className={classes.vertical} />
                        <div className={classes.footerStat}>
                            <Typography className={classes.footerStatTitle} variant="h6">
                                percentage owned
                            </Typography>
                            <Typography variant="h3">{data.percentOwned.toFixed(5)}%</Typography>
                        </div>
                    </div>
                    <div className={classes.footerRow}>
                        <div className={classes.footerStat}>
                            <Typography className={classes.footerStatTitle} variant="h6">
                                interest earned
                            </Typography>
                            <Typography variant="h3">{data.interest.shorten(2)}</Typography>
                        </div>
                        <div className={classes.vertical} />
                        <div className={classes.footerStat}>
                            <div className={classes.row}>
                                <img src={WrappedBTC} className={classes.wbtc} alt="btc" />{' '}
                                <Typography className={classes.footerStatTitle} variant="h6">
                                    Bitcoin
                                </Typography>
                            </div>
                            <Tooltip title={`$${numberWithCommas(data.divs, 2)}`}>
                                <Typography variant="h3">{data.allTime.toFixed(5)}</Typography>
                            </Tooltip>
                        </div>
                        <div className={classes.vertical} />
                        <div className={classes.footerStat}>
                            <Typography className={classes.footerStatTitle} variant="h6">
                                active stakes
                            </Typography>
                            <Typography variant="h3">{data.activeStakes}</Typography>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <Card classes={{ root: classes.card }}>
            <div
                className={classes.cardTitle}
                style={{
                    paddingTop: 15,
                    paddingBottom: 13,
                }}
            >
                <div className={clsx(classes.row, classes.between)}>
                    <div className={classes.row}>
                        <PersonOutlineSharp className={classes.titleIcon} />
                        <div className={classes.column}>
                            <Typography variant="h5" color="primary">
                                Profile
                            </Typography>
                            <Typography variant="h6">
                                {profiles.active
                                    ? `${profiles.active.substring(0, 6)}...${profiles.active.slice(
                                          -4
                                      )}`
                                    : 'No active profile'}
                            </Typography>
                        </div>
                    </div>
                    {staker && (
                        <Tag
                            text={data.eco.title}
                            image={data.eco.image}
                            onClick={() => {
                                history.push('/portfolio');
                            }}
                        />
                    )}
                </div>
            </div>
            {staker ? renderStaker() : renderAddStaker()}
        </Card>
    );
};

export default ProfileWidget;
