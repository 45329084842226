import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Button,
    Typography,
} from '@material-ui/core';

import useStyles from './styles';

// Assets
import shrimp from 'assets/images/shrimp.png';
import crab from 'assets/images/crab.png';
import fish from 'assets/images/fish.png';
import dolphin from 'assets/images/dolphin.png';
import kraken from 'assets/images/kraken.png';
import greatWhite from 'assets/images/shark.png';
import whale from 'assets/images/whale.png';

const ecosystems = [
    { image: kraken, name: 'Kraken', percent: 10 },
    { image: whale, name: 'Whale', percent: 1 },
    { image: greatWhite, name: 'Shark', percent: 0.1 },
    { image: dolphin, name: 'Dolphin', percent: 0.01 },
    { image: fish, name: 'Fish', percent: 0.001 },
    { image: crab, name: 'Crab', percent: 0.0001 },
    { image: shrimp, name: 'Shrimp', percent: 0.00001 },
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LeagueDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">AXION LEAGUE</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    The Axion League is the ranking of all accounts based on their relative
                    percentage of Axion shares owned. When you stake your Axion, your tokens are
                    burned and exchanged for shares, which is how you earn interest. The more shares
                    you own, the more interest you receive. The larger and longer your stakes are,
                    the more shares you are awarded. As people stake and end stake, your percentage
                    of overall shares owned changes:
                </DialogContentText>
                <div className={classes.column}>
                    <Typography variant="h4">LEVELS INFO</Typography>
                    {ecosystems.map((eco, index) => (
                        <div className={classes.league} key={`${eco.name}-index`}>
                            <img src={eco.image} className={classes.leagueImage} />
                            <span className={classes.name} variant="h5">
                                {eco.name}
                            </span>
                            <span className={classes.info} variant="h5">
                                {`> `}
                                {eco.percent}% of total shares
                            </span>
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Sounds Good!
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LeagueDialog;
