import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    card: {
        borderRadius: 6,
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            marginBottom: theme.spacing(2),
        },
        height: '100%',
    },
    cardTitle: {
        minHeight: 48,
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: 20,
        color: theme.brand.GrayChateau,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    cardFooter: {
        borderTop: `1px solid ${theme.brand.border}`,
        textTransform: 'uppercase',
        fontSize: 20,
        color: theme.brand.GrayChateau,
        padding: 12,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    titleIcon: {
        color: theme.brand.IconColor,
        marginRight: theme.spacing(1),
        fontSize: 24,
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    between: {
        justifyContent: 'space-between',
        flex: 1,
    },
    shareRate: {
        marginRight: 40,
    },
    day: {
        marginRight: 24,
    },
    owned: {
        marginLeft: theme.spacing(1),
        fontSize: 24,
        color: theme.brand.BoldText,
        fontWeight: 'bold',
    },

    // Other
    btc: {
        height: 150,
        width: 150,
        backgroundColor: 'white',
        borderRadius: '50%',
    },
    btcLogo: {
        height: '100%',
        width: '100%',
    },

    walletItems: {
        display: 'flex',
        padding: '12px 0px',
        flex: 2,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            alignItems: 'baseline',
        },
    },
    walletItem: {
        justifyContent: 'space-around',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: '8px 24px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    middleWalletItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: `1px solid ${theme.brand.border}`,
        borderLeft: `1px solid ${theme.brand.border}`,
        [theme.breakpoints.down('sm')]: {
            padding: '12px 0px',
            borderRight: 'none',
            borderLeft: 'none',
            flexBasis: '100%',
        },
    },
    horizontal: {
        width: '100%',
        height: 1,
        backgroundColor: theme.brand.border,
        margin: '24px 0px',
    },

    footerLogo: {
        height: 40,
    },
    bgBtc: {
        backgroundColor: 'white',
        borderRadius: '50%',
        height: 40,
        width: 40,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    bgAxion: {
        height: 40,
        width: 40,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
}));

export default useStyles;
