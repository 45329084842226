import React from 'react';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import { iOS } from 'utils/detect-device';
import { tradeUrl, stakeUrl, auctionUrl } from 'utils/variables';
// Components
import { Button, Hidden } from '@material-ui/core';
import { Autorenew, Gavel } from '@material-ui/icons';
import { FaBitcoin } from 'react-icons/fa';
import { BiPurchaseTag } from 'react-icons/bi';
import { SiAtom } from 'react-icons/si';
// Styles
import useStyles from './styles';

function NavigationtTopBar({ children, ...props }) {
    const classes = useStyles();

    if (iOS && isMobile) return null;

    return (
        <div className={classes.root}>
            <Button
                classes={{ root: classes.links }}
                href={auctionUrl}
                target="_blank"
                color="primary"
            >
                <SiAtom className={classes.reactIcon} />
                <Hidden smDown>AXN Accelerator</Hidden>
            </Button>
            <div className={classes.divider} />
            <Button
                classes={{ root: classes.links }}
                href={tradeUrl}
                target="_blank"
                color="primary"
            >
                <BiPurchaseTag className={classes.bitcoin} />
                <Hidden smDown>Buy AXN</Hidden>
            </Button>
            <div className={classes.divider} />
            <Button
                classes={{ root: classes.links }}
                href={stakeUrl}
                target="_blank"
                color="primary"
            >
                <Gavel className={classes.linkIcon} />
                <Hidden smDown>Stake your AXN!</Hidden>
            </Button>
            <div className={classes.divider} />
            <Button
                classes={{ root: classes.links }}
                color="primary"
                href="https://calc.axion.network"
                target="_blank"
            >
                <FaBitcoin className={classes.bitcoin} />
                <Hidden smDown>Bitcoin Calculator</Hidden>
            </Button>
        </div>
    );
}

export default withRouter(NavigationtTopBar);
