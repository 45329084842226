import React from 'react';

import { useTheme } from '@material-ui/core';
import useStyles from './styles';
import { colors, palette } from 'global-styles';
import FadeIn from 'react-fade-in';
import { Tooltip } from 'react-svg-tooltip';

const Donut = ({
    percentage = 0,
    innerColor = colors.teal,
    outerColor = palette.primary.main,
    borderColor = '#fdfdfd',
    circumference = 80,
    strokeWidth = 3.5,
    height = '100%',
    width = '100%',
    size = 42,
    head = true,
    centertext,
    fade = true,
    hoverTextRing = 'Liquid',
    hoverTextRingWidth = 19,
    hoverTextCircle = 'Staked',
    hoverTextCircleWidth = 19,
    ...props
}) => {
    const classes = useStyles(props);
    const theme = useTheme();

    const center = size / 2;
    const radius = circumference / (2 * Math.PI);
    const filled = (circumference * percentage) / 100;
    const unfilled = circumference - filled;

    const currentAngle = (Math.PI * 2 * percentage) / 100;
    const counterEndAngle = currentAngle - Math.PI / 2;
    const x = center + radius * Math.cos(counterEndAngle);
    const y = center + radius * Math.sin(counterEndAngle);

    const circleRef = React.createRef();
    const ringRef = React.createRef();

    return (
        <FadeIn className={classes.root}>
            <svg
                height={height}
                width={width}
                viewBox={`0 0 ${size} ${size}`}
                className={classes.svgroot}
            >
                <circle
                    className="donut-hole"
                    cx={center}
                    cy={center}
                    r={radius}
                    fill={theme.brand.CardBG}
                ></circle>

                {fade && (
                    <circle
                        className="donut-ring"
                        cx={center}
                        cy={center}
                        r={radius * 1.08}
                        strokeLinecap="round"
                        fill="transparent"
                        stroke={`rgba(200, 200, 200, 0.1)`}
                        strokeWidth="4.5"
                    ></circle>
                )}

                <circle
                    ref={ringRef}
                    className="donut-ring"
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeLinecap="round"
                    fill="transparent"
                    stroke={innerColor}
                    strokeWidth={strokeWidth}
                />
                <circle
                    ref={circleRef}
                    className="donut-segment"
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeLinecap="round"
                    fill="transparent"
                    stroke={outerColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${filled} ${unfilled}`}
                    strokeDashoffset={circumference / 4}
                />

                <Tooltip triggerRef={circleRef}>
                    <rect
                        x={2}
                        y={2}
                        width={hoverTextCircleWidth}
                        height={5}
                        rx={0.5}
                        ry={0.5}
                        fill="black"
                    />
                    <text x={5} y={5} fontSize={2} fill="white">
                        {percentage.toFixed(2)}% {hoverTextCircle}
                    </text>
                </Tooltip>
                {hoverTextRing && (
                    <Tooltip triggerRef={ringRef}>
                        <rect
                            x={2}
                            y={2}
                            width={hoverTextRingWidth}
                            height={5}
                            rx={0.5}
                            ry={0.5}
                            fill="black"
                        />
                        <text x={5} y={5} fontSize={2} fill="white">
                            {(100 - percentage).toFixed(2)}% {hoverTextRing}
                        </text>
                    </Tooltip>
                )}

                {head && <circle r="0.5" fill="#ffffff" cx={x} cy={y}></circle>}
                <g className={classes.chartText}>
                    <text
                        x="50%"
                        y="50%"
                        className={classes.chartNumber}
                        fill={theme.brand.BoldText}
                    >
                        {centertext}
                    </text>
                </g>
            </svg>
        </FadeIn>
    );
};

export default Donut;
